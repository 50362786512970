import WebPosUtils from "../../../helper/Utils";

class CatalogHelper {

    static loadSimpleProducts(product) {

        var params = new URLSearchParams();
        params.append('sku', product.sku);
        params.append('store', WebPosUtils.getCurrentStore().id);
        return WebPosUtils.getJSON(process.env.REACT_APP_API_URL + '/detail', params, 'post')
            .then(response => {

                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }

                return response;
            })
            .then(({data}) => {

                return data;
            }).catch(function (error) {
                console.log(error);
                return [];
            });


    }

    static loadCatalogAttributesFromWs() {

        return WebPosUtils.getJSON(process.env.REACT_APP_API_URL + '/attributesData/', null)
            .then(response => {

                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response;
            })
            .then(({data}) => {

                localStorage.setItem('attributes', JSON.stringify({attributes: data, time: WebPosUtils.getNow(true)}));
                return data;
            }).catch(function (error) {
                console.log(error);
                return [];
            });
    }

    static loadCatalogAttributesOptions(attributes) {

        var atts = localStorage.getItem('attributes');
        var atts_return = [];
        if (atts) {
            try {
                var json = JSON.parse(atts);

                if( (WebPosUtils.getNow(true) - json.time) > WebPosUtils.getAppInfo().getData("cache").attributes ) throw new Error();;
                atts = json.attributes;


                attributes.forEach(search => {
                    atts.forEach(att => {


                        if (att.code === search.code) {
                            atts_return.push(att);

                        }
                    });
                });
            } catch (e) {
                console.log(e);
                atts = [];
            }
        }

        if (atts_return.length === attributes.length) return new Promise((devuelve, rechaza) => {
            devuelve(atts_return)
        });

        if (localStorage.getItem('attributes') == null) {
            //si no tiene todos los atributos descargados, los vuelve a descargar
            return CatalogHelper.loadCatalogAttributesFromWs().then((data) => {
                    return CatalogHelper.loadCatalogAttributesOptions(attributes);
                }
            )
        } else return CatalogHelper.loadCatalogAttributesFromWs();

    }
    static updateStock(data){
        var params = new URLSearchParams();
        data.terminal = WebPosUtils.getCurrentTerminal();

        params.append('stock', JSON.stringify(data));

        return WebPosUtils.getJSON(process.env.REACT_APP_API_URL + '/PUTStock/', params, "post")
            .then(response => {

                if (response.status < 200 || response.status >= 300) {
                    return {error: 1, msg: response.statusText};
                }
                return response;
            })
            .then(({data}) => {
                if (data.error === "1") {
                    return {error: 1, msg: data.msg};
                } else {
                    return data;
                }
            }).catch(function (error) {

                return {error: 1, msg: error};
            });
    }

}

export default CatalogHelper;