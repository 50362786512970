import WebPosUtils from "../../../helper/Utils";
import QuoteAddressModel from "../model/QuoteAddressModel";

class QuoteHelper {


    static initCart(quote) {

        var uniqid = require('uniqid');
        quote.id = uniqid();
        quote.created_at = WebPosUtils.getNow();


    }

    static addToCart(quote, payload) {

        var product = payload.product;
        var qty = payload.qty;

        if (quote.created_at == null) QuoteHelper.initCart(quote);
        var found = false;
        if (quote.items == null) quote.items = [];
        let newItems = quote.items.slice();
        newItems.map((item, key) => {

            if (QuoteHelper.isSameProduct(item, product)) {
                item.qty += qty;
                newItems[key] = item;
                found = true;

            }
            return null;
        });
        quote.items = newItems;
        if (!found) {
            var uniqid = require('uniqid');
            let newItems = quote.items.slice();
            newItems.splice(-1, 0, {
                ...product,
                original_price: product.price,
                qty: qty,
                item_id: uniqid(),
                custom_price: 0,
                custom_price_without_tax: 0,
                customized: false
            });
            quote.items = newItems;

        }
        quote = QuoteHelper.refreshCart(quote);
        return quote;
    }

    static addDiscountToCart(quote, discount) {
        var amount = discount.value;
        var amount_percent, amount_fixed = 0;
        switch (discount.type) {
            case "%":
                amount_percent = amount;
                amount_fixed = 0;
                break;
            default:
                amount_percent = 0;
                amount_fixed = amount;
                break;
        }

        quote.discount_fixed = amount_fixed;
        quote.discount_percent = amount_percent;
        quote.discount_name = discount.label;
        quote = QuoteHelper.refreshCart(quote);
        return quote;
    }

    static removeDiscountFromCart(quote) {
        quote.discount_fixed = 0;
        quote.discount_percent = 0;
        quote.discount_amount = 0;
        quote.discount_amount_without_tax = 0;
        quote.discount_name = '';
        quote = QuoteHelper.refreshCart(quote);
        return quote;
    }

    static refreshCart(quote) {
        var moment = require('moment');
        const d = moment()
        quote.updated_at = d.format('DD-MM-YYYY H:mm:ss');

        quote.subtotal = quote.tax = quote.grand_total = 0;


        quote.items.map((item, key) => {

            if (!isNaN(item.price_without_tax)) {
                if (item.customized) {
                    if (item.tax_percent > 0) {
                        quote.items[key].custom_price_without_tax = item.custom_price / (1 + item.tax_percent * 0.01);
                    } else quote.items[key].custom_price_without_tax = item.custom_price;

                    quote.items[key].discount_without_tax = quote.items[key].discount = 0;
                    quote.items[key].row_total_without_tax = quote.items[key].custom_price_without_tax * item.qty;
                    quote.items[key].row_total = item.custom_price * item.qty;
                } else {
                    quote.items[key].discount_without_tax = quote.items[key].discount = 0;
                    quote.items[key].row_total_without_tax = item.price_without_tax * item.qty;
                    quote.items[key].row_total = item.price * item.qty;
                }

                quote.subtotal += item.row_total_without_tax;
                quote.grand_total += item.row_total;
            }
            return null;
        });
        if (!quote.items.length > 0) {
            quote.discount_amount = quote.discount_fixed = quote.discount_percent = 0;
            quote.discount_name = null;
        }
        quote = QuoteHelper.applyDiscount(quote);
        quote.tax = quote.grand_total - quote.subtotal;
        quote.total_due = quote.grand_total;
        if (quote.payments == null) quote.payments = [];
        quote.payments.map((item, key) => {
            quote.total_due -= item.delivered;
            return item;
        });

        return quote;
    }

    static applyDiscount(quote) {
        var percent = quote.discount_percent;

        if (quote.discount_fixed > 0) {
            percent = WebPosUtils.round((100 * quote.discount_fixed) / quote.grand_total);

        }
        //si hay descuento, recorremos de nuevo todas las lineas y lo aplicamos
        if (percent > 0) {
            quote.discount_amount_without_tax = quote.discount_amount = 0;
            quote.items.map((item, key) => {
                if (!isNaN(item.price_without_tax)) {

                    var discount_without_tax = item.price_without_tax * item.qty * percent * 0.01;
                    var discount = item.price * item.qty * percent * 0.01;
                    quote.items[key].discount_without_tax = discount_without_tax;
                    quote.items[key].discount = discount;
                    quote.discount_amount_without_tax += discount_without_tax;
                    quote.discount_amount += discount;

                }
                return null;
            });
            quote.subtotal -= WebPosUtils.round(quote.discount_amount_without_tax);
            quote.grand_total -= WebPosUtils.round(quote.discount_amount);

        }
        return quote;

    }


    static isSameProduct(product1, product2) {
        if (product1.customized || product2.customized) return false;
        if (product1.id === product2.id) {
            if (product1.customized) return false;
            if (product1.type === 'simple') return true;
            if (product1.selected_values == null) product1.selected_values = [];
            for (var i = 0; i < product1.selected_values.length; i++) {
                var option = product1.selected_values[i];
                if (!product2.selected_values || !product2.selected_values[i] || !product2.selected_values[i].code) return false;
                /*eslint-disable */
                if (option.code != product2.selected_values[i].code || option.value != product2.selected_values[i].value) return false;
                /*eslint-enable */
            }
            return true;
        }
        return false;
    }

    static addPaymentMethod(quote, method) {
        let payments = quote.payments.slice();
        for (var i = 0; i < payments.length; i++) {
            if (payments[i].code === method.code) return quote;
        }
        payments.splice(-1, 0, method);
        quote.payments = payments;
        return quote;
    }

    static updatePaymentDelivered(quote, data) {
        let payments = quote.payments.slice();
        quote.total_due = quote.grand_total;


        for (var i = 0; i < payments.length; i++) {
            if (payments[i].code === data.code) {
                payments[i].delivered = data.value;
                quote.payments = payments;
                break;
            }
        }
        quote.payments.map((item, key) => {
            quote.total_due -= item.delivered;
            return item;
        });
        return quote;
    }

    static removePaymentMethod(quote, method) {
        let payments = quote.payments.slice();
        var index = payments.findIndex(x => x.code === method.code);

        if (index > -1) {
            payments.splice(index, 1);
        }
        quote.payments = payments;
        return quote;
    }

    static updatePaymentReference(quote, payload) {

        let payments = quote.payments.slice();
        var index = payments.findIndex(x => x.code === payload.code);

        if (index > -1) {
            payments[index].reference = payload.reference;
        }
        quote.payments = payments;
        return quote;
    }

    static getDeliveredAmount(quote) {
        var amount = 0;

        if (typeof quote.payments !== "undefined" && quote.payments.length > 0) {
            quote.payments.map((item, key) => {
                amount += Number(item.delivered);
                return item;
            });
        }

        return amount;
    }

    static getPaymentMethod() {

        return WebPosUtils.getJSON(process.env.REACT_APP_API_URL + '/paymentMethods/', null)
            .then(response => {

                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response;
            })
            .then(({data}) => {
                return data;
            }).catch(function (error) {
                console.log(error);
                return [];
            });
    }

    static sendQuote(quote) {


        var params = new URLSearchParams();
        quote.terminal = WebPosUtils.getCurrentTerminal();
        quote.store_id = WebPosUtils.getCurrentStore().id;
        quote.payments = [];
        var addresses = Object.assign({}, quote.customer_data);
        delete (quote.customer_data);

        params.append('quote', JSON.stringify(quote));

        return WebPosUtils.getJSON(process.env.REACT_APP_API_URL + '/PUTQuote/', params, "post")
            .then(response => {

                if (response.status < 200 || response.status >= 300) {
                    throw new Error({error: response.status, msg: response.statusText});
                }

                return response;
            })
            .then(({data}) => {
                if (data.error === "1") {
                    return {error: 1, msg: data.msg};
                } else {
                    data.quote.customer_data = addresses;

                    return data;
                }
            }).catch(function (error) {
                console.log(error);
                return {error: 500, msg: error};
            });
    }

    static setmustBooking(quote) {
        quote.webpos_booking = null;
        if (!WebPosUtils.getSettings().booking) return quote;
        else {
            var payments = quote.payments;
            if (payments.length === 0) return quote;
            else {
                var only_cash = true;
                payments.map(function (value) {
                    if (value.code !== "webposcash") only_cash = false;
                    return null;
                })
                if (only_cash) {
                    if (quote.booking === "1") {
                        return quote;
                    } else quote.webpos_booking = "1";
                }
            }
        }
        return quote;
    }

    static sendOrder(quoteObject) {
        var quote = Object.assign({}, quoteObject);
        var params = new URLSearchParams();
        quote.terminal = WebPosUtils.getCurrentTerminal();
        quote.store_id = WebPosUtils.getCurrentStore().id;
        delete (quote.customer_data);
        quote = QuoteHelper.setmustBooking(quote);

        params.append('quote', JSON.stringify(quote));

        return WebPosUtils.getJSON(process.env.REACT_APP_API_URL + '/PUTOrder/', params, "post")
            .then(response => {

                if (response.status < 200 || response.status >= 300) {
                    return {error: 1, msg: response.statusText};
                }
                return response;
            })
            .then(({data}) => {
                if (data.error === "1" || !data.order_id > 0) {
                    return {error: 1, msg: data.msg};
                } else {
                    return data;
                }
            }).catch(function (error) {

                return {error: 1, msg: error};
            });
    }

    static sendPaymentOrder(quote, payment) {

        var params = new URLSearchParams();
        params.append('id', quote.id);
        params.append('payment', JSON.stringify(payment));

        return WebPosUtils.getJSON(process.env.REACT_APP_API_URL + '/PUTPayment/', params, "post")
            .then(response => {

                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response;
            })
            .then(({data}) => {
                if (data.error === "1") {
                    return {error: 1, msg: data.msg};
                } else {
                    return data;
                }
            }).catch(function (error) {

                return {error: 1, msg: error};
            });
    }

    static printOrder(printable) {

        var {id_order, id_refund, is_gift, coupon} = printable;
        var params = new URLSearchParams();
        if (id_order > 0) params.append("id", id_order);
        if (id_refund > 0) params.append('refund', id_refund);
        if (is_gift === true) params.append('is_gift', 1);
        if (coupon) {
            params.append('coupon_code', coupon.code);
            params.append('coupon_type', coupon.type);
            params.append('coupon_amount', coupon.amount);
            params.append('coupon_date_to', coupon.date_to);
        }

        var url = '/printOrder/';
        return WebPosUtils.getJSONProcessed(url, params, "post")
            .then(response => {
                return response;
            });
    }

    static calculeExchange(quote) {

        var dif = quote.grand_total - QuoteHelper.getDeliveredAmount(quote);
        return Math.round(dif * 100) / 100;
    }


    static sendRefundOrder(order, refund) {

        var params = new URLSearchParams();

        order.website_id = WebPosUtils.getWebsite4Store(order.store_id);
        refund.terminal = WebPosUtils.getCurrentTerminal();

        params.append('refund', JSON.stringify(refund));
        params.append('order', JSON.stringify(order));


        return WebPosUtils.getJSON(process.env.REACT_APP_API_URL + '/PUTRefund/', params, "post")
            .then(response => {

                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response;
            })
            .then(({data}) => {
                if (data.error === "1") {
                    return {error: 1, msg: data.msg};
                } else {
                    return data;
                }
            }).catch(function (error) {

                return {error: 1, msg: error};
            });
    }

    static getPaymentMethods(coupon_include = false, installment_include = false, booking=false) {

        var methods = [...WebPosUtils.getCustomerOptions().getPayments()];

        if (coupon_include) {
            methods.push({
                code: 'webposcoupon',
                label: 'app.quote.payment_coupon'

            });
        }
        if (!installment_include) {
            var key_installment = -1;
            for (let key in methods) {

                if (methods[key].code === 'webpos_installment') {
                    key_installment = key;
                    break;
                }
            }
            if (key_installment !== -1) delete methods[key_installment];
        }
        if (booking) {
            var key_card = -1;
            for (let key in methods) {

                if (methods[key].code === 'webposcard') {
                    key_card = key;
                    break;
                }
            }
            if (key_card !== -1) delete methods[key_card];
        }
        return methods;
    }

    static updateRowPrice(quote, payload) {

        quote.items.map((item, key) => {

            if (item.item_id === payload.row.item_id) {
                if (payload.custom_price !== item.price) {
                    item.custom_price = Number(payload.custom_price);
                    item.customized = true;
                } else {
                    item.custom_price = 0;
                    item.customized = false;
                }
                quote.items[key] = item;
            }
            return null;
        });
        quote = QuoteHelper.refreshCart(quote);

        return quote;
    }

    static updateCart(quote, row, qty, append = true) {

        let row_id = -1;
        quote.items.map((item, key) => {
            if (item.item_id === row.item_id) {
                row_id = key;
            }
            return null;
        });

        let newArray = quote.items.slice();
        if (append !== true) row.qty = qty;
        else row.qty += qty;
        newArray.splice(row_id, 1);
        newArray.splice(row_id, 0, row);
        quote.items = newArray;
        quote = QuoteHelper.refreshCart(quote);
        return quote;
    }

    static removeFromCart(quote, row_id) {
        let newArray = quote.items.slice();
        newArray.splice(row_id, 1);
        quote.items = newArray;
        quote = QuoteHelper.refreshCart(quote);
        return quote;

    }

    static getFundsSummary(date = null, booking=null) {

        var params = new URLSearchParams();
        if (date != null) params.append('created_at', date);
        if (booking != null) params.append('booking', booking);
        return WebPosUtils.getJSON(process.env.REACT_APP_API_URL + '/fundssummary/', params, "post")
            .then(response => {

                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response;
            })
            .then(({data}) => {
                if (data.error === "1") {
                    return {error: 1, msg: data.msg};
                } else {
                    return data;
                }
            }).catch(function (error) {

                return {error: 1, msg: error};
            });
    }

    static updateCustomerQuote(quote, customer) {
        quote.customer_shipping_address = null;
        quote.customer_billing_address = null;
        if (customer === null) {
            quote.id_customer = 0;
            quote.customer_name = null;
            quote.customer_data = null;
        } else {
            quote.id_customer = customer.id;
            quote.customer_name = customer.firstname;
            if (customer.lastname) quote.customer_name += " " + customer.lastname;
            quote.customer_data = customer;

            if (typeof customer.addresses !== "undefined" && customer.addresses.length > 0) {
                for (const item of customer.addresses) {

                    if (item.default_shipping) {
                        quote.customer_shipping_address = new QuoteAddressModel(item);
                        if (item.default_billing) {
                            quote.customer_billing_address = new QuoteAddressModel(item);
                        }
                    }
                    if (item.default_billing) {
                        quote.customer_billing_address = new QuoteAddressModel(item);
                    }

                }
                if (quote.customer_shipping_address == null && quote.customer_billing_address !== null) {
                    quote.customer_shipping_address = quote.customer_billing_address;
                }
                if (quote.customer_billing_address == null && quote.customer_shipping_address !== null) {
                    quote.customer_billing_address = quote.customer_shipping_address;
                }


            } else {
                quote.id_customer = 0;
                quote.customer_name = null;
                quote.customer_data = null;
            }
        }
        return quote;
    }

    static updateCustomerAddressQuote(quote, address) {

        if (address.billing) {
            quote.customer_billing_address = new QuoteAddressModel(address.address);
        } else {
            quote.customer_shipping_address = new QuoteAddressModel(address.address);
        }
        return quote;
    }

    static freeProduct(quote, payload) {
        let newArray = quote.items.slice();
        newArray.map((item, key) => {

            if (item.item_id === payload.row.item_id) {
                if ((item.qty - payload.qty) < 0.01) {
                    item.custom_price = 0;
                    item.customized = true;
                    newArray[key] = item;
                } else {
                    item.qty -= payload.qty;
                    newArray[key] = item;

                    var new_item = {...item};
                    new_item.qty = payload.qty;
                    new_item.custom_price = 0;
                    var uniqid = require('uniqid');
                    new_item.item_id = uniqid();
                    new_item.customized = true;
                    let newItems = quote.items.slice();
                    newItems.splice(-1, 0, new_item);
                    newArray = newItems;

                }

            }
            return null;
        });
        quote.items = newArray;
        quote = QuoteHelper.refreshCart(quote);
        return quote;
    }

    static sendCoupon(params) {


        params.website_id = WebPosUtils.getCurrentStore().website_id;

        return WebPosUtils.getJSON(process.env.REACT_APP_API_URL + '/createCoupon/', params, "post")
            .then(response => {

                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response;
            })
            .then(({data}) => {
                if (data.error === "1") {
                    return {error: 1, msg: data.msg};
                } else {
                    return data;
                }
            }).catch(function (error) {

                return {error: 1, msg: error};
            });
    }

    static canUpdatePrice(item) {
        return !QuoteHelper.isGift(item);
    }

    static canUpdateQty(item) {
        return !QuoteHelper.isGift(item);
    }

    static isGift(item) {
        if (typeof item.gift != "undefined" && (item.gift === "1" || item.gift === 1)) return true;
        return false;
    }

    static addToHold(quote) {


        var params = new URLSearchParams();
        quote.terminal = WebPosUtils.getCurrentTerminal();
        quote.store_id = WebPosUtils.getCurrentStore().id;
        quote.payments = [];

        params.append('quote', JSON.stringify(quote));

        return WebPosUtils.getJSON(process.env.REACT_APP_API_URL + '/PUTHold/', params, "post")
            .then(response => {

                if (response.status < 200 || response.status >= 300) {
                    throw new Error({error: response.status, msg: response.statusText});
                }

                return response;
            })
            .then(({data}) => {
                if (data.error === "1") {
                    return {error: 1, msg: data.msg};
                } else {
                    return data;
                }
            }).catch(function (error) {
                console.log(error);
                return {error: 500, msg: error};
            });
    }

    static removeHold(quote) {


        var params = new URLSearchParams();


        params.append('id', quote.id);

        return WebPosUtils.getJSON(process.env.REACT_APP_API_URL + '/deleteHold/', params, "post")
            .then(response => {

                if (response.status < 200 || response.status >= 300) {
                    throw new Error({error: response.status, msg: response.statusText});
                }

                return response;
            })
            .then(({data}) => {
                if (data.error === "1") {
                    return {error: 1, msg: data.msg};
                } else {
                    return data;
                }
            }).catch(function (error) {
                console.log(error);
                return {error: 500, msg: error};
            });
    }

    static voidOrder(order) {


        var params = new URLSearchParams();
        params.append('id', order.id);

        return WebPosUtils.getJSON(process.env.REACT_APP_API_URL + '/voidOrder/', params, "post")
            .then(response => {

                if (response.status < 200 || response.status >= 300) {
                    throw new Error({error: response.status, msg: response.statusText});
                }

                return response;
            })
            .then(({data}) => {
                if (data.error === "1") {
                    return {error: 1, msg: data.msg};
                } else {
                    return data;
                }
            }).catch(function (error) {
                console.log(error);
                return {error: 500, msg: error};
            });
    }
    static bookOrder(order, status=0) {


        var params = new URLSearchParams();
        params.append('order_id', order.id);
        params.append('status', status);

        return WebPosUtils.getJSON(process.env.REACT_APP_API_URL + '/bookOrder/', params, "post")
            .then(response => {

                if (response.status < 200 || response.status >= 300) {
                    throw new Error({error: response.status, msg: response.statusText});
                }

                return response;
            })
            .then(({data}) => {
                if (data.error === "1") {
                    return {error: 1, msg: data.msg};
                } else {
                    return data;
                }
            }).catch(function (error) {
                console.log(error);
                return {error: 500, msg: error};
            });
    }
}

export default QuoteHelper;