import React from 'react';
import {showNotification, withDataProvider, withTranslate,Loading} from "react-admin";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Row,Col,Card,Modal,Button,Form} from 'react-bootstrap';
import QuoteModel from "../cart/model/QuoteModel";
import CustomerAddress from "../customer/CustomerAddress";


class QuoteAddresses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {quote: QuoteModel, show:false, billing:false, addresses:[]};
        this.changeAddress=this.changeAddress.bind(this);
        this.toggleModal=this.toggleModal.bind(this);
        this.showAddress=this.showAddress.bind(this);
        this.search=this.search.bind(this);
    }
    changeAddress(address){


        this.props.dispatch( {
             type: "ADD_ADDRESS_TO_QUOTE",
             payload: {address:address,billing:this.state.billing, entity: this.props.checkout}
         });
        this.toggleModal(false);

    }
    toggleModal(status,billing){
        if(status) this.showAddress();
        this.setState({show:status, billing: billing});
    }
    showAddress(word=null){

        var list=null;
        if(this.props.quote.customer_data && this.props.quote.customer_data.addresses && this.props.quote.customer_data.addresses) list = this.props.quote.customer_data.addresses;
        var addresses=[];
        var limit=15;

        if(list!=null){

            for(var i=0;i<list.length;i++){
                if(addresses.length === limit) break;
                if(word!=null) {
                    if (list[i].region!==null && list[i].region.toLowerCase().indexOf(word)!==-1) addresses.push(list[i]);
                    else if (list[i].city!==null && list[i].city.toLowerCase().indexOf(word)!==-1) addresses.push(list[i]);
                    else if (list[i].postcode!==null && list[i].postcode.toLowerCase().indexOf(word)!==-1) addresses.push(list[i]);
                    else if (list[i].firstname!==null && list[i].firstname.toLowerCase().indexOf(word)!==-1) addresses.push(list[i]);
                    else if (list[i].lastname!==null && list[i].lastname.toLowerCase().indexOf(word)!==-1) addresses.push(list[i]);
                    else {
                        for (var j = 0; j < list[i].street.length; j++) {
                            if (list[i].street[j].toLowerCase().indexOf(word)!==-1){
                                addresses.push(list[i]);
                                break;
                            }
                        }
                    }

                }else {
                    addresses.push(list[i]);
                }
            }
        }

        this.setState({addresses:addresses});
    }
    search(event){
        var word = event.target.value;
        word = word.toLowerCase();
        this.showAddress(word);
    }
    render() {
        const {translate} = this.props;

        if(!this.props.quote.id_customer > 0) return '';
        if(!this.props.quote.customer_data || !this.props.quote.customer_data.addresses || this.props.quote.customer_data.addresses.length===0) return '';
        if(this.props.loading) return(<Loading loadingPrimary=""  loadingSecondary="app.core.loading1"/>);
        return (
<div className="quote_addresses">
                <Row>
                    <Col sm={6} xs={12}>
                        <Card   onClick={()=>{this.toggleModal(true, true)}}>
                            <Card.Header>{translate('app.quote.billing_address')}</Card.Header>
                            <Card.Body>
                                {this.props.quote.customer_billing_address?(
                                    <CustomerAddress address={this.props.quote.customer_billing_address}></CustomerAddress>
                                ):(
                                    <span>{translate('app.quote.no_address')}</span>
                                )}

                            </Card.Body>
                        </Card></Col>
                    <Col sm={6} xs={12}>
                        <Card   onClick={()=>{this.toggleModal(true, false)}}>
                            <Card.Header>{translate('app.quote.shipping_address')}</Card.Header>
                            <Card.Body>
                                {this.props.quote.customer_shipping_address?(
                                    <CustomerAddress address={this.props.quote.customer_shipping_address}></CustomerAddress>
                                ):(
                                    <span>{translate('app.quote.no_address')}</span>
                                )}

                            </Card.Body>
                        </Card></Col>
                    <Modal show={this.state.show} onHide={()=>{this.toggleModal(false, false)}}>
                        <Modal.Header closeButton>
                            <Modal.Title>{translate('app.quote.select_address')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                                <div className="search">
                                    <Form.Control  type="text" placeholder={translate('app.catalog.search')} onChange={this.search} />
                                </div>
                                <div className="addresses">
                            {this.state.addresses && this.state.addresses.map((row, index) => (
                                <Col onClick={()=>{this.changeAddress(row)}} key={index}>
                                    <CustomerAddress address={row}></CustomerAddress>
                                </Col>
                                ))}
                                </div>

                        </Modal.Body>
                        <Modal.Footer>
                            {this.props.quote.customer_data && this.props.quote.customer_data.addresses && this.props.quote.customer_data.addresses && (
                                <span>{this.props.quote.customer_data.addresses.length} {translate('app.core.addresses')}</span>
                            )}
                            <Button variant="secondary" onClick={()=>{this.toggleModal(false, false)}}>
                                {translate('app.common.close')}
                            </Button>

                        </Modal.Footer>
                    </Modal>
                </Row>
</div>

        )
    }
}
const mapStateToProps = (state) => {
    return {
        quote: state.cart

    }
};
const mapDispatchToProps = (dispatch) => {

    return {
        dispatch,
        ...bindActionCreators({showNotification}, dispatch)
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(withDataProvider(withTranslate(QuoteAddresses)));