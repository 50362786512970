import React from 'react';
import Form from 'react-bootstrap/Form';
import {withTranslate} from 'react-admin';
import Card from 'react-bootstrap/Card';
import Paper from '@material-ui/core/Paper';
import WebPosUtils from "../../helper/Utils";
import {connect} from 'react-redux';

class Settings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {settings: WebPosUtils.getSettings()};
        this.handleChange = this.handleChange.bind(this);
    }



    handleChange(event) {

        var name = event.target.name;
        var value;

        if(typeof this.state.settings[name] === "undefined") value=true;
        else value = !this.state.settings[name];
        //var settings = WebPosUtils.setSetting(name, value);
        var settings = this.state.settings;
        settings[name]=value;
        this.setState({
            settings: settings,
        });

        this.props.dispatch({
            type: "CHANGE_SETTINGS",
            payload: settings
        });
    }

    render() {
        const {translate} = this.props;
        return (
            <Paper>
                <Card className="payment_methods">
                    <Card.Header>{translate('app.core.settings')}</Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group controlId="autoprint">
                                <Form.Check type="checkbox" label={translate("app.config.autoprint")} onChange={this.handleChange} name="autoprint"  value="1" checked={this.state.settings.autoprint}/>
                            </Form.Group>
                            <Form.Group controlId="visible_check">
                                <Form.Check type="checkbox" label={translate("app.config.visible_check")} onChange={this.handleChange} name="visible_check"  value="1" checked={this.state.settings.visible_check}/>
                            </Form.Group>
                            <Form.Group controlId="enable_notifications">
                                <Form.Check type="checkbox" label={translate("app.config.enable_notifications")} onChange={this.handleChange} name="enable_notifications"  value="1" checked={this.state.settings.enable_notifications}/>
                            </Form.Group>
                            <Form.Group controlId="show_sku">
                                <Form.Check type="checkbox" label={translate("app.config.show_sku")} onChange={this.handleChange} name="show_sku"  value="1" checked={this.state.settings.show_sku}/>
                            </Form.Group>

                            <Form.Group controlId="hide_stock">
                                <Form.Check type="checkbox" label={translate("app.config.hide_stock")} onChange={this.handleChange} name="hide_stock"  value="1" checked={this.state.settings.hide_stock}/>
                            </Form.Group>
                            <Form.Group controlId="hide_codebar">
                                <Form.Check type="checkbox" label={translate("app.config.hide_codebar")} onChange={this.handleChange} name="hide_codebar"  value="1" checked={this.state.settings.hide_codebar}/>
                            </Form.Group>
                        </Form>
                    </Card.Body>
                </Card>

            </Paper>
        );
    }
}


export default connect()(withTranslate(Settings));