import {defaultTheme} from "react-admin";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import merge from "lodash/merge";
import WebPosUtils from "../helper/Utils";

var overrides = {  palette: {

        type: 'light', // Switching the dark mode on is a single property value change.
    },};

if (WebPosUtils.getThemeName() !== "default") {
    const path = './Themes/' + WebPosUtils.getThemeName() + '.js';
    const CustomTheme = require(''+path);
    overrides = CustomTheme.default;
}

export const theme = createMuiTheme(
    merge({}, defaultTheme, overrides)
);

