import WebPosUtils from "../helper/Utils";

export default (previousState = {}, {type, payload}) => {
    var shop;

    switch (type) {
        case "CHANGE_SETTINGS":

            shop = Object.assign({},previousState);
            shop.settings=payload;

            return shop;
        case "CHANGE_STORE":

            shop = Object.assign({},previousState);
            shop.shop=payload;
            WebPosUtils.setCurrentStore(payload);

            return shop;


        default:
            return previousState;
    }

}