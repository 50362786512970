import React from 'react';

import {withTranslate} from "react-admin";
import WebPosUtils from "../helper/Utils";
import {showNotification} from 'react-admin';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';


import { refreshView } from 'react-admin';

class Stores extends React.Component {

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);

    }

    handleClick(event) {
        var store = WebPosUtils.getStore4Id(event.currentTarget.value);

        if(store!=null) {
            this.props.dispatch({
                type: "CHANGE_STORE",
                payload: store
            });
            this.props.showNotification("app.quote.changed_store", 'success');

            this.props.refreshView();
        }
    };


    render() {

    if(WebPosUtils.getCustomerOptions()==null || WebPosUtils.getCustomerOptions().getStores()==null || WebPosUtils.getCustomerOptions().getStores().length < 2) return '';
    else    return (
            <div className="stores_combo">
                <select
                    id="store_id"
                    className="inputSelectRound form-control override-fc"
                    name="store_id"
                    onChange={this.handleClick}
                    value={this.props.store_id}
                >
                    {WebPosUtils.getCustomerOptions().getWebsites().map((website, index) => {
                        return (
                            <optgroup key={index} label={website.name}>
                                {WebPosUtils.getCustomerOptions().getStores().map((store, index) => {
                                    if(store.website_id === website.id){
                                        return (<option value={store.id} key={store.id}>{store.name}</option>)
                                    }else return ('')
                                })}
                            </optgroup>
                        )
                    })}
                </select>
            </div>
        )

    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        dispatch,
        ...bindActionCreators({showNotification, refreshView}, dispatch)
    }
};

const mapStateToProps = (state) => {

    return {
        store_id: state.shop.id
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(Stores));