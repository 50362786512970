import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    withTranslate
} from 'react-admin';

class CustomerCreateForm extends React.Component {

    render() {
        const {translate} = this.props;
        return (

            <Create title={translate("app.customer.header_create")} {...this.props} >
                <SimpleForm redirect="/customerlist">
                    <TextInput source="name" label="app.customer.firstname"/>
                    <TextInput source="lastname" label="app.customer.lastname"/>
                    <TextInput source="email" label="app.customer.email"/>
                    <TextInput source="taxvat" label="app.customer.taxvat"/>
                    <TextInput source="address" label="app.customer.address"/>
                    <TextInput source="city" label="app.customer.city"/>
                    <TextInput source="postcode" label="app.customer.postcode"/>
                    <TextInput source="phone" label="app.customer.phone"/>
                </SimpleForm>
            </Create>
        )
    }

}

export default (withTranslate(CustomerCreateForm));