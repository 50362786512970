import SettingsModel from "../pages/settings/model/Settings";
import storeConfig from '../config/store';
import CustomerOptions from "./CustomerOptions";
import AppInfo from "./AppInfo";



const axios = require('axios');

class WebPosUtils {
    static customer;
    static appInfo;
    static current_store = 0;
    static version = 1.1;

    static getAlias() {
        const domain = window.location.pathname.replace("/", "");
        return domain;
    }

    static isLogged() {

        return localStorage.getItem('token');
    }

    static getCurrentTerminal() {

        return localStorage.getItem('current_terminal');
    }


    static getCurrentStore() {
        if (this.current_store === 0) {
            var state = storeConfig.store.store.getState();
            this.current_store = state.shop.shop;

        }
        return this.current_store;
    }

    static setCurrentStore(shop) {
        this.current_store = shop;
    }

    static getStore4Id(store_id) {
        store_id = Number(store_id);
        var storeObject = null;
        WebPosUtils.getCustomerOptions().getStores().some((store) => {
            if (store.id === store_id) {
                storeObject = store;
                return true;
            }
            return false;
        });
        return storeObject;

    }

    static getWebsite4Store(store_id) {
        store_id = Number(store_id);
        var website_id = 0;
        WebPosUtils.getCustomerOptions().getStores().forEach((store) => {

            if (store.id === store_id) {

                website_id = store.website_id;

            }
        });
        return website_id;

    }

    static clearSession() {
        localStorage.removeItem('token');
        localStorage.removeItem('customer');
        localStorage.removeItem('customer_options');
        localStorage.removeItem('username');

    }

    static formatCurrency(price) {
        if (isNaN(price)) price = 0;
        price = Number(price);
        return price.toFixed(2) + ' ' + WebPosUtils.getCurrency();
    }

    static round(num) {
        return Math.round(num * 100) / 100;
    }

    static getCurrency() {
        //euro fijo
        return "\u20AC";
    }

    static getJSON(url, data, protocol = 'get') {

        const token = localStorage.getItem('token');
        return axios({
            url: url,
            method: protocol,
            headers: {'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': `Bearer ${token}`},
            data: data
        });
    }

    static getJSONProcessed(url, params, protocol = "post") {

        return WebPosUtils.getJSON(process.env.REACT_APP_API_URL + url, params, protocol)
            .then(response => {

                if (response.status < 200 || response.status >= 300) {
                    return {error: 1, msg: response.statusText};
                }
                return response;
            })
            .then(({data}) => {
                if (data.error === "1") {
                    return {error: 1, msg: data.msg};
                } else {
                    return data;
                }
            }).catch(function (error) {

                return {error: 1, msg: error};
            });
    }

    static getNow(timestamp = false) {
        var moment = require('moment');
        const d = moment();
        if (timestamp) return d.unix();
        else return d.format('DD-MM-YYYY H:mm:ss');
    }

    static formatDateFromMysql(date, hour=true) {
        if (date == null) return "";
        var moment = require('moment');
        var format = 'DD-MM-YYYY H:mm';
        if(!hour) format ='DD-MM-YYYY';
        return moment(date).format(format);
    }

    static getAppInfo() {
        if (WebPosUtils.appInfo === null || typeof WebPosUtils.appInfo === "undefined") {
            WebPosUtils.appInfo= new AppInfo();
        }
        return WebPosUtils.appInfo;
    }

    static getCustomerOptions() {
        if (WebPosUtils.customer === null || typeof WebPosUtils.customer === "undefined") {
            WebPosUtils.customer= new CustomerOptions();
        }
        return WebPosUtils.customer;

    }
    static getPermission(perm){
       return this.getCustomerOptions().getPermission(perm);
    }
    static reloadAppInfo(){
        this.appInfo = null;
    }
    static reloadCustomerOptions(){
        this.customer = null;
    }
    static getUsername() {

        return localStorage.getItem('username');
    }

    static getUrlVars() {
        var vars = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
        });
        return vars;
    }


    static getNotifications() {

        return WebPosUtils.getJSON(process.env.REACT_APP_API_URL + '/notifications/', null)
            .then(response => {

                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response;
            })
            .then(({data}) => {
                return data;
            }).catch(function (error) {
                console.log(error);
                return [];
            });
    }

   /* static setSetting(name, value) {

        var settings = WebPosUtils.getSettings();
        if (settings == null) settings = new SettingsModel().getValues();
        settings[name] = value;

        return settings;
    }*/

    /* static setSettings(settings) {

         localStorage.setItem('settings', JSON.stringify(settings));
     }*/
    static initialiceSettings() {
        var settings = new SettingsModel();

        var currentState = storeConfig.store.store.getState();

        settings.initiateServerValues(WebPosUtils.getCustomerOptions().getSettings());

        settings.initiate(currentState.shop.settings);

        storeConfig.store.store.dispatch({
            type: "CHANGE_SETTINGS",
            payload: settings.getValues()
        });

    }

    static getSettings() {

        var state = storeConfig.store.store.getState();
        if(typeof state.shop.settings === "undefined"){
            WebPosUtils.clearSession();
            return false;
        }
        var settings = Object.assign({},state.shop.settings);

        return settings;

    }


    static getTheme() {
        if (WebPosUtils.getThemeName() === "default") {
            return WebPosUtils.getDefaultTheme();
        }
        const theme = require('../config/Theme');
        return theme.theme;
    }

    static getDefaultTheme() {

        const defaultTheme = require('../config/Themes/default.js');
        return defaultTheme.default;

    }

    static getThemeName() {
        if (WebPosUtils.getAppInfo() === null || WebPosUtils.getAppInfo().getData("customcss") === null) return "default";
        else return WebPosUtils.getAppInfo().getData("customcss");
    }

    static checkVersion() {
        const version = localStorage.getItem('version_' + WebPosUtils.getAlias());
        var hold= localStorage.getItem('persist:' + WebPosUtils.getAlias() + '_hold');
        if(hold != null) {
            hold=JSON.parse(hold);
            console.log(hold);
        }

        if (WebPosUtils.version !== version) {

            localStorage.clear();
            localStorage.setItem('version_' + WebPosUtils.getAlias(), WebPosUtils.version);

           /* localStorage.removeItem('persist:' + WebPosUtils.getAlias() + '_cart');
            localStorage.removeItem('persist:' + WebPosUtils.getAlias() + '_hold');
            localStorage.removeItem('persist:' + WebPosUtils.getAlias() + '_shop');
            localStorage.setItem('version_' + WebPosUtils.getAlias(), WebPosUtils.version);*/
        }
    }
    static isMobile(){
        var width = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        return width < 640;
    }

}

export default WebPosUtils;