const CustomTheme= {
    palette: {
        // Your theme goes here
        // Write the following code to have an orange app bar. We'll explain it later in this article.
        secondary: {
            main: "#c8102e", // Not far from orange
        }
    },
    overrides:{
        MuiButton: {
            containedPrimary: {
                backgroundColor: '#c8102e',
                '&:hover':{
                    backgroundColor:'#000'
                }
            },
        }
    }
}
export default CustomTheme;