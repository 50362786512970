import QuoteModel from "../model/QuoteModel";
import QuoteHelper from "./QuoteHelper";



export default (previousState = QuoteModel, {type, payload}) => {
    var cart, quote;

    switch (type) {
        case "SET_BOOKING_CART":
            quote = Object.assign({},previousState);
            quote.booking=payload;
            return quote;
        case "FREEPRODUCT_CART":
            quote = Object.assign({},previousState);
            cart = QuoteHelper.freeProduct(quote, payload);
            if(payload.entity!==null) payload.entity.createQuote(cart);

            return cart;
        case "ADD_ADDRESS_TO_QUOTE":
            quote = Object.assign({},previousState);

            cart = QuoteHelper.updateCustomerAddressQuote(quote, payload);
            if(payload.entity != null) payload.entity.createQuote(cart);

            return cart;
        case "ADD_CUSTOMER_TO_QUOTE":
            quote = Object.assign({},previousState);
            cart = QuoteHelper.updateCustomerQuote(quote, payload);

            return cart;
        case "REMOVE_CUSTOMER_TO_QUOTE":
            quote = Object.assign({},previousState);
            cart = QuoteHelper.updateCustomerQuote(quote, payload);

            return cart;
        case "REMOVE_COUPON_FROM_CART":
            quote = Object.assign({},previousState);
            quote.coupon_code=null;
            payload.entity.createQuote(quote);
            return quote;
        case "ADD_COUPON_TO_CART":
            quote = Object.assign({},previousState);
            quote.coupon_code=payload.coupon;
            payload.entity.createQuote(quote);
            return quote;

        case "CUSTOMIZE_PRICE":

            quote = Object.assign({},previousState);
            cart = QuoteHelper.updateRowPrice(quote, payload);
            payload.entity.createQuote(cart);
            return cart;

        case "QUOTE_CREATED":
            quote = Object.assign({},previousState);
            return payload.quote;

        case "ORDER_DONE":

            return QuoteModel;

        case "UPDATE_PAYMENT_DELIVERED":
            quote = Object.assign({},previousState);
            cart = QuoteHelper.updatePaymentDelivered(quote, payload);
            return cart;

        case "UPDATE_PAYMENT_REFERENCE":
            quote = Object.assign({},previousState);
            cart = QuoteHelper.updatePaymentReference(quote, payload);
            return cart;
        case "REMOVE_PAYMENT_METHOD":
            quote = Object.assign({},previousState);
            cart = QuoteHelper.removePaymentMethod(quote, payload);
            return cart;
        case "ADD_PAYMENT_METHOD":
            quote = Object.assign({},previousState);
            cart = QuoteHelper.addPaymentMethod(quote, payload);
            return cart;
        case "REMOVE_DISCOUNT_TO_CART":
            quote = Object.assign({},previousState);
            cart = QuoteHelper.removeDiscountFromCart(quote);
            payload.entity.createQuote(cart);
            return cart;
        case "ADD_DISCOUNT_TO_CART":
            quote = Object.assign({},previousState);
            cart = QuoteHelper.addDiscountToCart(quote, payload);
            payload.entity.createQuote(cart);
            return cart;

        case 'ADD_FROM_HOLD':
        case 'ADD_FROM_HOLD_LEGACY':
            return payload;

        case 'ADD_TO_HOLD':
        case 'ADD_TO_HOLD_LEGACY':
            return QuoteModel;
        case 'ADD_TO_CART':

            quote = Object.assign({},previousState);
            cart = QuoteHelper.addToCart(quote, payload);
            return cart;
        case 'UPDATE_CART_QTY':

            quote = Object.assign({},previousState);
            cart = QuoteHelper.updateCart(quote, payload.row, payload.qty, payload.append);
            if(payload.entity != null) payload.entity.createQuote(cart);
            return cart;

        case 'CLEAR_CART':
            quote = Object.assign({},QuoteModel);
            return quote;
        case 'REMOVE_FROM_CART':
            quote = Object.assign({},previousState);
            cart = QuoteHelper.removeFromCart(quote, payload.id);
            if(payload.entity!=null) payload.entity.createQuote(cart);
            return cart;
            /*return {
                ...previousState,
                cart
            };*/
        case 'persist/REHYDRATE':

        if(payload) {

            cart = payload.cart;

            const ret = {
                ...previousState,
                ...cart
            };

           // newItem = Object.assign({}, previousState, cart);

            return ret;
        }else return previousState;

        default:

            return previousState;
    }

}