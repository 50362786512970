import React from 'react';
import PaymentIcon from '@material-ui/icons/Payment';
import EuroIcon from '@material-ui/icons/Euro';
import LabelTwoToneIcon from '@material-ui/icons/LabelTwoTone';



class WebposPaymentIcon extends React.Component {

    renderIcon(method){
        if(method === "webposcash") return <EuroIcon/>;
        else if(method === "webposcard") return <PaymentIcon/>;
        else if(method === "webposcoupon") return <LabelTwoToneIcon/>;
        else return <PaymentIcon/>;
    }
    render(){

        return (
            <span>{this.renderIcon(this.props.method)}</span>
        )

    }
}
export default WebposPaymentIcon;