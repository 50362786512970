import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import Catalog from './Catalog';
import WebPosUtils from "../../helper/Utils";
import Minicart from '../cart/Minicart';
import Customer from '../cart/Customer';
import {connect} from 'react-redux';
import '../../assets/css/dashboard.css';
class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount(){
        if (WebPosUtils.isLogged() && WebPosUtils.getAppInfo()!==null)  document.title = WebPosUtils.getAppInfo().getData("name");
    }
    render() {
        if (!WebPosUtils.isLogged()) return ('');
        else
            return (


                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-8">
                                <div className="product-content"><Catalog/></div>
                            </div>
                            <div className="col-sm-4 sidebar-cart">
                                <div className="cart_header"><Customer/></div>
                                <div className="product-content"><Minicart/></div>
                            </div>
                        </div>
                    </div>

            );
    };
};

export default connect()(Dashboard);