import React from 'react';
import {List, Filter, TextInput, Pagination, withTranslate, withDataProvider, Loading} from 'react-admin';
import {connect} from 'react-redux';
import ProductList from "./ProductList";
import Form from 'react-bootstrap/Form';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import Modal from 'react-bootstrap/Modal';
import WebPosUtils from "../../helper/Utils";

const CatalogFilter = (props) => (
    <Filter {...props}>
        <TextInput label="app.catalog.search" source="q" alwaysOn />
        <TextInput label="Sku" source="sku" alwaysOn={WebPosUtils.getSettings().show_sku}  />
    </Filter>
);
function getPerpage(){
    if(WebPosUtils.isMobile()) return [4];
    else return 12;
}

const PostPagination = props => <Pagination rowsPerPageOptions={[8, 12, 16, 20]} {...props} />;


class Catalog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {scanBarcode: false, loadingBarcode:false, codigo_barras:'',
            barcode_msg:{code:'', text:''}} ;
        this._handleKeyDown=this._handleKeyDown.bind(this);

    }
    toggleBarcode() {
        if(this.state.scanBarcode){
            this.setState({scanBarcode: !this.state.scanBarcode, codigo_barras:"", barcode_msg:{code:'', text:''}});
        }else {
            this.setState({scanBarcode: !this.state.scanBarcode});
        }
    }
    addToCart(product) {
        if (product.type === 'simple') {
            this.props.dispatch({
                type: "ADD_TO_CART",
                payload: {product: product, qty: 1}
            })
        }
    }
    _handleKeyDown(e) {
        const sku = e.target.value;

        if (e.key === 'Enter') {
            this.setState({loadingBarcode:true});
            const {translate} = this.props;
            var found = false
            if(this.props.ids){
                this.props.ids.map(id =>{
                    if(this.props.data[id].sku === sku){
                        this.addToCart(this.props.data[id]);
                        this.setState({codigo_barras:"", barcode_msg:{code:'alert alert-success', text:translate("app.quote.added_to_cart")}});
                        found = true;

                    }
                    return null;
                });

            }
            if(!found){

                this.props.dataProvider.getList('catalog', {filter:{'sku':sku, store_id:this.props.store_id}, pagination:{page:1, perPage:1}, sort:{field:'id',order:'ASC'}})
                    .then(({data}) => {
                        if(data && data.length===1) {
                            this.addToCart(data[0]);
                            this.setState({codigo_barras:"", barcode_msg:{code:'alert alert-success', text:translate("app.quote.added_to_cart")}});
                            this.setState({loadingBarcode:false});
                        }else  throw new Error("");
                    })
                    .catch(error => {
                        this.setState({codigo_barras:"", barcode_msg:{code:'alert alert-danger', text: translate("app.catalog.no_result")}});
                        this.setState({loadingBarcode:false});

                    })
            }else{
                this.setState({loadingBarcode:false});
            }
        }else this.setState({codigo_barras: sku})
    }


    render() {

           const {translate} = this.props;

        return (
            <div>
                {WebPosUtils.getSettings().hide_codebar!==true && (
                <button className="barcodeReader" onClick={() => {
                    this.toggleBarcode()
                }}><ViewWeekIcon></ViewWeekIcon></button>
                )}
            <List basePath='catalog' resource='catalog' exporter={false} filters={<CatalogFilter/>} hasCreate={false} hasEdit={false} hasList={true} hasShow={false} title="app.resource.catalog" pagination={<PostPagination/>} perPage={getPerpage()}  filter={{store_id: this.props.store_id, visible_check: WebPosUtils.getSettings().visible_check}}>
                <ProductList/>
            </List>
                <Modal show={this.state.scanBarcode} onHide={() => {
                    this.toggleBarcode()
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>{translate("app.catalog.scan_barcode")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control type="text" placeholder="Código Barras" value={this.state.codigo_barras} onKeyDown={this._handleKeyDown} onChange={this._handleKeyDown}/>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.loadingBarcode?(<Loading loadingPrimary="" loadingSecondary="app.core.loading1"/>):(
                        <div className={this.state.barcode_msg.code}>{this.state.barcode_msg.text}</div>
                            )}
                    </Modal.Footer>
                </Modal>
            </div>

        );
    }
}

const mapStateToProps = (state) => {

    return {
        store_id: state.shop.id
    }
};


export default connect(mapStateToProps)(withDataProvider(withTranslate(Catalog)));