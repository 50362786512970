import React from 'react';
import { useShowController } from 'react-admin';

const MyShow = props => {
    const {
        basePath, // deduced from the location, useful for action buttons
       // defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
        loaded, // boolean that is false until the record is available
        //loading, // boolean that is true on mount, and false once the record was fetched
        record, // record fetched via dataProvider.getOne() based on the id from the location
        resource, // the resource name, deduced from the location. e.g. 'posts'
        version, // integer used by the refresh feature
    } = useShowController(props);
    return (
        <div>

            {React.cloneElement(props.children, {
                basePath,
                record,
                resource,
                version,
                loaded
            })}
        </div>
    );
}

export default MyShow;