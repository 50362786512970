import React from 'react';
import {withTranslate, Loading, showNotification, refreshView} from 'react-admin';
import {Col, Row, Button, Modal,Alert} from 'react-bootstrap';
import {Card, CardContent, TextField} from '@material-ui/core';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import "../../assets/css/catalog.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import WebPosUtils from "../../helper/Utils";
import Detail from '../catalog/Detail';
import DefaultProductImage from '../../assets/img/DefaultProductImage.jpeg';
import {Remove as RemoveIcon, Add as AddIcon, Receipt as ReceiptIcon, Delete as DeleteIcon} from '@material-ui/icons';
import dataProvider from "../../config/dataprovider";
import ProductListModel from "../../helper/ProductListModel";
import QuoteHelper from "../cart/helper/QuoteHelper";
import Payments from ".//Payments";

class ProductListChange extends React.Component {


    constructor(props) {
        super(props);
        var product_list = new ProductListModel();
        product_list.items = this.props.refund.items_to_change;
        this.state = {
            show: false,
            loading: false,
            products: null,
            change_items: product_list,
            showDetail: false,
            current_product: null
        };
        this.changeSearch = this.changeSearch.bind(this);
        this.search = this.search.bind(this);
        this.renderStockQty = this.renderStockQty.bind(this);
        this.addItemToList = this.addItemToList.bind(this);
        this.submitChange = this.submitChange.bind(this);
        this.selectPaymentMethod = this.selectPaymentMethod.bind(this);
        this.renderSummary=this.renderSummary.bind(this);
        this.getPaymentTitle=this.getPaymentTitle.bind(this);
        this.getPayments=this.getPayments.bind(this);
        this.timeoutId = 0;
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutId);
    }

    changeSearch(event) {

        clearTimeout(this.timeoutId);

        var value = event.target.value;
        if (value.length < 3) return;
        var that = this;
        this.timeoutId = setTimeout(function () {
            that.search(value)
        }, 500);

    }

    renderStockQty(product) {
        if (product.type === 'configurable') return this.props.translate('app.catalog.configurable_product');
        else {
            if (!WebPosUtils.getSettings().hide_stock) return this.props.translate('app.catalog.stock') + ':' + product.stock;
            else return "";
        }
    }

    search(sku) {
        this.setState({loading: true});
        dataProvider("GET_LIST", 'catalog', {
            filter: {
                'q': sku,
                store_id: this.props.store_id,
                visible_check: WebPosUtils.getSettings().visible_check
            }, pagination: {page: 1, perPage: 6}, sort: {field: 'id', order: 'ASC'}
        })
            .then(({data}) => {
                if (data) {

                    this.setState({products: data, loading: false});


                } else {

                    throw new Error("");
                }

            })
            .catch(error => {
                this.setState({products: null, loading: false});

            })
    }

    addItem(item) {
        if (item.type === 'simple') {
            this.addItemToList({product: item, qty: 1});
        } else {
            this.setState({current_product: item, showDetail: true});
        }
    }

    addItemToList(item) {

        const {product, qty} = item;

        var list = this.state.change_items;
        list.addUnique(product, qty);
        this.setState({change_items: list});
        this.toggleDetail(false);
        this.props.setChangeProducts(this.state.change_items.items);
    }

    changeQty(item, qty) {
        var list = this.state.change_items;
        list.updateQty(item, qty);
        this.setState({change_items: list});
        this.props.setChangeProducts(this.state.change_items.items);
    }

    removeItem(item) {
        var list = this.state.change_items;
        list.remove(item);
        this.setState({change_items: list});
        this.props.setChangeProducts(this.state.change_items.items);
    }

    toggleDetail(status) {

        if (!status) {
            this.setState({current_product: null, showDetail: status});
        } else {
            this.setState({showDetail: status});
        }
    }

    submitChange() {
       // this.props.setChangeProducts(this.state.change_items.items);
        this.props.doChange();
    }

    selectPaymentMethod(payment) {
        //this.props.setChangeProducts(this.state.change_items.items);
        this.props.selectPaymentMethod(payment);


    }
    renderSummary(){
        const {translate} = this.props;
        var diff =this.props.refund.calculeExchange4Refund();
        if(diff <0){
            return translate("app.quote.refund_negative") + " " + WebPosUtils.formatCurrency(Math.abs(diff));
        }else  if(diff >0){
            return translate("app.quote.refund_positive") + " " + WebPosUtils.formatCurrency(Math.abs(diff));
        }else{
            return WebPosUtils.formatCurrency(Math.abs(diff));
        }
    }
    getPaymentTitle(){
        const {translate} = this.props;
        var diff =this.props.refund.calculeExchange4Refund();
        if(diff <0){
            return translate("app.quote.refund_negative_payment");
        }else  if(diff >0){
            return translate("app.quote.refund_positive_payment") ;
        }else{
            return null;
        }
    }
    getPayments(){
        var diff =this.props.refund.calculeExchange4Refund();
        if(diff <0) {
           return QuoteHelper.getPaymentMethods(true, false, this.props.order_booking === 1);
        }else{
            return QuoteHelper.getPaymentMethods(false, false, this.props.order_booking === 1);
        }
    }
    render() {

        const {translate} = this.props;
        if (this.props.creatingRefund) return (
            <Loading loadingPrimary="" loadingSecondary="app.core.loading1"/>
        )
        return (
            <div>
                <Row>
                    <Col md={4}>
                        <Row>
                            <Col>
                                <TextField
                                    id="outlined-helperText"
                                    label={translate("app.catalog.search")}
                                    defaultValue=""
                                    onChange={this.changeSearch}
                                />
                            </Col>
                        </Row>
                        {this.state.loading ? (
                            <Loading loadingPrimary="" loadingSecondary="app.core.loading1"/>
                        ) : (

                            <Row className="mini-product-list">
                                {this.state.products && this.state.products.map(product =>
                                    <Col xs={6} xl={6} lg={6} sm={6} key={product.id}>
                                        <Card key={product.id}>

                                            <CardContent className="touchable" onClick={() => {
                                                this.addItem(product)
                                            }}>

                                                <div className="product_image">
                                                    {product.image ? (
                                                            <img alt={product.id}

                                                                 src={product.image} className="img-fluid"
                                                            />
                                                        ) :
                                                        <img alt={product.id}

                                                             src={DefaultProductImage} className="img-fluid"
                                                        />
                                                    }
                                                </div>
                                                <div className="product_sku">{product.sku}</div>
                                                <div className="product_name">{product.name}</div>

                                                <Row>
                                                    <Col className="product_stock" sm={6} xs={12}>
                                                        {this.renderStockQty(product)}

                                                    </Col>
                                                    <Col className="product_price" sm={6} xs={12}>
                                                        {WebPosUtils.formatCurrency(product.price)}
                                                    </Col>
                                                </Row>
                                            </CardContent>

                                        </Card>
                                    </Col>
                                )}
                            </Row>
                        )}

                        <Modal show={this.state.show} onHide={() => {
                            this.toggleDetail(false)
                        }}>
                            <Modal.Header closeButton>
                                <Modal.Title>{this.state.current_product && this.state.current_product.name}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Detail product={this.state.current_product} onAddConfigurable={this.toggleDetail}/>
                            </Modal.Body>
                            <Modal.Footer>
                            </Modal.Footer>
                        </Modal>
                    </Col>
                    <Col md={8}>
                        {this.state.change_items.items && this.state.change_items.items.map((row, index) =>
                            <Row key={row.item_id}>
                                <Col xxl={5} xl={6} className="name_td">{row.name} <br/><span
                                    className="sku">{translate("SKU")}: {row.sku}</span>
                                    <ul className="configurable_options">
                                        {row.type === "configurable" && row.selected_values.map((att, index2) => (
                                            <li key={index2}>
                                                <strong>{att.label}</strong> : {att.value_label}
                                            </li>
                                        ))}
                                    </ul>
                                </Col>

                                <Col xxl={5} xl={6} md={12} className="price_td">
                                    {WebPosUtils.formatCurrency(row.price)}
                                    <br/>
                                    <Row className="item_qty_form">
                                        <Col sm={4} xs={4} className="minus">
                                            <Button variant="secondary" size="xs" onClick={() => {
                                                this.changeQty(row, -1)
                                            }} disabled={row.qty < 2}><RemoveIcon/></Button>
                                        </Col>
                                        <Col sm={4} xs={4} className="qty">
                                            <span className="itemQty"> x{row.qty}</span>
                                        </Col>
                                        <Col sm={4} xs={4} className="plus">
                                            <Button variant="secondary" size="xs" onClick={() => {
                                                this.changeQty(row, 1)
                                            }}><AddIcon/></Button><br/>
                                        </Col>
                                    </Row>
                                    {!WebPosUtils.getPermission("hide_stock") && (
                                        <span
                                            className="stock stockInfo">{translate('app.quote.stock')}:{row.stock}</span>
                                    )}
                                    {row.stock < row.qty && (
                                        <Alert variant="danger">{translate('app.quote.no_stock')}</Alert>
                                    )}

                                </Col>

                                <Col className="actions_td text-end" xxl={2} xl={6} sm={2} md={6} onClick={() => {
                                    this.removeItem(row)
                                }}><Button variant="danger" size="sm"><DeleteIcon/></Button></Col>
                            </Row>
                        )}
                        {this.state.change_items.items && this.state.change_items.items.length > 0 &&(                                                   <Row>
                                <Col className="text-end alert alert-warning">
                                    {translate("app.quote.refund_summary")}: <strong>{this.renderSummary()}</strong>
                                </Col>

                            </Row>
                        )}
                        <div>

                            {this.state.change_items != null && this.state.change_items.items.length > 0 && this.props.refund.calculeDiffMoneyFromChange() !== 0 && (
                                <div>
                                    <Payments loading={this.state.loading}
                                              available_payment_methods={this.getPayments()}
                                              selected_payments={this.props.selected_payments}
                                              selectPaymentMethod={this.selectPaymentMethod}
                                              updatePaymentReference={this.props.updatePaymentReference}
                                              updatePaymentDelivery={this.props.updatePaymentDelivery}
                                              removePaymentMethod={this.props.removePaymentMethod}
                                              title={this.getPaymentTitle()}
                                    ></Payments>
                                    <Alert variant="info" className="size-less1">{translate('app.quote.refund_payment_warning')}</Alert>
                                   </div>
                            )}

                            {this.state.change_items != null && this.state.change_items.items.length > 0 && (

                                <Row>
                                    <Col className="text-end">
                                        <hr/>
                                        <Button variant="primary" size="lg" onClick={() => {
                                            this.submitChange()
                                        }}><ReceiptIcon/>{translate("app.quote.change_action")}</Button></Col>
                                </Row>
                            )}
                        </div>


                    </Col>
                </Row>
                <Modal show={this.state.showDetail} onHide={() => {
                    this.toggleDetail(false)
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.current_product && this.state.current_product.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Detail product={this.state.current_product} is_refund={true}
                                onAddConfigurable={this.addItemToList}/>
                    </Modal.Body>
                    <Modal.Footer>


                    </Modal.Footer>
                </Modal>
            </div>
        );

    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        dispatch,
        ...bindActionCreators({showNotification, refreshView}, dispatch)
    }
};

export default connect(null, mapDispatchToProps)(withTranslate(ProductListChange));