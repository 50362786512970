import React from 'react';
import WebPosUtils from "../../helper/Utils";
import Keyboard from 'react-simple-keyboard';
import '../../assets/css/keyboard.css';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import {withTranslate} from "react-admin";

class Calculator extends React.Component {

    constructor(props) {
        super(props);
        const {translate} = this.props;
        this.state = {
            value: this.props.default_value,
            discount_type:this.props.currency,
            discount_name:translate('app.quote.default_discount_name')
        };
        this.executeAction=this.executeAction.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.changeLabelDiscount = this.changeLabelDiscount.bind(this);
        this.executeDiscountAction=this.executeDiscountAction.bind(this);

    }

    onChange(input) {

        if (this.state.discount_type === '%' && input > 100) input = 100;
        this.setState({value: input});

    }
    onKeyPress(button) {

        if (button === '{%}') {
            if (this.state.discount_type === '%') {
                if (this.state.value > 100) this.setState({value: 100, discount_type: WebPosUtils.getCurrency()});
                else this.setState({discount_type: WebPosUtils.getCurrency()});
            }
            else this.setState({discount_type: '%'});
        }
    }
    changeLabelDiscount(e) {
        this.setState({discount_name: e.target.value});
    }

    executeAction(){
        this.props.executeAction(this.state.value);
    }
    executeDiscountAction(){
        this.props.executeAction(this.state.value,  this.state.discount_type, this.state.discount_name);
    }
    render() {
        const {translate} = this.props;
        let price = this.state.value;
        if(!price) price=this.props.default_value;
        return (

            <Modal show={this.props.showCalculator} onHide={() => {
                this.props.toggleShowCalculator(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>{translate(this.props.title)}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="numeric-pad">

                    <InputGroup className="mb-3">
                        <FormControl
                            value={price}
                            readOnly={true}
                        />

                            <InputGroup.Text id="basic-addon2">{this.state.discount_type}</InputGroup.Text>

                    </InputGroup>


                    <Keyboard
                        onChange={this.onChange}
                        onKeyPress={this.onKeyPress}
                        layout={this.props.layout}
                        theme="hg-theme-default hg-layout-numeric numeric-theme"
                        display={this.props.display}
                    />
                </Modal.Body>
                <Modal.Footer>
                    {this.props.discount ? (
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder={translate('app.quote.default_discount_name')}
                                onChange={this.changeLabelDiscount}
                            />

                                <Button variant="success" size="md" onClick={() => {
                                    this.executeDiscountAction()
                                }}>{translate('app.quote.add_discount')}
                                </Button>

                        </InputGroup>
                    ):(

                    <Button variant="success" size="md" onClick={() => {
                       this.executeAction()
                    }}>{translate(this.props.executeLabel)}
                    </Button>
                    )}

                </Modal.Footer>
            </Modal>
        )
    }
}

export default withTranslate(Calculator);