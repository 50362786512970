import WebPosUtils from "../../../helper/Utils";



class TrackHelper {

    static getTrackingProviders() {

        return WebPosUtils.getJSONProcessed('/trackingProviders/', null, "get")
            .then(response => {
                return response;
            });
    }
    static getTrackingStatuses() {

        return WebPosUtils.getJSONProcessed('/trackingStatuses/', null, "get")
            .then(response => {
                return response;
            });

    }
    static sendRowTrack(data) {

        var params = new URLSearchParams();
        data.terminal = WebPosUtils.getCurrentTerminal();

        params.append('row', JSON.stringify(data));

        return WebPosUtils.getJSONProcessed('/PUTTrackingRow/', params, "post")
            .then(response => {
                return response;
            });
    }
    static deleteRowTrack(data) {

        var params = new URLSearchParams();
        data.terminal = WebPosUtils.getCurrentTerminal();
        params.append('row', JSON.stringify(data));
        return WebPosUtils.getJSONProcessed('/DeleteTrackingRow/', params, "post")
            .then(response => {
                return response;
            });

    }
}
export default TrackHelper;