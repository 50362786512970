import React from 'react';

class BlankPage extends React.Component {
    render() {
        return (
            <div className="text-center">
                <h3>No se ha encontrado la tienda</h3>
                <p>Compruebe la url</p>
            </div>
        )
    }
}

export default BlankPage;