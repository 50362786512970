import React from 'react';
import {withTranslate, showNotification, Loading} from "react-admin";
import {Card, CardContent, CardHeader} from '@material-ui/core';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Form, InputGroup} from 'react-bootstrap';

import QuoteHelper from "../cart/helper/QuoteHelper";



class Coupon extends React.Component {

    constructor(props) {
        super(props);
        this.print = this.print.bind(this);
        this.changeType = this.changeType.bind(this);
        this.submit = this.submit.bind(this);
        this.changeName = this.changeName.bind(this);
        this.changeAmount = this.changeAmount.bind(this);
        this.changePrint = this.changePrint.bind(this);
        this.changeGiftcard = this.changeGiftcard.bind(this);
        this.changeDays = this.changeDays.bind(this);
        this.state = {name: '', type: "cart_fixed", amount: '', days:'', creatingCoupon: false, autoprint: true, last_coupon_created:null, giftcard:false};
    }

    print() {
        this.props.showNotification("app.coupon.coupon_created", "success");
    }

    changeType(event) {
        var value=event.target.value;
        if(value==="by_percent") this.setState({type: value, giftcard:false});
        else  this.setState({type: value});
    }
    changeDays(event) {
        this.setState({days: event.target.value});
    }
    changeName(event) {
        var current = event.target.value;
        this.setState({name: current});
    }

    changeAmount(event) {
        var current = event.target.value;
        this.setState({amount: current});
    }

    changePrint(event) {


        this.setState({autoprint: event.target.checked});
    }
    changeGiftcard(event) {
        var value=event.target.value;
        if(this.state.type == "by_percent") value=false;
        this.setState({giftcard: value});
    }
    submit() {

        const {showNotification} = this.props;
        var amount = Number(this.state.amount);
        if (this.state.name.length < 1) {
            showNotification("app.coupon.name_empty", 'warning');
            return;
        }
        if (amount < 0.01) {
            showNotification("app.coupon.amount_empty", 'warning');
            return;
        }
        this.setState({creatingCoupon: true});
        QuoteHelper.sendCoupon({
            name: this.state.name,
            type: this.state.type,
            amount: this.state.amount,
            days:this.state.days,
            desc:(this.state.giftcard==true)?"giftcard":null
        }).then((data) => {
            this.setState({creatingCoupon: false});
            if (data.error !== 0) {


                if (data.msg) {
                    showNotification(data.msg, 'warning');
                } else {
                    showNotification("app.coupon.error_create", 'warning');
                }
            }
            else {



                showNotification("app.coupon.coupon_created", "success");
                this.setState({last_coupon_created:data.code});
                if (this.state.autoprint) {
                    const printable = {coupon: {amount: data.amount, code: data.code, type: data.type, date_to: data.date_to}};
                    QuoteHelper.printOrder(printable).then((data) => {

                        if (data.url !== null) {

                            window.open(data.url, "_blank");

                        } else {
                            alert(data.msg);
                        }
                    });

                }
                this.setState({name: '', type: "cart_fixed", amount: '', days:'', creatingCoupon: false, autoprint: true});

            }


        })

    }

    render() {
        const {translate} = this.props;
        if (this.state.creatingCoupon) return (<Loading loadingPrimary="" loadingSecondary="app.core.loading1"/>)
        return (
            <div>
                <Card variant="outlined">
                    <CardHeader title={translate('app.coupon.header_create')}/>
                    <CardContent>
                        <Form className="col-md-6">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>{translate('app.coupon.name')}</Form.Label>
                                <Form.Control type="text" name="name" value={this.state.name}
                                              onChange={this.changeName}/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>{translate('app.coupon.action')}</Form.Label>
                                <Form.Select name="type" onChange={this.changeType}>
                                    <option value="cart_fixed">{translate('app.coupon.fixed')}</option>
                                    <option value="by_percent">{translate('app.coupon.percent')}</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>{translate('app.coupon.amount')}</Form.Label>
                                <Form.Control type="text" name="amount" value={this.state.amount}
                                              onChange={this.changeAmount}/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>{translate('app.coupon.expire')}</Form.Label>
                                <InputGroup className="mb-12">
                                    <Form.Control type="text" name="days" value={this.state.days}
                                                  onChange={this.changeDays}/><InputGroup.Text
                                    id="basic-addon2">{translate('app.common.days')}</InputGroup.Text>
                                </InputGroup>
                                <Form.Text className="text-muted">{translate('app.coupon.empty_field')}</Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" value="1" checked={this.state.giftcard}
                                            onChange={this.changeGiftcard} label={translate('app.coupon.giftcard')}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" value="1" checked={this.state.autoprint}
                                            onChange={this.changePrint} label={translate('app.coupon.print')}/>
                            </Form.Group>
                            <Button variant="primary" type="button" onClick={this.submit}>
                                Submit
                            </Button>
                            {this.state.last_coupon_created && (
                            <div
                                className="alert-info alert mt-3">{translate('app.coupon.generated_code')} : {this.state.last_coupon_created}</div>
                                )}
                        </Form>
                    </CardContent>
                </Card>
            </div>


        )
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        dispatch,
        ...bindActionCreators({showNotification}, dispatch)
    }
};

export default connect(null, mapDispatchToProps)(withTranslate(Coupon));