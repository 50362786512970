import * as React from "react";
import {withTranslate, Loading, refreshView, showNotification} from 'react-admin';
import {Edit, SimpleForm, TextInput, DateInput, SelectInput} from 'react-admin';
import Card from 'react-bootstrap/Card';
import TrackHelper from "./helper/TrackHelper";
import Grid from '@material-ui/core/Grid';
import '../../assets/css/tracking.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import WebPosUtils from "../../helper/Utils";
import DeleteIcon from '@material-ui/icons/Delete';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

class EditTrack extends React.Component {

    constructor(props) {

        super(props);

        this.state = {providers: [], statuses: [],loading: true, suggestions: [], valueComplete: '',};
        this.loadProviders();
        this.row_key = 0;


    }

    loadProviders() {
        TrackHelper.getTrackingProviders().then((data) => {
            TrackHelper.getTrackingStatuses().then((statuses) => {
                this.setState({providers: data.data, statuses:statuses.data, loading: false});
            });
        });
    }


    deleteRow(row){
        if(window.confirm(this.props.translate('app.tracking.delete_row'))) {
            this.setState({loading: true});
            TrackHelper.deleteRowTrack(row).then((res) => {
                console.log(res);
                if (res.error > 0) {
                    this.props.showNotification(res.msg, 'warning');


                } else {
                    this.props.showNotification("app.core.changes_refresh", 'success');
                    //  this.props.refreshView();
                }
                this.setState({loading: false});
            });
        }

    }

    render(){
        const {translate} = this.props;


        if (this.state.loading) {
            return (
                <Card><Card.Body> <Loading loadingPrimary="" loadingSecondary="app.core.loading1"/></Card.Body></Card>);
        }


        const SanitizedGrid = ({basePath, ...props}) => {

            return (
                <div className="tracking_form">

                    <Card>
                        <Card.Header>{translate('app.tracking.entry')}</Card.Header>
                        <Card.Body>
                            <Grid container  {...props} className="editTrack">
                                <Grid item md={3}>
                                    <TextInput multiline source="ref" label={translate('app.tracking.ref')} required="true"/>
                                </Grid>
                                <Grid item md={3}>
                                    <TextInput label="Id" source="id" hidden={true}/>
                                    <SelectInput source="provider_id" label={translate('app.tracking.provider')}
                                                 choices={this.state.providers} required={true}/>
                                </Grid>
                                <Grid item md={3}>

                                    <SelectInput source="status" label={translate('app.tracking.status_id')}
                                                 choices={this.state.statuses}/>
                                </Grid>
                                <Grid item md={3}>
                                    <DateInput label={translate('app.tracking.entry_date')} source="entry_date"/>
                                </Grid>
                                <Grid item md={3}>
                                    <TextInput multiline source="total" label={translate('app.tracking.total')}/>
                                </Grid>
                                <Grid item md={3}>
                                    <TextInput multiline source="invoice" label={translate('app.tracking.invoice')}/>
                                </Grid>
                                <Grid item md={3}>
                                    <DateInput multiline source="invoice_date"
                                               label={translate('app.tracking.invoice_date')}/>
                                </Grid>
                                <Grid item md={3}>
                                    <DateInput multiline source="invoice_expiration"
                                               label={translate('app.tracking.invoice_expiration')}/>
                                </Grid>
                            </Grid>
                            <hr/>
                            {props.record && props.record.items && (
                            <Card className="">
                                <Card.Header>{translate('app.quote.items')}</Card.Header>
                                <div>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table cart" size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>{translate('app.catalog.name')}</TableCell>
                                                    <TableCell>{translate('app.quote.qty')}</TableCell>
                                                    <TableCell>{translate('app.tracking.batch')}</TableCell>
                                                    <TableCell>{translate('app.tracking.shelflife')}</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {props.record && props.record.items && props.record.items.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell align="left" className="name_td">{row.name}
                                                        </TableCell>
                                                        <TableCell align="left" className="qty_td">{row.qty}
                                                        </TableCell>
                                                        <TableCell align="left" className="">{row.batch}
                                                        </TableCell>
                                                        <TableCell align="left" className="">{WebPosUtils.formatDateFromMysql(row.shelflife)}
                                                        </TableCell>
                                                        <TableCell><DeleteIcon className="danger" onClick={()=> {this.deleteRow(row)}}></DeleteIcon></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </Card>
                            )}
                        </Card.Body></Card>

                </div>
            );
        };
        return (

            <Edit  id={this.props.id} resource={this.props.resource} basePath={this.props.basePath} undoable={false}>
                <SimpleForm resource="trackings">
                    <SanitizedGrid/>

                </SimpleForm>
            </Edit>
        )
    }
}
const mapDispatchToProps = (dispatch) => {

    return {
        dispatch,
        ...bindActionCreators({showNotification, refreshView}, dispatch)
    }
};
export default connect(null, mapDispatchToProps)(withTranslate(EditTrack));