import React from 'react';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import {withTranslate} from "react-admin";
import WebPosUtils from "../helper/Utils";
import {showNotification} from 'react-admin';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import storeConfig from "../config/store";


class Notifications extends React.Component {

    constructor(props) {
        super(props);
        this.timerId=null;
        this.running=false;
        this.state = {loading: false, anchorEl:null};
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.getNotifications=this.getNotifications.bind(this);
        this.openNotification=this.openNotification.bind(this);

        if(this.props.settings && this.props.settings.enable_notifications) this.enableNotifications();
        else this.disableNotifications();
    }
    enableNotifications(){

        if(!this.running) {
            this.getNotifications();
            this.timerId = setInterval(() => {
                this.getNotifications()
            }, 300000);
            this.running=true;
        }
    }
    disableNotifications(){

        if(this.running) {

            clearInterval(this.timerId);
            this.running=false;
        }
    }
    getNotifications(){
        WebPosUtils.getNotifications().then((data) => {
            if (data.error > 0) {

                if (data.error === 401 || data.error === 403) {
                    showNotification("app.core.not_login", 'warning');
                    this.props.history.push("/login");
                    return;
                } else {

                    this.props.showNotification("app.core.error_500", 'warning');
                }
            }else{
                this.props.dispatch( {
                    type: "UPDATE_NOTIFICATIONS",
                    payload: data.data
                })
               // this.setState({notifications:data.data});
            }
        });
    }
    handleClick(event) {
        this.setState({anchorEl:event.currentTarget});
    };

    handleClose() {
        this.setState({anchorEl:null});
    };
    openNotification(notif){

        switch(notif.category){
            case "reservations":
                storeConfig.history.push("/reservations");
                break;
            default:
                break;
        }
    }
    render() {
        if(this.props.settings && this.props.settings.enable_notifications) this.enableNotifications();
        else this.disableNotifications();

        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (
            <div>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick} color="inherit">
                    <Badge color={this.props.notifications.items && this.props.notifications.items.length >0?("error"):("primary")} badgeContent={this.props.notifications.items && this.props.notifications.items.length}>
                        <MailIcon/>
                    </Badge>
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <div className="m-2 ">
                        {this.props.notifications.items && this.props.notifications.items.map((notif, index)=>
                            <div className="p-2 alert-warning touchable" key={index} onClick={()=>{this.openNotification(notif)}}>{notif.msg}</div>
                        )}

                    </div>

                </Popover>
            </div>
        )

    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        dispatch,
        ...bindActionCreators({showNotification}, dispatch)
    }
};
const mapStateToProps = (state) => {

    return {
        notifications: state.notifications,
        settings:state.shop.settings
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(Notifications));