class AppInfo{
    constructor() {
        this.alias="";
        this.cache = null;
        this.cutomcss="default";
        this.image=null;
        this.name=null;
        this.terminals=[];
        this.initiate();
    }
    initiate(){

        try {
            var options = JSON.parse(localStorage.getItem('customer'));
        }catch(e){
            console.log(e);
            return;
        }
        Object.assign(this, options);

    }


    getData(key){
        if(typeof this[key] !=="undefined") return this[key];
        else{
            switch( key){
                case "name": return "";
                default:
                    return null;
            }
        }
    }

}
export default AppInfo;