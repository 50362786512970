import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {routerMiddleware, connectRouter} from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import {all, fork} from 'redux-saga/effects';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import cartreducer from '../pages/cart/helper/reducer';
import holdreducer from '../pages/hold/helper/reducer';
import orderreducer from '../pages/sales/helper/reducer';
import trackingreducer from '../pages/tracking/helper/reducer';
import notificationsreducer from '../common/reducer';
import shopReducer from '../common/shopReducer';
import closeSidebarSaga from './closeSidebarSaga';
import reservationsSaga from '../common/ReservationsSaga';


import {
    adminReducer,
    adminSaga,
    USER_LOGOUT,
} from 'react-admin';
import deleteHoldSaga from "./deleteHoldSaga";

//import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

function getAlias() {
    const domain = window.location.pathname.replace("/", "");
    return domain;
}

export default ({
                    authProvider,
                    dataProvider,
                    history,
                }) => {

   const alias=getAlias()+"_";

    const persistConfig = {
        key: alias+'cart',
        storage: storage

    };
    const pReducer = persistReducer(persistConfig, cartreducer);
    const persistConfigHold = {
        key: alias+'hold',
        storage: storage

    };
    const hReducer = persistReducer(persistConfigHold, holdreducer);

    const persistConfigNotifications = {
        key: alias+'notifications',
        storage: storage

    };
    const nReducer = persistReducer(persistConfigNotifications, notificationsreducer);
    const persistConfigShop = {
        key: alias+'shop',
        storage: storage

    };

    const shReducer = persistReducer(persistConfigShop, shopReducer);

    const reducer = combineReducers({
        admin: adminReducer,
        router: connectRouter(history),
        cart: pReducer,
        hold: hReducer,
        notifications:nReducer,
        order: orderreducer,
        tracking:trackingreducer,
        shop:shReducer
    })
    ;
    const resettableAppReducer = (state, action) =>
        reducer(action.type !== USER_LOGOUT ? state : undefined, action);

    const saga = function* rootSaga() {
        yield all(
            [
                adminSaga(dataProvider, authProvider),
                closeSidebarSaga,
                deleteHoldSaga,
                reservationsSaga
            ].map(fork)
        );
    };
    const sagaMiddleware = createSagaMiddleware();

    const composeEnhancers =
        (process.env.NODE_ENV === 'development' &&
            typeof window !== 'undefined' &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                trace: true,
                traceLimit: 25,
            })) ||
        compose;

    const store = createStore(
        resettableAppReducer,
        {/* set your initial state here */},
        composeEnhancers(
            applyMiddleware(
                sagaMiddleware,
                routerMiddleware(history),
                // add your own middlewares here
            ),
            // add your own enhancers here

        ),
    );
    sagaMiddleware.run(saga);
    const persistor = persistStore(store);
    return {store:store, persistor: persistor};
};
