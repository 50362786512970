import spanishMessages from "@blackbox-vision/ra-language-spanish/index";
import englishMessages from 'ra-language-english';
import {resolveBrowserLocale} from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";


const myMessages = {
    app: {
        core: {
            loading1: 'Cargando',
            loading2: 'Espere por favor...',
            error_connect: 'No se ha podido conectar con el servidor. Pulse Aceptar para recargar la página',
            error_500: 'No se ha podido conectar con el servidor. ',
            not_login: 'Sesión expirada',
            employee: 'Empleado',
            ok: 'Ok',
            error_create_refund: "Hubo un error al crear la devolución",
            changes_refresh: 'Datos actualizados',
            submit: 'Enviar',
            settings: 'Configuración global',
            addresses: 'Direcciones',
            required_field: 'Campo obligatorio'
        },
        config: {
            autoprint: "Imprimir automáticamente tickets",
            visible_check: "Mostrar solo productos visibles",
            enable_notifications: "Activar notificaciones de pedidos online",
            show_sku: "Mostrar sku en buscador",
            hide_stock: "Ocultar stock",
            hide_codebar: "Ocultar buscar codigo de barras"
        },
        resource: {
            catalog: 'Compra',
            hold: 'Pedidos en espera',
            orders: 'Historial de pedidos',
            funds: "Caja",
            reservations: "Reservas stock",
            tracking: "Tracking Stock",
            customers: 'Clientes',
            coupons: 'Cupones descuento'
        },
        notification: {
            'Failed to fetch': 'Error de conexión',

        },
        page: {
            loading: 'Cargando APP',
            dashboard: 'Compra'
        },
        catalog: {
            search: 'Buscar',
            stock: 'Stock',
            no_attributes: 'Error de conexión.',
            extra_stock: 'Otros almacenes',
            sku: 'Referencia',
            no_result: 'No se encontró la referencia',
            scan_barcode: 'Leer código de barras',
            name: 'Artículo',
            stock_save: 'Guardar',
            update_stock: 'Cambiar stock',
            configurable_product: 'Producto configurable',
            backorder_error: 'No se pueden añadir productos al carrito que no tienen stock',
            update_cart_qty: 'Cambiar cantidad',
            cart_qty_save: 'Guardar',
            hold_name_header: 'Introduce un nombre (opcional)',
            create_customer: 'Crear nuevo cliente',
            freeproduct_name_header: 'Regalar producto',
            free_product_btn: 'Regalar',
            gift: 'Regalo'
        },
        quote: {
            name: 'Nombre',
            reference: 'Ref',
            subtotal: 'Base Imponible',
            tax: 'Iva',
            grand_total: 'Total',
            discount: 'Descuento',
            hold: 'Pausar',
            customer_name: 'Cliente',
            purchase: 'Comprar',
            created_at: 'Creado',
            updated_at: 'Modificado',
            delete: 'Eliminar',
            continue: 'Recuperar',
            add_discount: 'Descuento',
            add_coupon: 'Cupón',
            remove_coupon: 'Quitar cupón',
            enter_coupon: 'Código de cupón',
            default_discount_name: 'Descuento personalizado',
            payments_methods: 'Métodos de pago',
            operation_number: 'Referencia operación',
            payment_delivered: 'Entregado',
            payment_refunded: 'Devuelto',
            change: 'A devolver',
            insufficient_founds: 'Pendiente de pago',
            enought_money: 'Ya está pagado todo el importe',
            amount_due: 'Pendiente de pago',
            amount_refund: 'Devuelto',
            total_due: 'Pendiente',
            finish_purchase: 'Finalizar Compra',
            partial_purchase: 'Pedido parcial',
            no_payments_methods: 'No se han encontrado métodos de pago disponibles.',
            no_payment_selected: 'Seleccione una forma de pago',
            add_payment: 'Añadir pago',
            payment_added: 'Pago añadido',
            remove_discount: 'Quitar descuento',
            order_complete: 'Pedido completado',
            print: 'Imprimir',
            items: 'Artículos',
            price: 'Precio',
            total_row: 'Total',
            qty: 'Cantidad',
            order_header: 'Pedido',
            add_to_cart: "Añadir al carrito",
            refund: "Devolución",
            total_refund: "Total a devolver",
            subtotal_without_discount: "Subtotal",
            total_refund_fix: "Ajuste manual",
            refund_created: "Devolución creada con éxito",
            stock: "Stock",
            nostock: "Los artículos marcados en rojo no tienen suficiente stock y se excluirán de la compra si no modifica la cantidad",
            no_partial_allowed: "No se permiten pedidos parciales",
            cash: "Efectivo",
            card: "Tarjeta",
            refund_empty: "No se puede crear una devolución con importe cero. Introduzca el importe en el método de pago.",
            qty_refunded: "Devuelto",
            refund_header: "Devolución",
            max: "Max",
            refund_not_allowed: "Todos los artículos han sido devueltos",
            online_order: "Pedido online",
            terminal: "Terminal",
            customice_price: "Personalizar precio",
            cancel_customice_price: "Cancelar precio personalizado",
            add_amount: "Insertar importe",
            ok: "Aceptar",
            funds: "Movimientos de caja",
            id: "Id",
            refund_requirements: "Selecciona artículo(s) a devolver y una forma de pago para generar la devolución",
            refund_discount_warning: "Este pedido contiene un descuento que no es incluído por defecto en el importe de la devolución. Revisa el importe que vas a devolver, para asegurarte que es correcto.",
            return_to_stock: "Restock",
            extra_stocks: "Disponibilidad",
            payment_coupon: 'vale descuento',
            refund_excedeed: 'Se va a devolver más cantidad de la pagada',
            negative_order: 'No se puede realizar una compra con importe negativo',
            clear: "Vaciar",
            clear_cart: 'Borrar carrito',
            clear_confirmation: '¿Borrar todos los elementos de la cesta?',
            added_to_cart: 'Producto añadido al carrito',
            billing_address: 'Dirección de facturación',
            shipping_address: 'Dirección de envío',
            no_address: 'No hay dirección',
            select_address: 'Selecciona dirección',
            changed_store: 'Se ha cambiado la tienda actual',
            selected_payments: 'Métodos de pago seleccionados',
            shipping_amount: 'Gastos de envío',
            print_gift_ticket: 'Imprimir ticket regalo',
            print_order_ticket: 'Imprimir ticket compra',
            change_item: 'Cambiar artículo',
            change_items: 'Cambiar artículos',
            refund_message: 'Se procederá a hacer una devolución del artículo a cambiar, y un pedido nuevo con el artículo que se le da al cliente.',
            change_action: 'Cambiar',
            refund_items_change: 'Artículos nuevos',
            refund_items: 'Artículos a devolver o cambiar',
            refund_summary: 'Diferencia de coste',
            refund_positive: "A pagar por el cliente",
            refund_negative: "A abonar al cliente",
            refund_negative_payment: "Forma de pago para abonar al cliente",
            refund_positive_payment: "Forma de pago del cliente",
            confirm_refund: "Esta devolución no cubre el gasto completo. ¿Estás seguro que quieres continuar?",
            confirm_refund_nostock: "Hay artículos con menos stock del que se quiere sacar. Si contúas es posible que el pedido nuevo no se realice. ¿Estás seguro que quieres continuar?",
            no_stock: "No hay stock suficiente",
            refund_payment_warning: "En el caso de pago en efectivo por parte del cliente, introducir la cantidad exacta. Ejemplo: si la diferencia a pagar son 2eu, poner esta cantidad independientemente que el cliente nos de un billete de 5eu",
            payment_installment: "Pago aplazado",
            void_order: "Cancelar pedido",
            void_order_confirm:"Va a cancelar este pedido. Esta acción no es reversible. ¿Continuar?",
            order_voided:"Pedido cancelado",
            error_void_order:"Error al cancelar el pedido",
            order_status:"Estado",
            submit_booking:"Emitir factura",
            submit_booking_confirm:"Va a emitir la factura de este pedido. ¿Continuar?",
            invoice_submited:"Factura emitida",
            invoice_already_submited:"Factura ya emitida",
            error_booking_order:"Error al generar la factura"

        },
        ticket: {
            items: 'Artículo',
            price: 'PVP',
            total_row: 'Total',
            qty: 'Ud'
        },
        hold: {
            list: 'Pedidos en espera',

        },
        auth: {
            terminal: 'Terminal',
            terminals: 'Terminales'
        },
        sales: {
            created_at: 'Creado'
        },
        funds: {
            summary: "Resumen del día",
            grand_total_no_coupon: "Total sin vales"
        },
        reservations: {
            accepted: 'Apartado',
            accepted_at: 'Fecha apartado',
            accepted_user: 'Por el usuario',
            reserve_stock: 'Apartar producto',
            reservation_fail: 'Error al apartar el producto',
            shipped: 'Enviado'
        },
        tracking: {
            ref: 'referencia',
            entry_date: 'Fecha',
            status: 'Estado',
            provider: 'Proveedor',
            track_header: 'Datos de la entrada',
            batch: 'Lote',
            shelflife: 'Caducidad',
            invoice: 'Factura asociada',
            invoice_date: 'Fecha factura',
            invoice_expiration: 'Vencimiento',
            total: 'Importe',
            entry: 'Entrada de stock',
            items: 'Artículos',
            add_row: 'Añadir artículo',
            delete_row: '¿Desea eliminar esta entrada de stock?',
            status_id: 'Estado'
        },
        customer: {
            select: 'Selecciona cliente',
            firstname: 'Nombre',
            lastname: 'Apellidos',
            email: 'Email',
            guest: 'Cliente anónimo',
            no_address: 'Este cliente no tiene direcciones creadas',
            address: 'Dirección',
            postcode: 'Código postal',
            phone: 'Teléfono',
            city: 'Ciudad',
            taxvat: 'DNI',
            header_create: 'Crear nuevo cliente'

        },
        common: {
            close: "Cerrar",
            days: "días"

        },
        coupon: {
            header_create: "Crear cupón descuento",
            name: "Nombre descuento",
            action: "Tipo descuento",
            fixed: "Importe fijo",
            percent: "Porcentaje sobre el total",
            amount: "Importe descuento",
            coupon_created: "Cupón creado",
            amount_empty: "Debe introducir un importe",
            name_empty: "Debe introducir un nombre",
            error_create: "hubo un error al crear el cupón de descuento. Reintente en unos instantes",
            print: "Imprimir cupón al terminar",
            expire: "Expira en",
            empty_field: "Dejar en blanco para que no expire",
            generated_code: "Código generado",
            giftcard:"Es una tarjeta regalo (solo funciona con tipo descuento = importe fijo)"
        }
    },
    free: "Pago con vale",
    'Internal Server Error': 'Internal Server Error',
    'Error: Request failed with status code 401': 'No dispone de permiso para ejecutar esta acción'
};
const messages = {
    es: {
        ...spanishMessages,
        ...myMessages
    },
    en: englishMessages,
};

const i18nProvider = polyglotI18nProvider(
    locale => messages[locale] ? messages[locale] : messages.es,
    resolveBrowserLocale(),
    {allowMissing: true}
);
export default i18nProvider;

