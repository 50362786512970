import React from 'react';

import Card from 'react-bootstrap/Card';
import {
    withTranslate,


} from 'react-admin';



class CustomerAddress extends React.Component {



    render() {

        return (

                <Card>
                    <Card.Body className="address">
                        <p>{this.props.address.firstname} {this.props.address.lastname}</p>
                        <p>{this.props.address.street.map((row, index) => (
                            <span key={index}>{row} </span>
                        ))}</p>
                        <p>{this.props.address.postcode} {this.props.address.city}</p>
                        <p>{this.props.address.region}</p>
                        <p>{this.props.address.phone}</p>
                    </Card.Body>
                </Card>

        );
    }

}

export default withTranslate(CustomerAddress);