
export default (previousState = {}, {type, payload}) => {

    var notifications;

    switch (type) {



        case 'UPDATE_NOTIFICATIONS':

            notifications = Object.assign({},previousState);
            notifications.items=payload;
            return notifications;

        default:
            return previousState;
    }

}