import React from 'react';
import {withTranslate, showNotification} from "react-admin";
import WebPosUtils from "../../helper/Utils";
import QuoteHelper from "../cart/helper/QuoteHelper";
import WebposPaymentIcon from '../sales/WebposPaymentIcon';
import {Card, Row, Col} from 'react-bootstrap';


class Summary extends React.Component {

    constructor(props) {

        super(props);

        this.state = {record: null, mounted: true, created_at: this.props.created_at, reload:true, booking:this.props.booking};

        this.last=null;
        this.booking=null;
    }


    static getDerivedStateFromProps(props, state) {
       if(props.created_at!==state.created_at || props.booking!==state.booking){
          
           return {
               reload: true

           };

       }
        return null;
    }
    componentDidUpdate(prevProps, prevState) {
       if (this.state.reload) {
            this.loadSummary();
        }

    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyDown);
        this.setState({mounted: false});
    }



    loadSummary() {

        if (this.last === this.props.created_at && this.booking === this.props.booking) return;
        this.last = this.props.created_at;
        this.booking = this.props.booking;

        QuoteHelper.getFundsSummary(this.props.created_at, this.props.booking).then((data) => {
            if (this.state.mounted) {
                var total = 0;
                var total_nocoupon=0;
                if (data.error > 0) {
                    if (data.error === 401 || data.error === 403) {
                        showNotification("app.core.not_login", 'warning');
                        this.props.history.push("/login");
                        return;
                    } else {
                        console.log(data);
                        showNotification("app.core.error_500", 'warning');

                    }
                } else {
                    if (data.payments) {
                        data.payments.map((method, index) => {
                            total += method.delivered;

                            if(method.code !=="webposcoupon") total_nocoupon+=method.delivered;
                            return null;
                        });
                    }
                    this.setState({record: data, total: total, total_nocoupon:total_nocoupon, reload:false});

                }
            }
        });
    }

    render() {
        const {translate} = this.props;
        return (

            <Card className="payment_methods">
                <Card.Header>{translate('app.funds.summary')} {WebPosUtils.formatDateFromMysql(this.props.created_at, false)}</Card.Header>
                <Card.Body>
                    <div className="row payment_methods">
                        {this.state.record && this.state.record.payments && this.state.record.payments.map((method, index) => (
                            <div className="col-6" key={method.code}>
                                <Card>
                                    <Card.Body className="text-center">
                                        <WebposPaymentIcon method={method.code}/>
                                        <Card.Text>

                                                <span>{method.label}</span> x <strong>{WebPosUtils.formatCurrency(method.delivered)}</strong>

                                        </Card.Text>

                                    </Card.Body>
                                </Card>
                            </div>

                        ))}
                    </div>
                    <div className="text-center mt-5 funds_totals">
                        <Row>
                            <Col xl={6} lg={12}>
                                <h5>{translate("app.quote.grand_total")}: <span>{WebPosUtils.formatCurrency(this.state.total)}</span></h5>
                            </Col>
                            <Col xl={6} lg={12}>
                                <h5>{translate("app.funds.grand_total_no_coupon")}: <span>{WebPosUtils.formatCurrency(this.state.total_nocoupon)}</span></h5>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
            </Card>

        );
    }
}

export default (withTranslate(Summary));