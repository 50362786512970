import QuoteModel from "./QuoteModel";

class RefundModel extends QuoteModel{
    constructor() {
        super();
        this.category='refund';
        this.items_to_change=[];
    }

    getQtyRefunded() {
        let total_refunded = 0;
        this.items.forEach(function (item) {
                total_refunded += item.qty_to_return;
            }
        );
        return total_refunded;
    }
    getPaymentTotals(){
        let total_refunded = 0;
        this.payments.forEach(function (item) {
                total_refunded += item.delivered;
            }
        );
        return total_refunded;
    }
    getTotalChangeOldItems(){
        let total_old = 0;
        this.items.forEach(function (item) {
                total_old += (item.price-item.discount)*item.qty_to_return;
            }
        );
        return total_old;
    }
    getTotalChangeNewItems(){
        let total_new = 0;
        this.items_to_change.forEach(function (item) {
                total_new += item.price*item.qty;
            }
        );
        return total_new;
    }
    getTotalChange(){
        let total_new = this.getTotalChangeNewItems();
        let total_old = this.getTotalChangeOldItems();
        return Math.min(total_old,total_new);

    }
    calculeDiffMoneyFromChange(){
        if(this.category === "change") {
            var total_new = this.getTotalChangeNewItems();
            var total_old = this.getTotalChangeOldItems();
            return total_new - total_old;
        }
        return 0;
    }
    calculeExchange4Refund() {
        var dif=0;
        var delivered = this.getDeliveredAmount();
        if(this.category === "change"){

            var total_new = this.getTotalChangeNewItems();
            var total_old = this.getTotalChangeOldItems();
            dif = total_new - (total_old + delivered);

        }else {
            dif = this.grand_total_final - delivered;


        }
        return Math.round(dif * 100) / 100;
    }
    refreshItemTotals(){
        let _subtotal=0, _tax=0, _grand_total=0;

        this.items.forEach(function (item) {

            _subtotal += item.price_without_tax * item.qty_to_return;
            _tax += (item.tax/item.qty)*item.qty_to_return;
            _grand_total += (item.price - item.discount/item.qty)*item.qty_to_return;
            }
        );
        this.subtotal=_subtotal;
        this.tax=_tax;
        this.grand_total=_grand_total;
    }

};
export default RefundModel;
