import React from 'react';
import {List, Datagrid, TextField, NumberField, DateField, Filter, Loading} from 'react-admin';
import {withTranslate} from 'react-admin';

import {DateInput} from 'react-admin';
import 'bootstrap/dist/css/bootstrap.min.css';
import Summary from "./Summary";
import "../../assets/css/funds.css";
import WebPosUtils from "../../helper/Utils";

const OrderFilter = (props) => (
    <Filter {...props}>
        <DateInput label="app.catalog.search" source="q" alwaysOn />

    </Filter>
);

const Aside = ({...props}) => {

    return (
        <div className="col-5 funds_summary">
            <Summary created_at={props.filterValues.q} booking={props.booking}/>
        </div>
    );
}

class Funds extends React.Component {

    constructor(props) {
        super(props);
        this.state = {loading: false, booking: null};

    }
    onKeyDown = (e) => {

        if (e.ctrlKey && e.key === "b"){
            if(this.state.booking === "1") this.setState({booking:null});
            else this.setState({booking:"1"});
        }

    }
    componentDidMount(){
        if(WebPosUtils.getSettings().booking) {
            document.addEventListener('keydown', this.onKeyDown);
        }
    }
    componentWillUnmount(){
        this.mounted=false;
        if(WebPosUtils.getSettings().booking) {
            document.removeEventListener('keydown', this.onKeyDown);
        }
    }
    getToday(){

        return  new Date().toISOString().split("T")[0];
    }
    render() {


        if (this.state.loading) return (<Loading loadingPrimary="" loadingSecondary="app.core.loading1"/>)
        return (
            <div className="container-fluid">

                <div className="row">

                    <div className="col-md-12 funds-container">
                        <List basePath='funds' resource='funds' exporter={false} hasCreate={false} hasEdit={false}
                              hasList={false} hasShow={false} bulkActionButtons={false} title="app.resource.funds"  filter={{booking:this.state.booking}}
                              filters={<OrderFilter/>} filterDefaultValues={{q:this.getToday()}} sort={{field: 'created_at', order: 'DESC'}} aside={<Aside booking={this.state.booking}/>}>
                            <Datagrid rowClick={this.selectItem}>
                                <DateField source="created_at" label="app.quote.created_at" showTime/>
                                <TextField source="increment_id" label="app.quote.reference" sortable={false}/>
                                <TextField source="user" label="app.core.employee" sortable={false} cellClassName="noprint" headerClassName="noprint"/>
                                <TextField  cellClassName="noprint" headerClassName="noprint" source="terminal" label="app.quote.terminal" sortable={false}/>
                                <TextField source="payment" label="app.quote.payments_methods" sortable={false}/>
                                <NumberField source="total" label="app.quote.grand_total"
                                             options={{style: 'currency', currency: 'EUR'}} sortable={false}/>

                            </Datagrid>
                        </List>
                    </div>


                </div>
            </div>
        );
    }
}


export default (withTranslate(Funds));