
export default (previousState = {}, {type, payload}) => {


    switch (type) {


        default:

            return previousState;
    }

}