// esto es para definir rutas personalizadas
import React from 'react';
import { Route } from 'react-router-dom';
import Hold from '../pages/hold/Hold';
import Checkout from '../pages/checkout/Checkout';
import Order from '../pages/sales/Order';
import Print from '../common/Print';
import CustomerList from '../pages/customer/CustomerList';
import BlankPage from '../common/BlankPage';
import Settings from "../pages/settings/Settings";

export default [
    <Route exact path="/hold" component={Hold} />,
    <Route exact path="/orders" component={Order} />,
    <Route exact path="/checkout" component={Checkout}/>,
    <Route exact path="/print" component={Print} noLayout />,
    <Route exact path="/customerlist" component={CustomerList}/>,
    <Route exact path="/settings" component={Settings}/>,
    <Route exact path="/blank" component={BlankPage} noLayout/>
];