import React from 'react';
import {List, Datagrid, TextField, NumberField, DateField,  Filter, TextInput, Loading, Pagination, showNotification, refreshView, withTranslate} from 'react-admin';
import {Row, Col, Modal, Button} from 'react-bootstrap';
import {connect} from 'react-redux';
import Quote from './Quote';
import WebPosUtils from "../../helper/Utils";
import Payments from './Payments';
import QuoteHelper from "../cart/helper/QuoteHelper";
import {bindActionCreators} from 'redux';
import PaymentModel from './model/PaymentModel';
import Refund from './Refund';
import MyShow from '../../common/MyShow';


const OrderFilter = (props) => (
    <Filter {...props}>
        <TextInput label="app.catalog.search" source="q" alwaysOn />
    </Filter>
);
const PostPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

class Order extends React.Component {

    constructor(props) {
        super(props);

        this.state = {id: 0, record: null, current_order:null, showWindowPortal: false, available_payment_methods: QuoteHelper.getPaymentMethods(false, true), loading: false,show: false, payment:null, loadingPayment:false, refunding:false, creatingRefund:false, printRefund:false, currentRefund:null, new_payments:null, voiding:null, booking: null};
        this.selectItem = this.selectItem.bind(this);
        this.print = this.print.bind(this);
        this.toggleDetail = this.toggleDetail.bind(this);

        this.selectPaymentMethod = this.selectPaymentMethod.bind(this);
        this.updatePaymentReference = this.updatePaymentReference.bind(this);
        this.updatePaymentDelivery = this.updatePaymentDelivery.bind(this);
        this.addPayment = this.addPayment.bind(this);
        this.doRefund = this.doRefund.bind(this);
        this.printRefund = this.printRefund.bind(this);
        this.toggleRefund=this.toggleRefund.bind(this);
        this.printGiftTicketAction = this.printGiftTicketAction.bind(this);
        this.removePaymentMethod=this.removePaymentMethod.bind(this);
        this.selectedPaymentMethod=this.selectedPaymentMethod.bind(this);
        this.toggleVoid=this.toggleVoid.bind(this);
        this.toggleBooking=this.toggleBooking.bind(this);
        this.onKeyDown=this.onKeyDown.bind(this);
        this.mounted=true;
        this.currentRefund=null;
        this.currentOrder=null;

    }
    onKeyDown = (e) => {

        if (e.ctrlKey && e.key === "b"){
            if(this.state.booking === "1") this.setState({booking:null});
           else this.setState({booking:"1"});
        }

    }
    componentDidMount(){
        if(WebPosUtils.getSettings().booking) {
            document.addEventListener('keydown', this.onKeyDown);
        }
    }
    componentWillUnmount(){
        this.mounted=false;
        if(WebPosUtils.getSettings().booking ) {
            document.removeEventListener('keydown', this.onKeyDown);
        }
    }
    toggleDetail(status=true) {

        if(!status) this.setState({show: status, payment:null });
        else this.setState({show: status});

    }
    toggleRefund(status, refund) {

        this.currentRefund=refund;
        this.setState({refunding: status});
    }



    selectItem(id, basePatch, record) {


        this.setState({id: id, record: record});
    }

    print() {
        const printable = {id_order:this.state.record.id};
        QuoteHelper.printOrder(printable, null).then((data) => {

            if(data.url !== null){

                window.open(data.url, "_blank");

            }else{
                alert(data.msg);
            }
        });
    }
    printGiftTicketAction(){
        const printable = {id_order:this.state.record.id, is_gift:true};
        QuoteHelper.printOrder(printable).then((data) => {

            if(data.url !== null){

                window.open(data.url, "_blank");

            }else{
                alert(data.msg);
            }
        });
    }
    printRefund(refund){
        const printable = {id_order:this.state.record.id, id_refund: refund.id};
        QuoteHelper.printOrder(printable).then((data) => {

            if(data.url !== null){

                window.open(data.url, "_blank");

            }else{
                alert(data.msg);
            }
        });
        this.setState({currentRefund:refund});

    }
    selectedPaymentMethod(){

        if(this.state.payment==null) return null
        else return [this.state.payment];
    }
    selectPaymentMethod(payment) {


        //if(this.state.record.total_due == 0) return;
        payment.delivered =this.state.record.total_due;
        payment.name = payment.label;
        this.setState({payment: payment});
    }



    updatePaymentReference(e) {

        var payment = this.state.payment;
        payment.reference = e.target.value;
        this.setState({payment: payment});

    }

    updatePaymentDelivery(e) {

        var payment = this.state.payment;
        payment.delivered = e.target.value;
        this.setState({payment: payment});



    }
    removePaymentMethod(){
        this.setState({payment: null});

    }

    addPayment(){
        this.setState({loadingPayment:true});
        QuoteHelper.sendPaymentOrder(this.state.record, this.state.payment).then((data) => {

            if (data.error === 1) {
                this.props.showNotification(data.msg, 'warning');
            }
            else {
                this.props.showNotification("app.quote.payment_added", "success");
                this.props.refreshView();
                this.setState({'show':false, 'payment':null});

            }
            this.setState({loadingPayment:false});

        })
    }
    doRefund(refund){
        const { showNotification} = this.props;

        if(refund.getQtyRefunded() === 0){
            this.props.showNotification("app.quote.refund_empty", 'warning');
            this.props.refreshView();
            this.setState({creatingRefund:false, refunding:false, showWindowPortal:false});
            return;
        }
        if(!refund.payments.length > 0){
            this.props.showNotification("app.quote.no_payment_selected", 'warning');
            this.props.refreshView();
            this.setState({creatingRefund:false, refunding:false, showWindowPortal:false});
            return;
        }
        this.setState({creatingRefund:true});
        QuoteHelper.sendRefundOrder(this.state.record, refund).then((data) => {
            if(this.mounted) {

                if (data.error !== 0) {


                    /*this.props.refreshView();*/
                    this.setState({creatingRefund: false, refunding: false, showWindowPortal: false});

                    if(data.msg) {
                        showNotification(data.msg, 'warning');
                    }else{
                        showNotification("app.core.error_create_refund", 'warning');
                    }

                }
                else {

                    this.props.refreshView();
                    this.setState({'show': false, 'payment': PaymentModel});
                    this.setState({creatingRefund: false, refunding: false, showWindowPortal: false});

                    refund.increment_id = data.increment_id;
                    refund.created_at = data.created_at;
                    refund.coupon = data.coupon;
                    refund.id=data.id;

                    showNotification("app.quote.refund_created", "success");
                    if(WebPosUtils.getSettings().autoprint) {
                        this.printRefund(refund);
                    }

                }

            }
        })
    }
    toggleVoid(status) {
        this.setState({voiding: status});
    }
    toggleBooking(status) {
        this.setState({booking_order: status});
    }
    void(){
        const { showNotification} = this.props;

        /*if(this.state.record.can_cancel !== 1){
            this.props.showNotification("app.quote.error_void_order", 'warning');
            this.props.refreshView();
            this.setState({voiding:false});
            return;
        }*/

        this.setState({loading:true});
        QuoteHelper.voidOrder(this.state.record).then((data) => {
            if(this.mounted) {

                if (data.error !== 0) {

                    /*this.props.refreshView();*/
                    this.setState({loading: false, voiding:false});

                    if(data.msg) {
                        if(typeof data.msg == "string") showNotification(data.msg, 'warning');
                        else showNotification("app.quote.error_void_order", 'warning');
                    }else{
                        showNotification("app.quote.error_void_order", 'warning');

                    }

                }
                else {

                    this.props.refreshView();
                    this.setState({'voiding': false, 'loading': false});

                    showNotification("app.quote.order_voided", "success");


                }

            }
        })
    }
    booking_order(){
        const { showNotification} = this.props;

        if(this.state.record.webpos_booking !== 1){
            this.props.showNotification("app.quote.invoice_already_submited", 'warning');
            this.props.refreshView();
            this.setState({booking_order:false});
            return;
        }

        this.setState({loading:true});
        QuoteHelper.bookOrder(this.state.record).then((data) => {
            if(this.mounted) {

                if (data.error !== 0) {

                    /*this.props.refreshView();*/
                    this.setState({loading: false, booking_order:false});

                    if(data.msg) {
                        if(typeof data.msg == "string") showNotification(data.msg, 'warning');
                        else showNotification("app.quote.error_booking_order", 'warning');
                    }else{
                        showNotification("app.quote.error_booking_order", 'warning');

                    }

                }
                else {

                    this.props.refreshView();
                    this.setState({'booking_order': false, 'loading': false});

                    showNotification("app.quote.invoice_submited", "success");


                }

            }
        })
    }

    render() {

        const {translate} = this.props;
        if(this.state.loading) return (<Loading loadingPrimary=""  loadingSecondary="app.core.loading1"/>)

        return (

            <div className="container-fluid">

                <Row>

                    <Col lg={6} xs={12}>
                        <List basePath='orders' resource='orders' exporter={false} hasCreate={false} hasEdit={false}
                              hasList={false} hasShow={false} bulkActionButtons={false} title="app.resource.orders" filters={<OrderFilter />} sort={{ field: 'created_at', order: 'DESC' }} pagination={<PostPagination/>} filter={{booking:this.state.booking}}>

                            <Datagrid rowClick={this.selectItem}>
                                <DateField source="created_at" label="app.quote.created_at" showTime/>
                                <TextField    headerClassName="d-xl-table-cell d-none" cellClassName="d-xl-table-cell d-none" source="terminal" label="app.quote.terminal" sortable={false}/>

                                <TextField source="increment_id" label="app.quote.reference"/>
                                <TextField source="customer_name" label="app.quote.customer_name" sortable={false}  headerClassName="d-xxl-table-cell d-none" cellClassName="d-xxl-table-cell d-none" />

                                <NumberField   headerClassName="d-lg-table-cell d-none" cellClassName="d-lg-table-cell d-none" source="grand_total" label="app.quote.grand_total"
                                             options={{style: 'currency', currency: 'EUR'}} sortable={false}/>
                                <NumberField   headerClassName="d-lg-table-cell d-none" cellClassName="d-lg-table-cell d-none" source="total_due" label="app.quote.total_due"
                                             options={{style: 'currency', currency: 'EUR'}} sortable={false}/>
                            </Datagrid>

                        </List>
                    </Col>
                    <Col lg={6} xs={12} >
                        {this.state.record && (
                            <div>

                            {this.state.id > 0 && (
                                <div>
                            <MyShow resource='orders' id={this.state.id} basePath='order' title=' '>
                                <Quote order="1" print={this.state.showWindowPortal}  printAction={this.print} printRefundAction={this.printRefund} printGiftTicketAction={this.printGiftTicketAction} toggleDetail={this.toggleDetail} toggleRefund={this.toggleRefund} togglePaymentList={this.toggleDetail} toggleVoid={this.toggleVoid}  toggleBooking={this.toggleBooking}>

                                </Quote>

                            </MyShow>

                                </div>
                            )}

                            </div>
                            )}

                        {this.state.record && (

                        <Modal show={this.state.show} onHide={() => {
                            this.toggleDetail(false)
                        }} size="xl">
                            <Modal.Header closeButton>
                                <Modal.Title>{translate('app.quote.no_payment_selected')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Payments loading={this.state.loading}
                                          available_payment_methods={QuoteHelper.getPaymentMethods()}
                                          selected_payments={this.selectedPaymentMethod()}
                                          selectPaymentMethod={this.selectPaymentMethod}
                                          updatePaymentReference={this.updatePaymentReference} updatePaymentDelivery={this.updatePaymentDelivery}
                                          removePaymentMethod={this.removePaymentMethod}
                                          can_add={false}
                                ></Payments>

                            </Modal.Body>
                            <Modal.Footer>
                                {this.state.loadingPayment ?(
                                    <Loading loadingPrimary=""  loadingSecondary="app.core.loading1"/>):(
                                <Button variant="primary" size="md" onClick={() => {
                                    this.addPayment()
                                }}>{translate('app.quote.add_payment')}
                                </Button>
                                    )}
                            </Modal.Footer>
                        </Modal>
                        )}
                        {this.state.record && (
                            <Modal show={this.state.refunding} onHide={() => {
                                this.toggleRefund(false, null)
                            }} size="xl">
                                <Modal.Header closeButton>
                                    <Modal.Title>{translate('app.quote.refund')}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                        <Refund order="1" creating={true} print={this.state.showWindowPortal}  printAction={this.printRefund} toggleDetail={this.toggleDetail} doRefund={this.doRefund} creatingRefund={this.state.creatingRefund} showNotification={this.showNotification} record={this.currentRefund} currentorder={this.state.record}>
                                        </Refund>
                                </Modal.Body>

                            </Modal>
                        )}
                        {this.state.record && (
                            <Modal show={this.state.voiding} onHide={() => {
                                this.toggleVoid(false, null)
                            }} size="xl">
                                <Modal.Header closeButton>
                                    <Modal.Title>{translate('app.quote.void_order')}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {translate('app.quote.void_order_confirm')}
                                </Modal.Body>
                                <Modal.Footer>
                                    {this.state.loading ? (
                                            <Loading loadingPrimary="" loadingSecondary="app.core.loading1"/>) :
                                        (<Button variant="primary" size="md" onClick={() => {
                                            this.void()
                                        }}>{translate('app.quote.void_order')}
                                        </Button>)
                                    }
                                </Modal.Footer>

                            </Modal>
                        )}
                        {this.state.record && (
                            <Modal show={this.state.booking_order} onHide={() => {
                                this.toggleBooking(false, null)
                            }} size="xl">
                                <Modal.Header closeButton>
                                    <Modal.Title>{translate('app.quote.submit_booking')}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {translate('app.quote.submit_booking_confirm')}
                                </Modal.Body>
                                <Modal.Footer>
                                    {this.state.loading ? (
                                            <Loading loadingPrimary="" loadingSecondary="app.core.loading1"/>) :
                                        (<Button variant="primary" size="md" onClick={() => {
                                            this.booking_order()
                                        }}>{translate('app.quote.submit_booking')}
                                        </Button>)
                                    }
                                </Modal.Footer>

                            </Modal>
                        )}

                    </Col>

                </Row>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {

    return {
        dispatch,
        ...bindActionCreators({showNotification, refreshView}, dispatch)
    }
};

export default connect(null, mapDispatchToProps)(withTranslate(Order));