const QuoteModel = {
    id:0,
    id_customer : 0,
    customer_name:null,
    customer_data:null,
    customer_billing_address:null,
    customer_shipping_address:null,
    subtotal :0,
    grand_total :0,
    total_due:0,
    shipping :0,
    discount_fixed :0,
    discount_amount:0,
    discount_name:'',
    tax :0,
    created_at : null,
    updated_at : null,
    items : [],
    payments:[],
    terminal:0,
    booking:null

};

export default QuoteModel;
