
class QuoteModel {
    constructor() {
        this.id = 0;
        this.id_order = 0;
        this.terminal = 0;
        this.subtotal = 0;
        this.grand_total = 0;
        this.total_fix = 0;
        this.grand_total_final = 0;
        this.tax = 0;
        this.created_at = null;
        this.updated_at = null;
        this.items = [];
        this.payments = [];
        this.name='';
    }
    addPaymentMethod(method) {
        let payments = this.payments.slice();
        for (var i = 0; i < payments.length; i++) {
           // if (payments[i].code === method.code) return this;
        }
        payments.splice(-1, 0, method);
        this.payments = payments;
        return this;
    }
    getDeliveredAmount() {
        var amount = 0;

        if (typeof this.payments !== "undefined" && this.payments.length > 0) {
            this.payments.map((item, key) => {
                amount += Number(item.delivered);
                return item;
            });
        }

        return amount;
    }
    clearPayments(){

        this.payments=[];
    }
}

export default QuoteModel;