import React from 'react';
import {withTranslate} from 'react-admin';
import {connect} from 'react-redux';
import '../../assets/css/cart.css';
import '../../helper/Utils';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import storeConfig from "../../config/store";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

class Customer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {quote: props.quote};

        this.toggleWindow=this.toggleWindow.bind(this);
        this.removeCustomer=this.removeCustomer.bind(this);
    }
    removeCustomer(){
        this.props.dispatch({
            type: "REMOVE_CUSTOMER_TO_QUOTE",
            payload: null

        })
        storeConfig.history.push('/catalog');
    }
    toggleWindow(status){
        storeConfig.history.push('/customerlist');
    }
    render() {
        const {translate} = this.props;

        return (
            <div>
                <Card variant="outlined">
                    <CardContent>
                        <Container>
                            <Row>
                                <Col  xs={10}  onClick={() => {
                                    this.toggleWindow(true)
                                }}>
                        <AccountCircleIcon fontSize="large"/>
                        <span className="customer_name">
                {this.props.quote.id_customer > 0 ? (
                    this.props.quote.customer_name
                ) : (
                    translate('app.customer.guest')
                )
                }
                </span>
                                </Col>
                                <Col xs={2}>
                                    {this.props.quote.id_customer > 0 && (
                        <Button className="float-right" variant="default" size="xs"  onClick={() => {
                            this.removeCustomer()
                        }}><HighlightOffIcon /></Button>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    </CardContent>
                </Card>

            </div>

        );
    }

}

const mapStateToProps = (state) => {

    return {
        quote: state.cart
    }
};

export default connect(mapStateToProps)(withTranslate(Customer));