import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import CatalogHelper from './helper/CatalogHelper.js';
import Button from 'react-bootstrap/Button';
import {showNotification, withTranslate, Loading} from "react-admin";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import WebPosUtils from "../../helper/Utils";
import {InputGroup, Row, Col} from 'react-bootstrap';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';


class Detail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {attributes: [], simples: [], loading: false, qty: 1, simple_selected: null};
        this.handleChange = this.handleChange.bind(this);
        this.isAvailable = this.isAvailable.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.onChangeQty = this.onChangeQty.bind(this);
        this.minusQty = this.minusQty.bind(this);
        this.plusQty = this.plusQty.bind(this);
        this.hasStock = this.hasStock.bind(this);
    }

    componentDidMount() {


        if (this.props.product.configurable_atributes.length > 0) {
            this.setState({loading: true});
            CatalogHelper.loadSimpleProducts(this.props.product).then((data) => {

                    var values = [];
                    var atts = [];

                    if (data && data.attributes && data.attributes.length > 0 && data.simple.length > 0) {
                        //this.setState({"attributes": data.attributes});

                        data.attributes.map((att, index) => {
                            //solo cargamos las opciones del primer atributo
                            if (index === 0) {
                                var options = [];

                                att.options && att.options.map((option, index2) => {

                                    if (this.isAvailable(data.simple, att.code, option.value)) {

                                        options.push(option);
                                    }
                                    return option;
                                });
                                att['enabled_values'] = options;


                            } else att['enabled_values'] = [];
                            att['value'] = "";
                            atts[index] = att;
                            return att;
                        });

                        this.setState({simples: data.simple, attributes: atts, values: values, loading: false});


                    } else {
                        this.setState({simples: data.simple, attributes: [], values: values, loading: false});
                    }
                }
            );
        }

    }

    isAvailable(collection, code, value) {

        var simple_exists = false;
        for (var i = 0; i < collection.length; i++) {
            for (var j = 0; j < collection[i].options.length; j++) {
                var option = collection[i].options[j];
                /*eslint-disable */

                if (option.code == code && option.value == value) {
                    simple_exists = true;
                    /*eslint-enable */
                    if (this.state.attributes) {
                        for (var k = 0; k < this.state.attributes.length; k++) {
                            var att = this.state.attributes[k];

                            if (att.value !== "" && typeof att.value !== "undefined") {

                                //recorro de nuevo los atributos del producto a ver si cumplen con las condiciones del select
                                for (var z = 0; z < collection[i].options.length; z++) {
                                    if (collection[i].options[z].code === att.code) {

                                        if (collection[i].options[z].value !== att.value) {
                                            simple_exists = false;
                                            break;
                                        }
                                    }

                                }
                                if (!simple_exists) break;
                            }
                        }
                    }

                    if (simple_exists) return true;
                }
            }
            ;
        }
        ;
        return simple_exists;

    }

    handleChange(event) {

        var start = false;
        var empty_attributes = false;
        var _new_values = [];
        var attributes = this.state.attributes;
        attributes.map((att, index) => {

            if (att.code === event.target.name) {

                start = true;
                attributes[index].value = event.target.value;
            } else {
                if (start) {
                    empty_attributes = true;
                    attributes[index].value = "";
                    var options = [];
                    att.options && att.options.map((option, index2) => {


                        if (this.isAvailable(this.state.simples, att.code, option.value)) {

                            options.push(option);
                        }
                        return option;
                    });

                    attributes[index]['enabled_values'] = options;
                }
            }
            return att;
        });
        var simple = null;

        if (!empty_attributes) {

            simple = this.getSimple(this.getSelectedValues());

        }

        var old_values = this.state.values;

        this.setState(
            {

                values: {
                    ...old_values,
                    ..._new_values
                },
                attributes: attributes,
                simple_selected: simple
            }
        );

    }
    ;

    getSimple(options) {

        var skip_product = false;
        for (var j = 0; j < this.state.simples.length; j++) {
            var p = this.state.simples[j];

            for (var i = 0; i < options.length; i++) {
                if (skip_product) {

                    break;
                }

                var option_buyed = options[i];

                for (var z = 0; z < p.options.length; z++) {
                    var option_product = p.options[z];

                    if (option_product.code === option_buyed.code) {
                        if (option_product.value !== option_buyed.value) {

                            skip_product = true;
                            break;
                        }
                    }
                }
            }
            if (!skip_product) return this.state.simples[j];
            else skip_product = false;
        }
    }

    getSelectedValues() {
        var options = [];
        this.state.attributes.map((att, index) => {
            var option_label = "";
            att.enabled_values.map((option, index2) => {
                /*eslint-disable */

                if (option.value == att.value) option_label = option.label;
                /*eslint-enable */
                return option
            });

            var option = {
                code: att.code,
                label: att.label,
                id: att.id,
                value: att.value,
                value_label: option_label
            };

            if (option.value !== "") options.push(option);

            return att;
        });
        return options;
    }

    addToCart() {
        var product = this.props.product;
        product['selected_values'] = [];
        var miss = false;
        var options_selected = this.getSelectedValues();
        if (this.state.attributes.length !== options_selected.length) miss = true;
        product.selected_values = options_selected;

        var simple = this.getSimple(product.selected_values);

        if (miss || typeof simple === "undefined") {
            return;
        }
        product['child_id'] = simple['id'];
        product['stock'] = simple['stock']['qty'];
        product['price'] = simple['price'];
        product['price_without_tax'] = simple['price_without_tax'];
        product['tax'] = simple['tax'];
        product['tax_percent'] = simple['tax_percent'];
        var payload = {product: product, qty: this.state.qty};
        if(!this.props.is_refund){

            this.props.dispatch({
                type: "ADD_TO_CART",
                payload: payload
            })

        }
        this.props.onAddConfigurable(payload);
    }

    onChangeQty(event) {

        this.setState({qty: event.target.value});
    }

    minusQty() {
        var qty = this.state.qty;
        if (qty > 1) {
            qty--;
            this.setState({qty: qty});
        }

    }

    plusQty() {
        var qty = this.state.qty;
        qty++;
        this.setState({qty: qty});

    }

    hasStock() {

        var simple = this.state.simple_selected;
        if (WebPosUtils.getSettings().backorders !== 1 && simple['stock']['qty'] < 1) {

            return false;
        }
        return true;
    }

    render() {
        const {translate} = this.props;
        if (this.state.loading) return (<Loading loadingPrimary="" loadingSecondary="app.core.loading1"/>);

        return (
            <div className="product_detail">
                {this.state.attributes.length === 0 ?
                    <Alert variant="danger">{translate('app.catalog.no_attributes')}</Alert>
                    :
                    <div>
                        {this.state.attributes.map((att, index) => (

                            <Form.Group controlId="{att.code}" key={att.code}>
                                <Form.Label>{att.label}</Form.Label>
                                <Select
                                    className="form-control"
                                    labelId=""
                                    id={att.code}
                                    name={att.code}
                                    value={att.value}
                                    onChange={this.handleChange}
                                >
                                    {att.enabled_values && att.enabled_values.map((option, index2) => (

                                        <MenuItem value={option.value} key={index2}>{option.label}</MenuItem>

                                    ))}
                                </Select>
                            </Form.Group>
                        ))}
                        {this.state.simple_selected && (
                            <div className="configurable_detail">

                                {this.hasStock() ? (
                                        <Row>

                                            <Col xs={4}>
                                                {!WebPosUtils.getSettings().hide_stock && (
                                                    <div>
                                <span className="stock">
                                    {translate('app.quote.stock')}: {this.state.simple_selected.stock.qty}
                                    </span><br/>
                                                        {this.state.simple_selected.extra_stocks && this.state.simple_selected.extra_stocks.length > 1 && (
                                                            <div className='extra_stocks'>
                                                                <span>{translate('app.catalog.extra_stock')}</span>
                                                                <ul>
                                                                    {this.state.simple_selected.extra_stocks.map(stock =>
                                                                        <li key={stock.source_code}>
                                                                            <span>{stock.source_code}: {stock.qty}</span>
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </Col>
                                            <Col xs={8}>
                                                <div className="product_qty">
                                                    <Form>

                                                        <InputGroup className="mb-3">
                                                                <Button variant="primary" size="md" onClick={() => {
                                                                    this.minusQty()
                                                                }}><RemoveIcon/>
                                                                </Button>


                                                            <Form.Control className="qty text-center" type="text"
                                                                          value={this.state.qty}
                                                                          id="qty"
                                                                          readOnly/>

                                                                <Button variant="primary" onClick={() => {
                                                                    this.plusQty()
                                                                }} size="md"><AddIcon></AddIcon>
                                                                </Button>



                                                        </InputGroup>


                                                    </Form>


                                                </div>
                                                <div className="text-right">
                                                    <hr/>
                                                    <span
                                                        className="final_price"> {translate('app.quote.price')}: {this.state.simple_selected && WebPosUtils.formatCurrency(this.state.simple_selected.price)}</span>
                                                    <hr/>
                                                    <div className="detail-actions actions" align="right">
                                                        <Button variant="success" onClick={() => {
                                                            this.addToCart()
                                                        }}>
                                                            <AddShoppingCartIcon/> {translate('app.quote.add_to_cart')}
                                                        </Button>
                                                    </div>
                                                </div>


                                            </Col>
                                        </Row>
                                    ) :
                                    <Alert variant="danger">{translate('app.catalog.backorder_error')}</Alert>
                                }

                            </div>

                        )}
                    </div>
                }

            </div>

        );
    }
}

const
    mapDispatchToProps = (dispatch) => {

        return {
            dispatch,
            ...bindActionCreators({showNotification}, dispatch)
        }
    };

export default connect(undefined, mapDispatchToProps)

(
    withTranslate(Detail)
)
;