import { put, takeLatest } from 'redux-saga/effects'
import {showNotification} from "react-admin";
import WebPosUtils from "../helper/Utils";



function* reloadNotifications(action) {

    try {
        const data = yield WebPosUtils.getNotifications().then((data) => {
            if (data.error > 0) {

                if (data.error === 401 || data.error === 403) {
                    showNotification("app.core.not_login", 'warning');
                    return [];
                } else {
                    showNotification("app.core.error_500", 'warning');
                    return [];
                }
            }else{
                return data.data;
            }
        });
        yield put({type: "UPDATE_NOTIFICATIONS", payload: data});

    } catch (error) {
        console.log( error)
    }
}

function* closeSidebarSaga() {
    yield takeLatest("UPDATE_RESERVATIONS", reloadNotifications)
}

export default closeSidebarSaga;