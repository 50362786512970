import React from 'react';
import {List, Datagrid, TextField, Filter, Loading, TextInput, showNotification} from 'react-admin';
import {connect} from 'react-redux';
import Quote from '../sales/Quote';
import {Button, Row, Col} from 'react-bootstrap';
import {withTranslate, withDataProvider} from 'react-admin';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import QuoteHelper from "../cart/helper/QuoteHelper";
import WebPosUtils from "../../helper/Utils";


const OrderFilter = (props) => (
    <Filter {...props}>

        <TextInput label="app.quote.name" source="name" alwaysOn />
    </Filter>
);

class Hold extends React.Component {

    constructor(props) {
        super(props);

        this.state = {record: null,loading: false};
        this.selectHold = this.selectHold.bind(this);
        this.delete = this.delete.bind(this);
        this.continue = this.continue.bind(this);
        this.mounted=true;
    }
    componentWillUnmount(){
        this.mounted=false;
    }
    selectHold(id, basePatch, record) {

        this.setState({record: record});
    }

    delete() {
        this.setState({loading:true});
        QuoteHelper.removeHold({
            id: this.state.record.id
        }).then((data) => {
            if(!this.mounted) return;
            this.setState({loading: false});
            if (data.error !== 0) {

                if (data.msg) {
                    showNotification(data.msg, 'warning');
                } else {
                    showNotification("app.core.error_500", 'warning');
                }
            }else{
                this.setState({record: null});
            }

        });


    }
    continue() {


        this.props.dispatch({
            type: "ADD_FROM_HOLD",
            payload: this.state.record
        });
        this.delete();

        this.props.history.push('/');
    }
    render() {
        const {translate} = this.props;
        if (this.state.loading) return (<Loading loadingPrimary="" loadingSecondary="app.core.loading1"/>)

        return (
            <div className="container-fluid">
            <Row>
                <Col md={12} lg={6}>
                    <List basePath='hold' resource='hold' exporter={false} hasCreate={false} hasEdit={false}
                          hasList={false} hasShow={false} bulkActionButtons={false} title="app.resource.hold" filters={<OrderFilter/>}  filter={{store_id: WebPosUtils.getCurrentStore().id}}>
                        <Datagrid rowClick={this.selectHold}>
                            <TextField source="id"  headerClassName="d-sm-table-cell d-none" cellClassName="d-sm-table-cell d-none" />
                            <TextField source="name" label="app.quote.name"/>
                            <TextField source="subtotal" label="app.quote.subtotal"/>
                            <TextField source="grand_total" label="app.quote.grand_total"/>
                            <TextField source="created_at" label="app.quote.created_at"/>
                        </Datagrid>
                    </List>
                </Col>
                <Col md={12} lg={6}>
                    {this.state.record &&

                        <Quote record={this.state.record}>
                            <div className="actions">
                                <Button size="lg" variant="danger" onClick={() => {
                                    this.delete()
                                }}
                                ><RemoveShoppingCartIcon /> {translate('app.quote.delete')}
                                </Button>
                                <Button size="lg" variant="success"  onClick={() => {
                                    this.continue()
                                }}><ShoppingCartIcon/>
                                    {translate('app.quote.continue')}
                                </Button>

                            </div>
                        </Quote>



                    }

                </Col>
            </Row>
            </div>
        );
    }
}
const mapStateToProps = (state) => {

    return {
        carts: state.carts
    }
};

export default connect(mapStateToProps)(withDataProvider(withTranslate(Hold)));