class CustomerOptions{
    constructor() {
        this.backorders=0;
        this.settings = [];
        this.payments=[];
        this.source_stock=null;
        this.stores=[];
        this.websites=[];
        this.initiate();
    }
    initiate(){
        try {
            var options = JSON.parse(localStorage.getItem('customer_options'));
        }catch(e){
            console.log(e);
            return;
        }
        Object.assign(this, options);

    }
    getPermission(perm){
        if(typeof this.settings[perm] === "undefined") return false;
        return this.settings[perm];
    }
    getStores(){
        if(typeof this.stores === "undefined") return [];
        return this.stores;
    }
    getSettings(){
        return this.settings;
    }
    getSourceStock(){
        if(typeof this.source_stock === "undefined") return "default";
        return this.source_stock;
    }
    getWebsites(){
        if(typeof this.websites === "undefined") return [];
        return this.websites;
    }
    getPayments(){
        if(typeof this.payments === "undefined") return [];
        return this.payments;
    }

}
export default CustomerOptions;