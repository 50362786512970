import simpleRestProvider from "ra-data-simple-rest";
import {fetchUtils} from "react-admin";

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded'});
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);

};

const restdataProvider = simpleRestProvider (process.env.REACT_APP_API_URL, httpClient);
export default restdataProvider;