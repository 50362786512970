
export default (previousState = {}, {type, payload}) => {

    let newItems = [];
    let carts;
    let index;
    switch (type) {
        case 'ADD_FROM_HOLD_LEGACY':
            carts = previousState.carts.slice();

            index = carts.findIndex(x => x.id === payload.id);
            if (index >= 0) carts.splice(index, 1);
            return {carts: carts};

        case 'ADD_TO_HOLD_LEGACY':

            if (payload.id === 0) return previousState;


            if (previousState.carts) newItems = previousState.carts.slice();

            newItems.splice(-1, 0, payload);

            return {carts: newItems};

        case 'REMOVE_FROM_HOLD_LEGACY':

            carts = previousState.carts.slice();
            const id = payload;
            index = carts.findIndex(x => x.id === id);
            if (index >= 0) carts.splice(index, 1);
            return {carts: carts};

        case 'REMOVE_MASS_FROM_HOLD_LEGACY':
            carts = previousState.carts.slice();
            const ids = payload;
            ids.forEach(id => {
                index = carts.findIndex(x => x.id === id);
                if (index >= 0) carts.splice(index, 1);
            });
            return {carts: carts};


        default:
            return previousState;
    }

}