import QuoteHelper from "../pages/cart/helper/QuoteHelper";

class ProductListModel{

    constructor() {
        this.items = [];
    }
    addUnique(product, qty) {

        var found = false;
        if(this.items==null) this.items=[];
        let newItems = this.items.slice();
        newItems.map((item, key) => {

            if (QuoteHelper.isSameProduct(item, product)) {
                item.qty += qty;
                newItems[key] = item;
                found = true;
            }
            return null;
        });

        this.items = newItems;
        if (!found) {
            var uniqid = require('uniqid');
            let newItems = this.items.slice();
            newItems.splice(-1, 0, {
                ...product,
                qty: qty,
                item_id: uniqid()
            });
            this.items = newItems;

        }

        return this.items;
    }
    remove(item){
        if(this.items==null) return;

        for(var i=0; i<this.items.length; i++){
            if(this.items[i].item_id === item.item_id){
                this.items.splice(i,1);
                return;
            }
        }
    }
    updateQty(item, qty){
        if(this.items==null) return;

        for(var i=0; i<this.items.length; i++){
            if(this.items[i].item_id === item.item_id){
                this.items[i].qty += qty;
                if(this.items[i].qty < 0)  this.items.splice(i,1);
                return;
            }
        }
    }
}
export default ProductListModel;