// in src/App.js

import React, {useEffect} from 'react';
import {Admin, Resource} from 'react-admin';
import authProvider from './config/authProvider';
import i18nProvider from "./config/languageProvider";
import dataProvider from "./config/dataprovider";
import customRoutes from './config/customRoutes';
import {
    LabelTwoTone as LabelTwoToneIcon,
    FlightLand as FlightLandIcon,
    LocalShipping as LocalShippingIcon,
    Receipt as ReceiptIcon,
    HourglassEmpty as HourglassEmptyIcon,
    ShoppingCart as ShoppingCartIcon,
    LocalAtm as LocalAtmIcon
} from '@material-ui/icons';
import Dashboard from './pages/catalog/Dashboard';
import LoginPage from './pages/session/LoginPage';
import {Provider} from 'react-redux';

import {PersistGate} from 'redux-persist/integration/react';
import MyLayout from './config/layout';
import storeConfig from './config/store';


import Hold from './pages/hold/Hold';
import Hold_legacy from './pages/hold/Hold_legacy';
import Order from './pages/sales/Order';
import Coupon from './pages/coupon/Coupon';
import Funds from './pages/funds/Funds';
import Reservations from './pages/reservations/Reservations';
import Trackings from './pages/tracking/Trackings';
import WebPosUtils from "./helper/Utils";
import TrackForm from "./pages/tracking/TrackForm";
import {useThemeState} from './config/useThemeState.js';
import CustomerCreateForm from './pages/customer/CustomerCreateForm';

/*
const theme2 = createMuiTheme({
    palette: {
        secondary: {
            main: '#2196f3',
        },
        danger:{
            main: '#CC0000',
        },
        type: 'light', // Switching the dark mode on is a single property value change.
    },
});
console.log(theme2);*/


const App = () => {
    const {theme, setTheme} = useThemeState();

    var menu = [<Resource name="catalog" list={Dashboard} icon={ShoppingCartIcon}
                          options={{label: i18nProvider.translate('app.resource.catalog')}} title=""/>,
    ];
    if (WebPosUtils.getCustomerOptions() !== null) {
        if (WebPosUtils.getPermission("hold_legacy")) {
            menu.push(<Resource name="hold_legacy" list={Hold_legacy} icon={HourglassEmptyIcon}
                                options={{label: i18nProvider.translate('app.resource.hold')}}/>,);
        } else {
            menu.push(<Resource name="hold" list={Hold} icon={HourglassEmptyIcon}
                                options={{label: i18nProvider.translate('app.resource.hold')}}/>,);
        }
    }

    menu.push(<Resource name="orders" list={Order} icon={ReceiptIcon}
                        options={{label: i18nProvider.translate('app.resource.orders')}}/>,);
    menu.push(<Resource name="customers" icon={LocalAtmIcon} create={CustomerCreateForm}/>,);

    if (WebPosUtils.getCustomerOptions() !== null) {
        if (WebPosUtils.getPermission("reservations")) {
            menu.push(<Resource name="reservations" list={Reservations} icon={LocalShippingIcon}
                                options={{label: i18nProvider.translate('app.resource.reservations')}}/>,);
        }
        if (WebPosUtils.getPermission("tracking") && false) {
            menu.push(<Resource name="trackings" list={Trackings} icon={FlightLandIcon}
                                options={{label: i18nProvider.translate('app.resource.tracking')}} create={TrackForm}
                                edit={TrackForm}/>);
        }
        if (WebPosUtils.getPermission("funds")) {
            menu.push(<Resource name="funds" list={Funds} icon={LocalAtmIcon}
                                options={{label: i18nProvider.translate('app.resource.funds')}}/>);
        }
        if (WebPosUtils.getPermission("create_coupon")) {
            menu.push(<Resource name="coupons" list={Coupon} icon={LabelTwoToneIcon}
                                options={{label: i18nProvider.translate('app.resource.coupons')}}/>);
        }


    }

    const customer = WebPosUtils.getAppInfo();
    if (customer != null) {
        const favicon = document.getElementById("favicon");
        if (favicon !== null && customer.getData("image") !== null) {
            favicon.href = customer.image;
        }
    }
    useEffect(() => {
        if (WebPosUtils.getCustomerOptions() !== null) {
            const _theme = WebPosUtils.getTheme();
            setTheme(_theme);
        }
    }, [setTheme]);
    return (

        <Provider store={storeConfig.store.store}>
            <PersistGate
                persistor={storeConfig.store.persistor}
            >
                <Admin layout={MyLayout} history={storeConfig.history} loginPage={LoginPage} dataProvider={dataProvider}
                       authProvider={authProvider} title="Webpos"
                       dashboard={Dashboard}
                       i18nProvider={i18nProvider}
                       customRoutes={customRoutes}
                       theme={theme}
                >
                    {permissions => menu}

                </Admin>
            </PersistGate>
        </Provider>
    );
}

export default App;