import React, {useState, useMemo,useEffect} from 'react';
import {useLogin, useNotify, useTranslate, Loading, Notification} from 'react-admin';
import {ThemeProvider} from '@material-ui/styles';
import WebPosUtils from "../../helper/Utils";
import {makeStyles} from '@material-ui/core/styles';
import {Avatar, Card, CardActions, createMuiTheme, Button} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../assets/css/Login.css';
import {Field, Form} from 'react-final-form';
import TextField from '@material-ui/core/TextField';
import { useCheckAuth } from 'ra-core';
import { useHistory } from 'react-router-dom';
import {useThemeState} from '../../config/useThemeState.js';




const useStyles = makeStyles(
    (theme) => ({
        form: {
            padding: '0 1em 1em 1em',
        },
        input: {
            marginTop: '1em',
        },
        button: {
            width: '100%',
        },
        icon: {
            marginRight: theme.spacing(1),
        },
    }),
    {name: 'RaLoginForm'}
);

const Input = ({
                   meta: {touched, error}, // eslint-disable-line react/prop-types
                   input: inputProps, // eslint-disable-line react/prop-types
                   ...props
               }) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);


//Esta función se encarga de obtener el customer actual, para devolver terminales y logo
const LoginPage = ({theme}) => {

    const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);
    var customerData = JSON.parse(localStorage.getItem('customer'));
    const {setTheme} = useThemeState();

    //const [appLoaded, setApploaded] = useState(customerData!=null);
    const [appLoaded, setApploaded] = useState(false);
    const [appLoading, setApploading] = useState(false);
    const translate = useTranslate();
    const [customer, setCustomer] = useState(customerData);
    const [terminal, setTerminal] = useState(0);

    const login = useLogin();
    const checkAuth = useCheckAuth();
    const history = useHistory();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const endCallback = () =>{

        const theme = WebPosUtils.getTheme();
        setTheme(theme);
        setApploaded(true);
        setApploading(false);
    }
    const submit = values => {
        setLoading(true);
        values.terminal=terminal;
        login(values)
            .then(() => {
                //setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                    'warning'
                );
            });
    };

    const classes = useStyles({});

    const validate = (values) => {

        const errors = {};

        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }

        return errors;
    };

    const getAppInfo = () => {


        WebPosUtils.clearSession();
        WebPosUtils.checkVersion();
        const axios = require('axios');
        let domain = WebPosUtils.getAlias();
        var params = new URLSearchParams();
        params.append('alias', domain);

        axios({
            url: process.env.REACT_APP_API_URL + '/getAppInfo/',
            method: 'post',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            data: params
        })
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    setApploading(false);
                    throw new Error(response.statusText);
                }
                return response;
            })
            .then(({data}) => {
                const customer = {
                    ...data

                };
                if(data.terminals.length > 0) setTerminal(data.terminals[0].id);
                localStorage.setItem('customer', JSON.stringify(customer));
                WebPosUtils.reloadAppInfo();
                setCustomer(customer);
                const favicon = document.getElementById("favicon");
                if(favicon!=null) {
                    favicon.href = customer.image;
                }
               endCallback();

            }).catch(function (error) {
            console.log(error);
            //setApploading(false);
            history.push('/blank');
        });

    };
    const onChangeTerminal =((e)=>{

        setTerminal(e.target.value);
    });

    useEffect(() => {
        checkAuth({}, false)
            .then(() => {
                // already authenticated, redirect to the home page
                history.push('/');
            })
            .catch(() => {
                // not authenticated, stay on the login page
            });
    }, [checkAuth, history]);


    if (!appLoaded) {
        if(!appLoading) {
            setApploading(true);

            getAppInfo();

        }
        return (
            <Loading loadingPrimary="app.page.loading"/>
        )
    } else {

        return (
            <div className="RaLogin-main-20">
                <div className="MuiPaper-root MuiCard-root RaLogin-card-21 MuiPaper-elevation1 MuiPaper-rounded">
                    <ThemeProvider theme={muiTheme}>
                        <div>
                            <Card className={classes.card}>
                                <div className="text-center logo-login">

                                    <div className="flex-center">
                                        <Avatar src={customer.image} className="login_avatar" />
                                    </div>
                                </div>
                                <Form
                                    onSubmit={submit}
                                    validate={validate}
                                    render={({handleSubmit}) => (
                                        <form onSubmit={handleSubmit} noValidate>
                                            <div className={classes.form}>
                                                <div className={classes.input}>
                                                    <Field

                                                        id="terminal"
                                                        name="terminal"
                                                        component="select"
                                                        label={translate('app.auth.terminal')}
                                                        disabled={loading}
                                                        className="form-control"
                                                        onChange={onChangeTerminal}
                                                    >
                                                        {customer.terminals && customer.terminals.map( t=>(
                                                            <option value={t.id} key={t.id}>{t.name}</option>
                                                            )

                                                        )}
                                                    </Field>
                                                </div>
                                                <div className={classes.input}>
                                                    <Field
                                                        autoFocus
                                                        id="username"
                                                        name="username"
                                                        component={Input}
                                                        label={translate('ra.auth.username')}
                                                        disabled={loading}
                                                    />
                                                </div>
                                                <div className={classes.input}>
                                                    <Field
                                                        id="password"
                                                        name="password"
                                                        component={Input}
                                                        label={translate('ra.auth.password')}
                                                        type="password"
                                                        disabled={loading}
                                                        autoComplete="current-password"
                                                    />
                                                </div>
                                            </div>
                                            <CardActions>
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    color="primary"
                                                    disabled={loading}
                                                    className={classes.button}
                                                >
                                                    {loading && (
                                                        <CircularProgress
                                                            className={classes.icon}
                                                            size={18}
                                                            thickness={2}
                                                        />
                                                    )}
                                                    {translate('ra.auth.sign_in')}
                                                </Button>
                                            </CardActions>
                                        </form>
                                    )}
                                />
                            </Card>
                            <Notification />
                        </div>
                    </ThemeProvider>
                </div>
            </div>

        );


    }
};

export default LoginPage;