import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    Filter,
    TextInput,
    Loading,
    withTranslate, refreshView, showNotification,EditButton
} from 'react-admin';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import MyShow from '../../common/MyShow';
import Track from './Track';



const TrackingFilter = (props) => (
    <Filter {...props}>
        <TextInput label="app.catalog.search" source="q" alwaysOn />
    </Filter>
);

class Trackings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {id: 0, record: null, loading: false};
        this.mounted=true;
        this.selectItem = this.selectItem.bind(this);
    }

    componentWillUnmount(){
        this.mounted=false;
    }
    selectItem(id, basePatch, record) {

        this.setState({id: id, record: record});
    }
    render() {


        if (this.state.loading) return (<Loading loadingPrimary="" loadingSecondary="app.core.loading1"/>)
        return (

                <div className="row">

                    <div className="col-md-6">
                        <List basePath='trackings' resource='trackings' exporter={false} hasCreate={true}  hasEdit={true}
                              hasList={false} hasShow={true} bulkActionButtons={false} title="app.resource.tracking" filters={<TrackingFilter />} sort={{ field: 'created_at', order: 'DESC' }}>
                            <Datagrid rowClick={this.selectItem}>
                                <DateField source="entry_date" label="app.tracking.entry_date" showTime/>
                                <TextField source="provider_name" label="app.tracking.provider" sortable={false}/>
                                <TextField source="ref" label="app.tracking.ref"/>
                                <TextField source="status_name" label="app.tracking.status" sortable={false}/>
                                <EditButton />
                            </Datagrid>
                        </List>
                    </div>
                    <div className="col-md-6">
                        {this.state.record && (
                            <div>

                                {this.state.id > 0 && (
                                    <MyShow resource='trackings' id={this.state.id} basePath='track' title=' '>
                                        <Track track="1">
                                        </Track>

                                    </MyShow>

                                )}

                            </div>
                        )}
                    </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        dispatch,
        ...bindActionCreators({showNotification, refreshView}, dispatch)
    }
};
export default connect(null, mapDispatchToProps)(withTranslate(Trackings));
