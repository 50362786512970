import React,{useContext} from 'react';
import WebPosUtils from "../helper/Utils";



const StateContext = React.createContext({
    theme: null,
    setTheme: () => {}
});

export const ThemeStateProvider = (props) => {

    const [theme, setTheme] = React.useState(WebPosUtils.getDefaultTheme());

    return (

        <StateContext.Provider value={{
        theme,
            setTheme
    }}>
    {props.children}
    </StateContext.Provider>
);
};

export const useThemeState = () => useContext(StateContext);