import React from 'react';
import { Layout } from 'react-admin';
import MyMenu from './menu';
import MyAppBar from './MyAppBar';

const MyLayout = props => <Layout
    {...props}
    menu={MyMenu}
    appBar={MyAppBar}
/>;

export default MyLayout;