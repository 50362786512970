import React, { createElement } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import {MenuItemLink, getResources, withTranslate} from 'react-admin';
import { withRouter } from 'react-router-dom';
import { useTranslate } from 'ra-core';

import SettingsIcon from '@material-ui/icons/Settings';

const Menu = ({ onMenuClick, logout }) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    const translate = useTranslate();
    /* <MenuItemLink
                to="/"
                key='catalog'
                primaryText={translate("app.page.dashboard")}
                leftIcon={<ShoppingCartIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />*/
    return (

        <div>


           {resources.map(resource => (

               resource.hasList && (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={resource.options && (resource.options.label || resource.name)}
                    leftIcon={createElement(resource.icon)}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />)
            ))}

            <MenuItemLink
                to="/settings"
                key='settings'
                primaryText={translate("app.core.settings")}
                leftIcon={<SettingsIcon/>}
                sidebarIsOpen={open}
            />


            {isXSmall && logout}
        </div>
    );
};

export default withRouter(withTranslate(Menu));