import * as React from "react";
import Card from 'react-bootstrap/Card';
import Autosuggest from 'react-autosuggest';
import {withTranslate, showNotification, refreshView, Loading} from "react-admin";
import WebPosUtils from "../../helper/Utils";
import EditTrack from "./EditTrack";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import '../../assets/css/tracking.css';
import '../../assets/css/suggest.css';
import TrackHelper from './helper/TrackHelper';
import AddIcon from '@material-ui/icons/Add';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

class TrackForm extends React.Component {

    constructor(props) {

        super(props);

        this.state = {suggestions: [], valueComplete: '', newRow:{}, id:0, record:{}, loading:false};

        this.search = this.search.bind(this);
        this.onChange = this.onChange.bind(this);
        this.loadSuggestions = this.loadSuggestions.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.onSuggestionSelected=this.onSuggestionSelected.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.lastRequestId = null;
        this.autocomplete = null;

    }
    componentDidMount(){
       this.setState({id:this.props.id, record:this.props.record});
    }

    search(search) {

        var params = {filter: {'sku': search}, pagination: {page: 1, perPage: 10}};
        WebPosUtils.getJSON(process.env.REACT_APP_API_URL + '/catalog/', params, "get").then(response => {

            var list = [];
            if (response.data.length) {
                list = response.data.map(item => {
                    return ({id: item.id, name: item.name})
                });

                this.setState({suggestions: list});

                console.log(this.state.suggestions);

                return response;
            } else {
                return response;
            }

        });

    }

    loadSuggestions(value) {
        // Cancel the previous request
        if (this.lastRequestId !== null) {
            clearTimeout(this.lastRequestId);
        }

        this.setState({
            isLoading: true
        });

        // Fake request
        this.lastRequestId = setTimeout(() => {
            this.search(value);

        }, 500);
    }

    onSuggestionsFetchRequested = ({value}) => {
        this.loadSuggestions(value);
    };
    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };
    onChange = (event, {newValue}) => {
        this.setState({
            valueComplete: newValue
        });
    };
    onSuggestionSelected(event, object){

        var row=this.state.newRow;
        row.product_id=object.suggestion.id;
        this.setState({
            newRow: row
        });
    }
    handleChange(event){

        var name=event.target.name;
        var value = event.target.value;
        var row=this.state.newRow;

        row[name] = value;
        this.setState({
            newRow: row,
        });
    }
    handleSubmit(event) {
        event.preventDefault();
       event.target.reset();
       const data = this.state.newRow;
       data.entry_id=this.state.id;

        this.setState({loading:true});
        TrackHelper.sendRowTrack(data).then((res) => {

            if (res.error > 0) {
                this.props.showNotification(res.msg, 'warning');


            }else {
                this.props.showNotification("app.core.changes_refresh", 'success');
              //  this.props.refreshView();
            }
            this.setState({loading:false});
        });

    }
    render() {

        const {translate} = this.props;
        var value = this.state.valueComplete;
        const onChange = this.onChange;
        const inputProps = {
            placeholder: translate('app.catalog.name'),
            value,
            onChange: onChange,
            className:'form-control'
        };
        if (this.state.loading) {
            return (
                <Card><Card.Body> <Loading loadingPrimary="" loadingSecondary="app.core.loading1"/></Card.Body></Card>);
        }
        return (
            <div>

                <div>
                    <EditTrack id={this.state.id} resource={this.props.resource} basePath={this.props.basePath}></EditTrack>
                    <hr/>
                    {this.state.id > 0 &&(
                        <div>
                    <Card className="">
                        <Card.Header>{translate('app.quote.items')}</Card.Header>
                        <div>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table cart">
                                    <TableBody>
                                        {this.props.record && this.props.record.items && this.props.record.items.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="left" className="name_td">{row.product_name}

                                                </TableCell>
                                                <TableCell align="right" className="qty_td">x{row.qty}
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Card>
                    <div className="tracking_form">

                        <Card> <Card.Header>{translate('app.tracking.add_row')}</Card.Header>
                            <Card.Body>
                                <Form  onSubmit={this.handleSubmit}>
                                    <Form.Row className="align-items-center" >
                                        <Col xs="auto">
                                            <Form.Label>{translate('app.catalog.name')}</Form.Label>
                                            <Autosuggest
                                                suggestions={this.state.suggestions}
                                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                getSuggestionValue={(item) => item.name}
                                                renderSuggestion={(suggestion) => (
                                                    <div>
                                                        {suggestion.name}
                                                    </div>
                                                )}
                                                inputProps={inputProps}
                                                onSuggestionSelected={this.onSuggestionSelected}
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <Form.Label>{translate('app.quote.qty')}</Form.Label>
                                            <Form.Control type="text" name="qty" placeholder={translate('app.quote.qty')}
                                            onChange={this.handleChange}
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <Form.Label>{translate('app.tracking.batch')}</Form.Label>
                                            <Form.Control type="text" name="batch" placeholder={translate('app.tracking.batch')} onChange={this.handleChange}/>
                                        </Col>
                                        <Col xs="auto">
                                            <Form.Label>{translate('app.tracking.shelflife')}</Form.Label>
                                            <Form.Control type="date" name="shelflife" placeholder={translate('app.tracking.shelflife')} onChange={this.handleChange}/>
                                        </Col>
                                        <Col xs="auto"><br/>
                                            <Button size="sm" variant="primary" type="submit">
                                                     <AddIcon></AddIcon>
                                            </Button>
                                        </Col>

                                    </Form.Row>

                                </Form>
                            </Card.Body></Card>
                    </div>
                        </div>
                        )}
                </div>
            </div>
        )
    }

}
const mapDispatchToProps = (dispatch) => {

    return {
        dispatch,
        ...bindActionCreators({showNotification, refreshView}, dispatch)
    }
};
export default connect(null, mapDispatchToProps)(withTranslate(TrackForm));
