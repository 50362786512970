import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import WebPosUtils from "../../helper/Utils";


import '../../assets/css/dashboard.css';
import Button from 'react-bootstrap/Button';
import {withTranslate,Loading} from "react-admin";

import InputGroup from 'react-bootstrap/InputGroup';

import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import '../../assets/css/keyboard.css';
import '../../assets/css/checkout.css';


import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import WebposPaymentIcon from './WebposPaymentIcon';


class Payments extends React.Component {

    constructor(props) {
        super(props);
        this.getTitle=this.getTitle.bind(this);
    }
    getTitle(){
        const {translate} = this.props;
        if(this.props.title!=null){
            return this.props.title;
        }else{
            return translate('app.quote.payments_methods');
        }
    }
    render(){

        const {translate} = this.props;

        return (
            <Card className="payment_methods">
                <Card.Header>{this.getTitle()}</Card.Header>
                <Card.Body>
                    {this.props.loading ? <Loading loadingPrimary=""  loadingSecondary="app.core.loading1"/> :
                        <div>
                            <Row>
                                {this.props.available_payment_methods && this.props.available_payment_methods.length > 0 && this.props.available_payment_methods.map((method) => (
                                    <Col lg={3} sm={6} xs={12} key={method.code}>
                                        <Card onClick={() => {
                                            this.props.selectPaymentMethod(method)
                                        }}>

                                            <Card.Body className="text-center">

                                                <WebposPaymentIcon method={method.code}/>
                                                <Card.Text>
                                                    {translate(method.label)}
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                            <hr/>
                            {this.props.selected_payments && this.props.selected_payments.length > 0 && (
                                <Card className="payment_methods_selected">
                                    <Card.Header> {translate('app.quote.selected_payments')}</Card.Header>
                                    <Card.Body>

                            {this.props.selected_payments && this.props.selected_payments.map((method, key) => (
                                <Row key={key}>

                                    <Col xxl={4} xl={4} sm={12}>
                                        <WebposPaymentIcon method={method.code}/>{translate(method.label)}
                                    </Col>
                                    <Col xxl={4} xl={3} sm={5}>
                                        <Form.Control type="text"
                                                                         name={method.code}
                                                                         placeholder={translate("app.quote.operation_number")}
                                                                         onChange={this.props.updatePaymentReference}
                                                                         value={method.reference}/>
                                    </Col>

                                    <Col xxl={3} xl={4} sm={5} xs={8}>
                                        <InputGroup className="mb-3">
                                            <FormControl
                                                name={method.code}
                                                value={method.delivered}
                                                placeholder={translate("app.quote.payment_delivered")}
                                                onChange={this.props.updatePaymentDelivery}
                                                type="number"
                                            />

                                                <InputGroup.Text
                                                    id="basic-addon2">{WebPosUtils.getCurrency()}</InputGroup.Text>

                                        </InputGroup>
                                    </Col>
                                    <Col xl={1} sm={2} xs={4} className="text-left"><Button variant="link" size="md"
                                                                             onClick={() => {
                                                                                 this.props.removePaymentMethod(method);
                                                                             }}><RemoveCircleIcon/></Button>
                                    </Col>
                                </Row>
                            ))}
                                    </Card.Body>
                                </Card>
                                        )}
                            {this.props.children}
                        </div>

                    }
                </Card.Body>
            </Card>
        )

    }
}
export default withTranslate(Payments);