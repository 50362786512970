import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MyUserMenu from './MyUserMenu';
import WebPosUtils from "../helper/Utils";
import Notifications from '../common/Notifications';
import Stores from '../common/Stores';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const MyAppBar = props => {
    const classes = useStyles();
    return (
        <AppBar {...props}  >
            <span className="shop_name">{WebPosUtils.getAppInfo() && WebPosUtils.getAppInfo().getData("name")!==null} | {WebPosUtils.getCurrentTerminal()} |&nbsp;</span>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
        <Notifications />
            <Stores />
        </AppBar>
    );
};
MyAppBar.defaultProps ={
    userMenu:<MyUserMenu />
}

export default MyAppBar;