class SettingsModel {
    constructor() {
        this.autoprint = {value: false, editable: true};
        this.visible_check = {value: false, editable: true};
        this.enable_notifications = {value: false, editable: true};
        this.show_sku = {value: false, editable: true};
        this.hide_stock = {value: false, editable: true};
        this.hide_codebar = {value: false, editable: true};
        this.hide_print_button = {value: false, editable: false};
        this.funds = {value: false, editable: false};
        this.reservations = {value: false, editable: false};
        this.returns = {value: false, editable: false};
        this.tracking = {value: false, editable: false};
        this.update_price = {value: false, editable: false};
        this.update_stock = {value: false, editable: false};
        this.hide_discount = {value: false, editable: false};
        this.hide_coupon = {value: false, editable: false};
        this.gift_ticket = {value: false, editable: false};
        this.changes = {value: false, editable: false};
        this.customer_create = {value: false, editable: false};
        this.free_product = {value: false, editable: false};
        this.create_coupon = {value: false, editable: false};
        this.booking = {value: false, editable: false};
    }

    initiate(params, server=false) {

        if (typeof params === "undefined") return;
        let valuesArray = Object.entries(this);
        for (let value of valuesArray) {
            var key = value[0];
            if(!server && !value[1].editable) continue;
            var newValue = params[key];
            if (typeof newValue !== "undefined") {

                this[key].value = newValue;
            }
        }


    }

    initiateServerValues(settings) {

        var values = {};
        if (settings === null || typeof settings === "undefined") {
            console.log("Opciones vacías");
            return;
        }
        let valuesArray = Object.entries(settings);
        var hasValue = 0;
        for (let value of valuesArray) {
            var key = value[0];
            var val = value[1];
            values[key] = val;
            hasValue++;

        }
        if (hasValue > 0) {
            this.initiate(values, true);
            return true;
        }
        return false;
    }

    getValues() {
        var values = {};
        let valuesArray = Object.entries(this);
        for (let value of valuesArray) {
            values[value[0]] = value[1].value;
        }
        return values;
    }
}

export default SettingsModel;