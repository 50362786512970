import React from 'react';
import {withTranslate} from 'react-admin';
import {connect} from 'react-redux';
import ReactDOM from 'react-dom';
import '../assets/css/order.css';

class Print extends React.Component {

    copyStyles(sourceDoc, targetDoc) {
        Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
            try {
                if (styleSheet.cssRules) {
                    const newStyleEl = sourceDoc.createElement('style');

                    Array.from(styleSheet.cssRules).forEach(cssRule => {
                        newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
                    });

                    targetDoc.head.appendChild(newStyleEl);
                } else {
                    const newLinkEl = sourceDoc.createElement('link');

                    newLinkEl.rel = 'stylesheet';
                    newLinkEl.href = styleSheet.href;
                    targetDoc.head.appendChild(newLinkEl);
                }
            } catch (e) {
                // console.log(e);
            }
        });
    }
    constructor(props) {
        super(props);
        // STEP 1: create a container <div>
        this.containerEl = document.createElement('div');
        this.externalWindow = null;

    }

    render() {

        // STEP 2: append props.children to the container <div> that isn't mounted anywhere yet
        return ReactDOM.createPortal(this.props.children, this.containerEl);
    }

    componentDidMount() {
        // STEP 3: open a new browser window and store a reference to it
        this.externalWindow = window.open('', '', 'width=400,height=400,left=200,top=200');
        if(this.externalWindow==null){
         alert("Para imprimir necesita permitir ventanas emergentes en el navegador");
        }else {
            // STEP 4: append the container <div> (that has props.children appended to it) to the body of the new window
            this.externalWindow.document.body.appendChild(this.containerEl);
            this.copyStyles(document, this.externalWindow.document);
            this.externalWindow.print();
            this.props.printAction();
        }
    }

    componentWillUnmount() {
        // STEP 5: This will fire when this.state.showWindowPortal in the parent component becomes false
        // So we tidy up by closing the window


    }
}

const mapStateToProps = (state) => {

    return {
        order: state.order
    }
};

export default connect(mapStateToProps)(withTranslate(Print));