import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import WebPosUtils from "../../helper/Utils";
import '../../assets/css/dashboard.css';
import {withTranslate} from "react-admin";
import Card from 'react-bootstrap/Card';
import '../../assets/css/keyboard.css';
import '../../assets/css/checkout.css';
import WebposPaymentIcon from './WebposPaymentIcon';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import {Row, Col, Button} from 'react-bootstrap';
import {Add as AddIcon} from '@material-ui/icons';

class PaymentList extends React.Component {
    constructor(props) {

        super(props);
        this.addNewPayment = this.addNewPayment.bind(this);
    }

    addNewPayment() {

        if(this.props.togglePaymentList!=null) this.props.togglePaymentList();
    }

    render() {

        const {translate} = this.props;

        return (
            <Card className="payment_methods">
                <Card.Header>{translate('app.quote.payments_methods')}</Card.Header>
                <Card.Body>
                    <div className="row payment_methods">
                        {this.props.record && this.props.record.payments.map((method, index) => (
                            <Col sm={6} xs={12} key={index}>
                                <Card>
                                    <Card.Body className="text-center">
                                        <WebposPaymentIcon method={method.code}/>
                                        <Card.Text>
                                            {method.name}
                                            {method.coupon && (
                                                <span className="coupon_code"> [{method.coupon}] </span>
                                            )}
                                            <strong> x {WebPosUtils.formatCurrency(method.delivered)}</strong>


                                        </Card.Text>

                                    </Card.Body>
                                </Card>
                            </Col>

                        ))}
                        {this.props.can_add && (
                            <Col sm={6} xs={12}>
                                <Card>
                                    <Card.Body className="text-center">
                                        <Button size="sm" onClick={() => {
                                            this.addNewPayment();
                                        }}><AddIcon/> {translate('app.quote.add_payment')}</Button>

                                    </Card.Body>
                                </Card>
                            </Col>

                        )
                        }
                        <Row>
                            {this.props.order === 1 && this.props.record.total_due > 0 && (
                                <Col sm={3} xs={12}>
                                    <Card className="alert alert-info">
                                        <Card.Body className="text-center" onClick={() => {
                                            this.props.toggleDetail(true);
                                        }}>

                                            <Card.Text>
                                                <ControlPointIcon></ControlPointIcon>

                                            </Card.Text>

                                        </Card.Body>
                                    </Card>

                                </Col>

                            )}
                        </Row>
                    </div>

                </Card.Body>
            </Card>
        )

    }
}

export default withTranslate(PaymentList);