import React from 'react';
import {withTranslate, Loading, showNotification, refreshView} from 'react-admin';
import {Col, Row, Form, Button, Popover, OverlayTrigger, Modal}from 'react-bootstrap';
import {Card, CardContent} from '@material-ui/core';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import "../../assets/css/catalog.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import WebPosUtils from "../../helper/Utils";
import Detail from './Detail';
import DefaultProductImage from '../../assets/img/DefaultProductImage.jpeg';
import {Info as InfoIcon, Edit as EditIcon} from '@material-ui/icons';
import CatalogHelper from "./helper/CatalogHelper";


class ProductList extends React.Component {

    initProps = {
        data: {},
        ids: [],
    }

    constructor(props) {
        super(props);

        //({ ids, data, basePath })
        this.addToCart = this.addToCart.bind(this);
        this.toggleDetail = this.toggleDetail.bind(this);
        this.state = {
            height: 'calc(100vh - 200px)',
            show: false,
            current_product: null,
            scanBarcode: false,
            codigo_barras: '',
            barcode_msg: {code: '', text: ''},
            showEditQty:false,
            stock:{product_id:0,sku:'',source:'default',qty:0},
            loadingStock:false,
            resize: null
        };
        this.myRef = React.createRef();

        this.submitStock = this.submitStock.bind(this);
        this.changeStock=this.changeStock.bind(this);
        this.getHeight=this.getHeight.bind(this);
        this.getColorProduct=this.getColorProduct.bind(this);
        this.renderStockQty=this.renderStockQty.bind(this);
        this.fixHeightList=this.fixHeightList.bind(this);
        this.onAddConfigurable=this.onAddConfigurable.bind(this);
        this.toggleUpdateStockWindow=this.toggleUpdateStockWindow.bind(this);

    }


    addToCart(product) {
        if (product.type === 'simple') {
            if( WebPosUtils.getSettings().backorders!== 1 &&  product.stock < 0.01){
                this.props.showNotification("app.catalog.backorder_error", 'warning');
                return;
            }

            this.props.dispatch({
                type: "ADD_TO_CART",
                payload: {product: product, qty: 1}
            })
        } else if (product.type === 'configurable') {
            this.setState({current_product: product});
            this.toggleDetail(true);
        } else {
            alert("Tipo de producto no disponible. Solo se admiten simples y configurables");
        }
    }

    onAddConfigurable(item){
        this.toggleDetail(false);
    }
    toggleDetail(status) {

        if (!status) {
            this.setState({current_product: null, show: status});
        } else {
            this.setState({show: status});
        }
    }

    componentDidMount() {
        this.fixHeightList();
        window.addEventListener('resize', this.fixHeightList)
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.fixHeightList);
    }

    fixHeightList(){
        var node = this.myRef.current;
        var rect = node.getBoundingClientRect();
        if(!WebPosUtils.isMobile()) {
            //le sumamos el paginador
            var top = rect.top + 60;
            this.setState({height: 'calc(100vh - ' + top + 'px)'});
        }else{
            this.setState({height: 0});
        }
    }

    changeStock(event){

        var current = this.state.stock;
        current.qty=event.target.value;
        this.setState({stock:current});

    }
    showUpdateStockWindow(id, sku, source, qty){

        this.setState({stock:{product_id:id, sku:sku, source:source,qty:qty}});
        this.toggleUpdateStockWindow(true);
    }
    toggleUpdateStockWindow(status){
        this.setState({showEditQty: status});
    }
    submitStock(event){
        event.preventDefault();
        this.setState({loadingStock:true});
        CatalogHelper.updateStock(this.state.stock).then((data) => {

            if (data.error > 0) {

                    this.props.showNotification("app.core.error_500", 'warning');
                    this.props.history.push("/");

            }else {
                this.props.showNotification("app.core.changes_refresh", 'success');
                this.props.refreshView();


                }
        });

    }
    getHeight(){

        return {height: (this.state.height!==0)?this.state.height:'auto'};
    }
    getColorProduct(product){
        if(product.type==='configurable' || product.stock > 0) return "product";
        else return "product nostock";
    }
    renderStockQty(product){
        if(product.type==='configurable') return this.props.translate('app.catalog.configurable_product');
        else{
            if(!WebPosUtils.getSettings().hide_stock) return this.props.translate('app.catalog.stock')+':'+ product.stock;
            else return "";
        }
    }
    render() {

        const {translate} = this.props;

        return (
            <div ref={this.myRef} className="catalog-list" id="catalog-list" style={this.getHeight()}>

                {this.props.loading ? (
                    <Loading loadingPrimary="" loadingSecondary="app.core.loading1"/>
                ) : (

                    <Row>

                        {this.props.ids && this.props.ids.map(id =>
                            <Col xs={6} xl={3} lg={4} sm={6} key={id}>
                                <Card key={this.props.data[id].id}
                                      className={this.getColorProduct(this.props.data[id])}>
                                    {this.props.data[id].extra_stocks && this.props.data[id].extra_stocks.length > 0 && (
                                        <div className="product_info">
                                            <OverlayTrigger trigger="click" rootClose={true} placement="bottom"
                                                            overlay={
                                                                <Popover id="popover-basic">
                                                                    <Popover.Header
                                                                        as="h3">{translate("app.quote.extra_stocks")}</Popover.Header>
                                                                    <Popover.Body>
                                                                        <ul className="extra_stocks">
                                                                            {this.props.data[id].extra_stocks.map(stock =>
                                                                                <li key={stock.source_code}>
                                                                                    <span><strong>{stock.source_code}</strong>: {stock.qty}</span>
                                                                                    {typeof WebPosUtils.getPermission("update_stock") && stock.source_code === WebPosUtils.getCustomerOptions().getSourceStock() && this.props.data[id].type==='simple' && (
                                                                                       <Button size="sm" className="editStock"><EditIcon  style={{ fontSize: 15 }} onClick={() => this.showUpdateStockWindow(id, this.props.data[id].sku, stock.source_code, stock.qty)}/></Button>
                                                                                    )}
                                                                                </li>
                                                                            )}
                                                                        </ul>
                                                                    </Popover.Body>
                                                                </Popover>
                                                            }>
                                                <Button variant="light" size="sm"><InfoIcon/></Button>
                                            </OverlayTrigger>

                                        </div>
                                    )}
                                    <CardContent className="touchable" onClick={() => {
                                        this.addToCart(this.props.data[id])
                                    }}>

                                        <div className="product_image">
                                            {this.props.data[id].image ? (
                                                    <img alt={this.props.data[id].id}

                                                         src={this.props.data[id].image} className="img-fluid"
                                                    />
                                                ) :
                                                <img alt={this.props.data[id].id}

                                                     src={DefaultProductImage} className="img-fluid"
                                                />
                                            }
                                        </div>
                                        <div className="product_sku">{this.props.data[id].sku}</div>
                                        <div className="product_name">{this.props.data[id].name}</div>

                                        <Row>
                                            <Col className="product_stock" sm={6} xs={12}>
                                                {this.renderStockQty(this.props.data[id])}

                                            </Col>
                                            <Col className="product_price" sm={6} xs={12}>
                                                {WebPosUtils.formatCurrency(this.props.data[id].price)}
                                            </Col>
                                        </Row>
                                    </CardContent>

                                </Card>
                            </Col>
                        )}
                    </Row>
                )}

                <Modal show={this.state.show} onHide={() => {
                    this.toggleDetail(false)
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.current_product && this.state.current_product.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Detail product={this.state.current_product} onAddConfigurable={this.onAddConfigurable}/>
                    </Modal.Body>
                    <Modal.Footer>


                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showEditQty} onHide={() => {
                    this.toggleUpdateStockWindow(false)
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>{translate('app.catalog.update_stock')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.submitStock}>
                            <Row>
                                <Col>
                            <Form.Control type="number" data-id={this.state.stock.product_id} data-source={this.state.stock.source} onChange={this.changeStock} value={this.state.stock.qty} />
                                </Col>
                                <Col>
                                    {this.state.loadingStock ?( <Loading loadingPrimary="" loadingSecondary="app.core.loading1"/>):(
                                    <Button size="sm" variant="primary" type="submit">
                                        {translate('app.catalog.stock_save')}
                                    </Button>
                                        )}
                                </Col>
                            </Row>
                        </Form>

                    </Modal.Body>
                    <Modal.Footer>


                    </Modal.Footer>
                </Modal>


            </div>

        );
    }
}
const mapDispatchToProps = (dispatch) => {

    return {
        dispatch,
        ...bindActionCreators({showNotification, refreshView}, dispatch)
    }
};

export default connect(null, mapDispatchToProps)(withTranslate(ProductList));