import {
    put,
    takeEvery,
} from 'redux-saga/effects'


function* deleteHold(action) {

    try {

            yield put({type: "RA/REFRESH_VIEW", payload: {data:[{id: action.payload}]}});

    } catch (error) {
        console.log(error);
    }
}

function* deleteHoldSaga() {
    yield takeEvery(["REMOVE_FROM_HOLD","REMOVE_MASS_FROM_HOLD"], deleteHold)
}

export default deleteHoldSaga;