import React from 'react';
import {
    withTranslate,
    List,
    Datagrid,
    TextField,
    Filter,
    TextInput

} from 'react-admin';
import {connect} from 'react-redux';
import '../../assets/css/cart.css';
import '../../helper/Utils';
import storeConfig from "../../config/store";
import WebPosUtils from "../../helper/Utils";



const CustomerFilter = (props) => (
    <Filter {...props}>
        <TextInput label="app.catalog.search" source="q" alwaysOn/>
    </Filter>
);
const postRowStyle = (record, index) => ({
    backgroundColor: typeof record.addresses === "undefined" ? 'rgb(249 76 96 / 43%)' : 'white',
});
class CustomerList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {quote: props.quote, show: false, no_address:false};
        this.selectItem = this.selectItem.bind(this);
        this.toggleWindow = this.toggleWindow.bind(this);
    }

    selectItem(id, basePatch, record) {

        if (id > 0) {
            if (typeof record.addresses === "undefined") {
               alert(this.props.translate('app.customer.no_address'));
                return;
            }
            this.setState({no_address: false});
            this.props.dispatch({
                type: "ADD_CUSTOMER_TO_QUOTE",
                payload: record
            })
        }
        this.toggleWindow();
    }

    toggleWindow() {
        storeConfig.history.push('/catalog');
    }


    render() {

        return (
            <div>


                <List basePath='customers' resource='customers' exporter={false} hasCreate={WebPosUtils.getSettings().customer_create}
                      hasEdit={false}
                      hasList={false} hasShow={false} bulkActionButtons={false}
                      title="app.resource.customers" sort={{field: 'created_at', order: 'DESC'}}
                      filters={<CustomerFilter/>}
                      filter={{website_id: WebPosUtils.getCurrentStore().website_id}}
                >
                    <Datagrid rowClick={this.selectItem} rowStyle={postRowStyle}>
                        <TextField source="firstname" label="app.customer.firstname"/>
                        <TextField source="lastname" label="app.customer.lastname" sortable={false}/>
                        <TextField source="email" label="app.customer.email" sortable={false}/>
                    </Datagrid>
                </List>
            </div>


        );
    }

}

const mapStateToProps = (state) => {

    return {
        quote: state.cart
    }
};

export default connect(mapStateToProps)(withTranslate(CustomerList));