import React from 'react';
import {withTranslate, Loading} from 'react-admin';
import Card from 'react-bootstrap/Card';
import WebPosUtils from "../../helper/Utils";

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';


class Track extends React.Component {

    render(){
        const {translate} = this.props;

        if(this.props.track === "1"){
            if(!this.props.record || !this.props.record.items){
                return (<Card><Card.Body>   <Loading loadingPrimary=""  loadingSecondary="app.core.loading1"/></Card.Body></Card>);
            }
        }
        return(
            <div>
            <Card className="tracking_header">
                <Card.Header>{translate('app.tracking.track_header')} #{this.props.record.ref}
                </Card.Header>
                <Card.Body>
                    <strong>{translate('app.tracking.ref')}</strong>: {this.props.record.ref}<br/>
                    <strong>{translate('app.tracking.entry_date')}</strong>: {WebPosUtils.formatDateFromMysql(this.props.record.entry_date)}<br/>
                    <strong>{translate('app.tracking.provider')}</strong>: {this.props.record.provider_name}<br/>
                    <strong>{translate('app.tracking.status')}</strong>: {this.props.record.status_name}<br/>

                </Card.Body>

            </Card>
            <Card className="">
            <Card.Header>{translate('app.quote.items')}</Card.Header>
        <div>
            <TableContainer component={Paper}>
                <Table aria-label="simple table cart">
                    <TableBody>
                        {this.props.record && this.props.record.items && this.props.record.items.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell align="left" className="sku_td">{row.sku}</TableCell>
                                <TableCell align="left" className="name_td">{row.name}
                                    {row.batch &&(
                                        <div>{translate('app.tracking.batch')}: {row.batch}</div>
                                    )}
                                </TableCell>
                                <TableCell align="right" className="qty_td">{row.qty}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </div>
        </Card>
            </div>
        );
    }
}
export default withTranslate(Track);