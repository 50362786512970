import React, { Fragment } from 'react';

import {List, Datagrid, TextField,  DateField,  Loading,  BooleanField } from 'react-admin';
import {withTranslate} from 'react-admin';
import 'bootstrap/dist/css/bootstrap.min.css';
import  AcceptReservationButton  from './AcceptButton';


const PostBulkActionButtons = props => (
    <Fragment>
        <AcceptReservationButton label="app.reservations.reserve_stock" {...props} />
        {/* default bulk delete action */}

    </Fragment>
);


class Reservations extends React.Component {

    constructor(props) {
        super(props);
        this.state = {loading: false};

    }
    postRowStyle(record, index) {
        var bg='white';
      if(record.shipped) bg='#f8d7da';
      else if(!record.accepted) bg='#fff3cd';
        return {
            backgroundColor: bg,
        }
    }

    render() {


        if (this.state.loading) return (<Loading loadingPrimary="" loadingSecondary="app.core.loading1"/>)
        return (
            <div>


                        <List basePath='reservations' resource='reservations' exporter={false} hasCreate={false} hasEdit={false}
                              hasList={false} hasShow={false} bulkActionButtons={<PostBulkActionButtons />} title="app.resource.reservations"
                             >
                            <Datagrid rowClick={this.selectItem} isRowSelectable={record=>!record.accepted} rowStyle={this.postRowStyle}>
                                <DateField source="created_at" label="app.quote.created_at" showTime sortable={true}/>
                                <TextField source="increment_id" label="app.quote.online_order" sortable={false}/>
                                <TextField source="sku" label="app.catalog.sku" sortable={false}/>
                                <TextField source="qty" label="app.quote.qty" sortable={false}/>
                                <BooleanField  source="accepted" label="app.reservations.accepted" sortable={false}/>
                                <DateField source="accepted_at" label="app.reservations.accepted_at" showTime sortable={false}  headerClassName="d-xl-table-cell d-none" cellClassName="d-xl-table-cell d-none" />
                                <TextField source="accepted_user" label="app.reservations.accepted_user" sortable={false}  headerClassName="d-xl-table-cell d-none" cellClassName="d-xl-table-cell d-none" />
                                <BooleanField  source="shipped" label="app.reservations.shipped" sortable={false}/>


                            </Datagrid>
                        </List>

            </div>
        );
    }
}


export default (withTranslate(Reservations));