import dataProvider from "./dataprovider";
import createAdminStore from "./storeProvider";
import authProvider from "./authProvider";
import { createHashHistory } from 'history';

const history = createHashHistory();

export default {
    history:history,
    store: createAdminStore({
                         authProvider,
                         dataProvider,
                         history,
                     })

};