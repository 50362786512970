import React from 'react';
import {withTranslate, Title, showNotification, Loading, Authenticated, withDataProvider} from "react-admin";
import 'bootstrap/dist/css/bootstrap.min.css';
import WebPosUtils from "../../helper/Utils";
import Minicart from '../cart/Minicart';
import Customer from '../cart/Customer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import '../../assets/css/dashboard.css';
import {Button, Row, Col, FormControl, InputGroup} from 'react-bootstrap';
import {Table, TableBody, TableCell, TableContainer, TableRow, Paper, Popover, Checkbox, FormControlLabel} from '@material-ui/core';
import {AssignmentTurnedIn as AssignmentTurnedInIcon, LabelTwoTone as LabelTwoToneIcon, LabelOffTwoTone as LabelOffTwoToneIcon, ShoppingCart as ShoppingCartIcon, Done as DoneIcon} from '@material-ui/icons';

import Quote from '../sales/Quote';
import '../../assets/css/keyboard.css';
import '../../assets/css/checkout.css';
import QuoteHelper from "../cart/helper/QuoteHelper";
import QuoteModel from "../cart/model/QuoteModel";
import Payments from '../sales/Payments';
import Calculator from './Calculator';
import QuoteAddresses from './QuoteAddresses';


class Checkout extends React.Component {

    constructor(props) {

        super(props);

        const layout = {
            'default':
                ["1 2 3", "4 5 6", "7 8 9", "{%} 0 .", "{bksp}"],
        };
        const layout_price = {
            'default':
                ["1 2 3", "4 5 6", "7 8 9", "0 . {bksp}"],
        };
        const display = {
            '{bksp}': '<<'
        };
        const {translate} = this.props;

        this.state = {
            last_error: false,
            quote: QuoteModel,
            show: false,
            layout: layout,
            layout_price: layout_price,
            display: display,
            value: '',
            discount_type: WebPosUtils.getCurrency(),
            discount_name: translate('app.quote.default_discount_name'),
            available_payment_methods: [],
            loading: false,
            order_id: null,
            mounted: false,
            show_custom_price: false,
            current_row: null,
            coupon_modal: false,
            coupon_anchor: null,
            current_coupon: ''

        };

        this.addDiscount = this.addDiscount.bind(this);
        this.changeLabelDiscount = this.changeLabelDiscount.bind(this);
        this.selectPaymentMethod = this.selectPaymentMethod.bind(this);
        this.removePaymentMethod = this.removePaymentMethod.bind(this);
        this.updatePaymentReference = this.updatePaymentReference.bind(this);
        this.updatePaymentDelivery = this.updatePaymentDelivery.bind(this);
        this.calculeExchange = this.calculeExchange.bind(this);
        this.resetDiscount = this.resetDiscount.bind(this);
        this.updatePrice = this.updatePrice.bind(this);
        this.customizePrice = this.customizePrice.bind(this);
        this.toggleCustomPrice = this.toggleCustomPrice.bind(this);
        this.toggleCoupon = this.toggleCoupon.bind(this);
        this.closeCoupon = this.closeCoupon.bind(this);
        this.changeCoupon = this.changeCoupon.bind(this);
        this.submitCoupon = this.submitCoupon.bind(this);
        this.removeCoupon = this.removeCoupon.bind(this);
        this.focusCoupon = this.focusCoupon.bind(this);
        this.print = this.print.bind(this);
        this.printOrder = this.printOrder.bind(this);
        this.printGiftTicketAction = this.printGiftTicketAction.bind(this);
        this.toggleDetail = this.toggleDetail.bind(this);
        this.couponInput = React.createRef();
        this.showBooking = this.showBooking.bind(this);
        this.changeBooking = this.changeBooking.bind(this);

    }

    calculeExchange() {

        return QuoteHelper.calculeExchange(this.props.quote);
    }

    createQuote(quote = null) {

        this.setState({loading: true, mounted: true});
        if (!quote) quote = this.props.quote;
        QuoteHelper.sendQuote(quote).then((data) => {
            if (this.state.mounted) {

                if (data.error > 0) {

                    if (data.error === 401 || data.error === 403) {
                        this.props.showNotification("app.core.not_login", 'warning');
                        this.props.history.push("/login");
                        return;
                    } else {


                        this.props.history.push("/catalog");
                        console.log(data);
                        this.props.showNotification("app.core.error_500", 'warning');
                    }
                }
                else {

                    //this.getOrderData(data.id);


                    this.props.dispatch({
                        type: "QUOTE_CREATED",
                        payload: data
                    });

                    if (typeof data.no_stock !== "undefined" && data.no_stock.length > 0) {
                        this.props.showNotification("app.quote.nostock", 'warning');
                    }
                    if (this.state.last_error) {
                        this.setState({last_error: false});
                        // this.finalizePurchase();
                    }

                    if (data.quote.payment_methods.length === 1) {
                        this.selectPaymentMethod(data.quote.payment_methods[0]);
                    }

                    this.setState({loading: false});
                }
            }
        })

    }

    componentDidMount() {

        this.createQuote();
        this._mounted = true;

    }

    componentWillUnmount() {
        this.setState({mounted: false});
        this._mounted = false;
    }

    addDiscount(discount, type, label) {

        if (discount > 0) {

            this.props.dispatch({
                type: "ADD_DISCOUNT_TO_CART",
                payload: {value: discount, type: type, label: label, entity: this}
            })
        }
        this.setState({show: false, pending_create: true});

    }

    resetDiscount() {

        this.props.dispatch({
            type: "REMOVE_DISCOUNT_TO_CART",
            payload: {entity: this}
        })


    }

    updatePrice(row) {

        this.setState({value: row.price, current_row: row});

        this.toggleCustomPrice(true);
    }

    toggleCustomPrice(status) {


        this.setState({show_custom_price: status, discount_type: WebPosUtils.getCurrency()});
    }

    customizePrice(price) {

        this.props.dispatch({
            type: "CUSTOMIZE_PRICE",
            payload: {row: this.state.current_row, custom_price: price, entity: this}
        })

        this.toggleCustomPrice(false);

    }

    toggleDetail(status) {

        this.setState({show: status});
    }


    changeLabelDiscount(e) {
        this.setState({discount_name: e.target.value});
    }

    selectPaymentMethod(payment) {

        if (this.props.quote.grand_total === 0) return;
        var delivered = this.calculeExchange();
      
        if (delivered <= 0) {

            this.props.showNotification("app.quote.enought_money", 'warning');
        } else {
            payment.delivered = delivered;
            payment.reference = '';
            payment.name = this.props.translate(payment.label);
            this.props.dispatch({
                type: "ADD_PAYMENT_METHOD",
                payload: payment
            })
        }

    }

    removePaymentMethod(payment) {

        this.props.dispatch({
            type: "REMOVE_PAYMENT_METHOD",
            payload: payment
        })

    }

    updatePaymentReference(e) {

        this.props.dispatch({
            type: "UPDATE_PAYMENT_REFERENCE",
            payload: {code: e.target.name, reference: e.target.value}
        })

    }

    updatePaymentDelivery(e) {


        this.props.dispatch({
            type: "UPDATE_PAYMENT_DELIVERED",
            payload: {code: e.target.name, value: e.target.value}
        })

    }

    customizePaymentAmount() {

        this.props.dispatch({
            type: "CUSTOMIZE_PRICE",
            payload: {row: this.state.current_row, custom_price: this.state.value}
        })
        this.createQuote();
        this.toggleCustomPrice(false);

    }

    finalizePurchase() {

        if (this.calculeExchange() > 0) {
            this.props.showNotification("app.quote.no_partial_allowed", 'warning');
            return;
        }
       /* if (this.calculeExchange() < 0) {
            this.props.showNotification("app.quote.negative_order", 'warning');
            return;
        }*/
        /* if (this.props.quote.payments.length === 0) {

             this.props.showNotification("app.quote.no_payment_selected", 'warning');
             return;
         } else {*/
        if (this.state.last_error) {
            this.createQuote();
            return;
        }

        this.setState({loading: true});
        QuoteHelper.sendOrder(this.props.quote).then((data) => {
            if(!this._mounted) return;
            if (data.error > 0 || data.order_id === "") {
                this.setState({loading: false});
                if (data.error === 401 || data.error === 403) {
                    this.props.showNotification("app.core.not_login", 'warning');
                    this.props.history.push("/login");
                    return;
                } else {

                    this.setState({last_error: true});

                    if (data.msg != null) {
                        try {
                            this.props.showNotification(data.msg.data.message, 'warning');
                        } catch (e) {

                        }
                    } else {


                        this.props.showNotification("app.notification.Failed to fetch", 'warning');

                    }
                    this.createQuote();
                }
            }
            else {

                this.setState({last_error: false});
                this.props.showNotification("app.quote.order_complete", "success");
                var id_order=data.order_id;
                this.props.dataProvider.getOne('orders', {id: data.order_id})
                    .then(({data}) => {
                        if(this._mounted) {
                            this.setState(
                                {order: data, loading: false}
                            );
                        }
                        if (WebPosUtils.getSettings().autoprint) {
                            this.printOrder(id_order);
                        }

                        this.props.dispatch({
                            type: "ORDER_DONE",
                            payload: null
                        });
                    })
                    .catch(error => {
                        if(this._mounted) {
                            this.setState({loading: false});
                        }
                    })

            }

        })
        //}
    }


    getTextButton() {
        const {translate} = this.props;
        if (this.calculeExchange() > 0) return translate('app.quote.partial_purchase');
        else return translate('app.quote.finish_purchase');
    }
    printOrder(id){
        const printable = {id_order:id};
        this.print(printable);
    }
    print(printable) {

        QuoteHelper.printOrder(printable).then((data) => {

            if (data.url !== null) {

                window.open(data.url, "_blank");

            } else {
                alert(data.msg);
            }
        });
    }
    printGiftTicketAction(id){
        const printable = {id_order:id, is_gift:true};
        this.print(printable);
    }
    toggleCoupon(e) {
        this.setState({coupon_anchor: e.currentTarget});

        //
    }

    focusCoupon() {
        this.couponInput.current.focus();
    }

    closeCoupon(e) {
        this.setState({coupon_anchor: null});
    }

    changeCoupon(e) {
        this.setState({current_coupon: e.target.value});

    }

    submitCoupon() {
        this.closeCoupon(null);
        this.props.dispatch({
            type: "ADD_COUPON_TO_CART",
            payload: {coupon: this.state.current_coupon, entity: this}
        });
    }

    removeCoupon() {
        this.props.dispatch({
            type: "REMOVE_COUPON_FROM_CART",
            payload: {entity: this}
        });
    }
    showBooking(){
        if(!WebPosUtils.getSettings().booking) return false;

        var payments = this.props.quote.payments;
        if(payments.length === 0) return false;
        var only_cash=true;
        payments.map(function(value){
            if(value.code !== "webposcash") only_cash=false;
            return null;
        })
        return only_cash;
    }
    changeBooking(event){
        var value = event.target.checked?"1":null;
        this.props.dispatch({
            type: "SET_BOOKING_CART",
            payload: value
        });


    }
    render() {

        const {translate} = this.props;
        console.log(this.props.quote);
        if (!WebPosUtils.isLogged()) return ('');
        else
            return (
                <Authenticated>

                    <div className="container-fluid">
                        <Title title={translate('app.quote.finish_purchase')}/>
                        <div className="row">
                            <div className="col-sm-4 sidebar-cart">
                                <div className="cart_header"><Customer/></div>
                                <div className="product-content"><Minicart checkout={this}
                                                                           addDiscount={this.addDiscount}
                                                                           loading={this.state.loading}
                                                                           updatePrice={this.updatePrice}>
                                    <div className="discount_action actions">
                                        <Row>
                                        {!WebPosUtils.getSettings().hide_discount && (
                                        <Col xs="auto">
                                            {(this.props.quote.discount_fixed > 0 || this.props.quote.discount_percent > 0) ? (
                                                <Button variant="danger" size="md" onClick={() => {
                                                    this.resetDiscount()
                                                }}><LabelOffTwoToneIcon/> {translate('app.quote.remove_discount')}
                                                </Button>
                                            ) : (
                                                <Button variant="secondary" size="md" onClick={() => {
                                                    this.toggleDetail(true)
                                                }}><LabelTwoToneIcon/> {translate('app.quote.add_discount')}
                                                </Button>
                                            )}
                                        </Col>
                                        )}
                                        {!WebPosUtils.getSettings().hide_coupon && (
                                        <Col className="text-end">
                                            {this.props.quote.coupon_code ? (
                                                <Button variant="danger" size="md"
                                                        onClick={this.removeCoupon}><LabelOffTwoToneIcon/> {translate('app.quote.remove_coupon')}
                                                </Button>
                                            ) : (
                                                <div>
                                                    <Button variant="dark" size="md"
                                                            onClick={this.toggleCoupon}><LabelTwoToneIcon/> {translate('app.quote.add_coupon')}
                                                    </Button>
                                                    <Popover
                                                        id="coupon"
                                                        open={Boolean(this.state.coupon_anchor)}
                                                        anchorEl={this.state.coupon_anchor}
                                                        onClose={this.closeCoupon}
                                                        onEntered={this.focusCoupon}
                                                        anchorOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <div className="m-1">
                                                            <InputGroup className="mb-3">
                                                                <FormControl
                                                                    value={this.state.current_coupon}
                                                                    placeholder={translate("app.quote.enter_coupon")}
                                                                    onChange={this.changeCoupon}
                                                                    type="text"
                                                                    className="input-coupon"
                                                                    ref={this.couponInput}
                                                                />

                                                                    <Button variant="success" size="md"
                                                                            onClick={this.submitCoupon}><DoneIcon/>
                                                                    </Button>


                                                            </InputGroup>
                                                        </div>
                                                    </Popover>
                                                </div>
                                            )}
                                        </Col>
                                        )}
                                        </Row>
                                    </div>
                                </Minicart></div>
                            </div>
                            <div className="col-sm-8 box">
                                {this.state.order && (
                                    <div>

                                        <Quote order="1" checkout="1" printAction={this.printOrder} printGiftTicketAction={this.printGiftTicketAction}
                                               record={this.state.order}>
                                        </Quote>

                                    </div>
                                )
                                }
                                {!this.state.order && (
                                    <div>
                                        <div className="quote_payment_address">
                                            <QuoteAddresses checkout={this} loading={this.state.loading}
                                            />
                                            <br/>
                                            <Payments loading={this.state.loading}
                                                      available_payment_methods={this.props.quote.payment_methods}
                                                      selected_payments={this.props.quote.payments}
                                                      selectPaymentMethod={this.selectPaymentMethod}
                                                      updatePaymentReference={this.updatePaymentReference}
                                                      updatePaymentDelivery={this.updatePaymentDelivery}
                                                      removePaymentMethod={this.removePaymentMethod}></Payments>
                                            {this.showBooking() && (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={this.props.quote.booking === "1"}
                                                            onClick={this.changeBooking}
                                                            name="booking"
                                                            color="primary"
                                                        />
                                                    }
                                                    label={translate("app.quote.submit_booking")}
                                                />
                                            )}

                                        </div>
                                        <div className="checkout_summary">

                                            <TableContainer component={Paper}>
                                                <Table className="cart" size="small">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell align="left"
                                                                       className="subtotal_label">{this.calculeExchange() <= 0 ? translate('app.quote.change') :
                                                                <span className="no-founds">
                                                        {translate('app.quote.insufficient_founds')}</span>}
                                                            </TableCell>
                                                            <TableCell
                                                                align="right">
                                                                {this.calculeExchange() > 0 ?
                                                                    <div className="no-founds qty_to_refund">
                                                                        {WebPosUtils.formatCurrency(this.calculeExchange())}
                                                                    </div> :
                                                                    <div className="qty_to_refund green">
                                                                        {WebPosUtils.formatCurrency(this.calculeExchange())}
                                                                    </div>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>

                                                </Table>
                                            </TableContainer>
                                            {this.state.loading ?
                                                <Loading loadingPrimary="" loadingSecondary="app.core.loading1"/> :
                                                <div className="actions">

                                                    {!this.state.order && typeof this.props.quote.items !== "undefined" && this.props.quote.items.length > 0 && this.calculeExchange() <= 0 && (
<div>
                                                        <Button variant="success" size="lg" onClick={() => {
                                                            this.finalizePurchase()
                                                        }}><AssignmentTurnedInIcon/>
                                                            {this.getTextButton()}
                                                        </Button>

</div>
                                                    )}
                                                    {this.state.order && (
                                                        <Button variant="primary" size="lg" onClick={() => {
                                                            this.props.history.push("/catalog");
                                                        }}><ShoppingCartIcon/>
                                                        </Button>
                                                    )}

                                                </div>
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>

                        <Calculator
                            showCalculator={this.state.show}
                            toggleShowCalculator={this.toggleDetail}
                            title={'app.quote.add_discount'}
                            default_value={this.state.value}
                            currency={this.state.discount_type}
                            layout={this.state.layout}
                            display={this.state.display}
                            executeAction={this.addDiscount}
                            executeLabel={'app.quote.add_discount'}
                            discount={true}
                        />

                        <Calculator
                            showCalculator={this.state.show_custom_price}
                            toggleShowCalculator={this.toggleCustomPrice}
                            title={'app.quote.customice_price'}
                            default_value={this.state.value}
                            currency={WebPosUtils.getCurrency()}
                            layout={this.state.layout_price}
                            display={this.state.display}
                            executeAction={this.customizePrice}
                            executeLabel={'app.quote.customice_price'}
                        />


                    </div>
                </Authenticated>
            )
                ;
    };
};
const mapStateToProps = (state) => {

    return {
        quote: state.cart

    }
};
const mapDispatchToProps = (dispatch) => {

    return {
        dispatch,
        ...bindActionCreators({showNotification}, dispatch)
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(withDataProvider(withTranslate(Checkout)));