import WebPosUtils from "../helper/Utils";
import storeConfig from './store';

const axios = require('axios');

const authProvider = {
    login: ({ terminal, username, password }) =>  {
        /*const request = new Request(process.env.REACT_APP_API_URL+'login/', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });*/

        var params = new URLSearchParams();
        params.append('terminal', terminal);
        params.append('username', username);
        params.append('password', password);
        params.append('alias', WebPosUtils.getAlias());
        params.append('action', 'authenticate');

        return axios({
            url: process.env.REACT_APP_API_URL+'/login/',
            method: 'post',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            data:  params
    })
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response;
            })
            .then(({ data }) => {
                localStorage.setItem('current_terminal', terminal);
                localStorage.setItem('token', data.access_token);
                localStorage.setItem('username', data.user);
                localStorage.setItem('customer_options', JSON.stringify(data.config));
                WebPosUtils.reloadCustomerOptions();

                storeConfig.store.store.dispatch({
                    type: "CHANGE_STORE",
                    payload: data.config.stores[0]
                });
                WebPosUtils.initialiceSettings();


            }).catch(function (error) {

                console.log(error);
            });
    },
    logout: () => {
        WebPosUtils.clearSession();
        return Promise.resolve();
    },
    checkAuth: () => {

        return localStorage.getItem('token')
        ? Promise.resolve()
        : Promise.reject();
    },
    checkError: (error) => {

        const status = error.status;
        if (status === 401 || status === 403) {
            WebPosUtils.clearSession();
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: params => Promise.resolve(),
};

export default authProvider;