import React from 'react';
import {useDispatch} from 'react-redux';
import {
    Button,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
} from 'react-admin';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';



const AcceptReservationButton = ({ selectedIds }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const dispatch = useDispatch()
    const [updateMany, { loading }] = useUpdateMany(
        'reservations',
        selectedIds,
        { accepted: 1 },
        {
            onSuccess: () => {
                refresh();
                dispatch( {
                    type: "UPDATE_RESERVATIONS",
                    payload: null
                })
                notify('app.core.changes_refresh');
                unselectAll('reservations');
            },
            onFailure: error => notify('app.reservations.reservation_fail', 'warning'),
        }
    );

    return (
        <Button
            label="app.reservations.reserve_stock"
            disabled={loading}
            onClick={updateMany}
        >
            <LocalShippingIcon />
        </Button>
    );
};

export default AcceptReservationButton;