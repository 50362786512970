import React from 'react';
import {withTranslate, Confirm, Loading} from 'react-admin';
import {Table, TableBody, TableCell, TableContainer, TableRow, Paper, CardContent, Card} from '@material-ui/core';
import {Edit as EditIcon, Remove as RemoveIcon, Add as AddIcon, Receipt as ReceiptIcon, HourglassEmpty as HourglassEmptyIcon, Delete as DeleteIcon, CardGiftcard as CardGiftcardIcon} from '@material-ui/icons';
import {Row, Col, Button, Modal, Form} from 'react-bootstrap';
import {connect} from 'react-redux';
import '../../assets/css/cart.css';
import '../../helper/Utils';
import WebPosUtils from "../../helper/Utils";
import storeConfig from '../../config/store';
import QuoteHelper from "./helper/QuoteHelper";




class Minicart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {quote: props.quote, open: false, showEditCartQty:false, cart_qty:0, row_updating:null,showHoldName:false,hold_name:'', freeproduct_qty:1, freeproduct_row:null, showfreeqty:false};
        this.removeFromCart = this.removeFromCart.bind(this);
        this.updatePrice = this.updatePrice.bind(this);
        this.purchase = this.purchase.bind(this);
        this.clear = this.clear.bind(this);
        this.clearCart = this.clearCart.bind(this);
        this.toggleConfirmation = this.toggleConfirmation.bind(this);
        this.changeQty = this.changeQty.bind(this);
        this.getClassItem=this.getClassItem.bind(this);
        this.toggleUpdateCartQtyWindow=this.toggleUpdateCartQtyWindow.bind(this);
        this.changeQtyValue=this.changeQtyValue.bind(this);
        this.changeCartQty= this.changeCartQty.bind(this);
        this.setQty= this.setQty.bind(this);
        this.toggleHoldNameWindow = this.toggleHoldNameWindow.bind(this);
        this.changeHoldName = this.changeHoldName.bind(this);
        this.addToHold = this.addToHold.bind(this);

        this.freeProductClick = this.freeProductClick.bind(this);
        this.freeProductAction = this.freeProductAction.bind(this);
        this.toggleFreeQtyWindow = this.toggleFreeQtyWindow.bind(this);
        this.changeFreeProductQty = this.changeFreeProductQty.bind(this);

    }

    toggleConfirmation(status = false) {

        this.setState({open: status});
    }

    clear() {
        this.toggleConfirmation(true);
    }

    clearCart() {

        this.props.dispatch({
            type: "CLEAR_CART",
            payload: null

        })
        this.toggleConfirmation(false);
    }

    changeQty(row, qty) {
        qty=Number(qty);
        if (row.qty + qty < 1) return;
        this.props.dispatch({
            type: "UPDATE_CART_QTY",
            payload: {row: row, qty: qty, entity: this.props.checkout, append:true},

        })
    }
   setQty(row, qty) {
        qty=Number(qty);
        if (row.qty + qty < 1) return;
        this.props.dispatch({
            type: "UPDATE_CART_QTY",
            payload: {row: row, qty: qty, entity: this.props.checkout, append:false},

        })
    }

    removeFromCart(id) {

        this.props.dispatch({
            type: "REMOVE_FROM_CART",
            payload: {id: id, entity: this.props.checkout},

        })
    }

    updatePrice(row) {

    }
    toggleHoldNameWindow(status){

        this.setState({showHoldName: status, hold_name:''});
    }
    changeHoldName(event){

        var current = event.target.value;

        this.setState({hold_name:current});

    }
    addToHold() {
        let quote =this.props.quote;
        quote.name=this.state.hold_name;

        if(WebPosUtils.getPermission('hold_legacy')=== "1"){
            this.props.dispatch({
                type: "ADD_TO_HOLD_LEGACY",
                payload: this.props.quote
            });
        }else {
            QuoteHelper.addToHold(quote);
            this.props.dispatch({
                type: "ADD_TO_HOLD",
                payload: this.props.quote
            });
        }
        this.toggleHoldNameWindow(false);

    }

    purchase() {

        if (this.props.quote.items.length > 0) storeConfig.history.push('/checkout');
    }
    getClassItem(item){

        if(this.props.checkout!=null && item.quote_item_id === 0) return "cart_item nostock";
        else return "cart_item";
    }
    toggleUpdateCartQtyWindow(status, row=null){
        var qty=0;
        if(row!=null) qty=row.qty;
        this.setState({showEditCartQty: status, refund_item_qty:qty, row_updating:row});
    }
    changeQtyValue(event){

        var current = event.target.value;

        this.setState({refund_item_qty:current});

    }
    changeCartQty(event){

        this.setQty(this.state.row_updating, this.state.refund_item_qty);
        this.toggleUpdateCartQtyWindow(false, null);
    }

    changeFreeProductQty(qty){
        var _qty=this.state.freeproduct_qty + qty;
        this.setState({freeproduct_qty:_qty});
    }
    toggleFreeQtyWindow(status = false) {

        this.setState({showfreeqty: status});
    }
    freeProductClick(row){
        if(row===null){
            this.freeProductAction(this.state.freeproduct_row, this.state.freeproduct_qty);
        }else if(row.qty === 1){
            this.freeProductAction(row, 1);

        }else{
            this.setState({freeproduct_row: row, freeproduct_qty:1, showfreeqty:true});
        }
    }
    freeProductAction(row, qty){

        this.props.dispatch({
            type: "FREEPRODUCT_CART",
            payload: {row: row, qty: qty, entity: this.props.checkout??null},

        })
        this.toggleFreeQtyWindow(false);
    }
    render() {
        const {translate} = this.props;

        if (this.state.open) {
            return (<div><Confirm

                isOpen={true}
                title="app.quote.clear_cart"
                content={translate('app.quote.clear_confirmation')}
                onConfirm={this.clearCart}
                onClose={() => {
                    this.toggleConfirmation(false)
                }}
            /><Loading loadingPrimary="" loadingSecondary="app.core.loading1"/></div>)
        }
        return (

            <div className="cart">
                {this.props.loading ? <Loading loadingPrimary="" loadingSecondary="app.core.loading1"/> :
                    <div>
                        <div className="cart_items">

                            <Card variant="outlined">
                                <CardContent>

                                    {this.props.quote && this.props.quote.items && this.props.quote.items.map((row, index) => (
                                        <Row className={this.getClassItem(row)} key={row.item_id}>
                                            <Col xxl={5} xl={6} className="name_td"  onClick={() => {
                                                this.toggleUpdateCartQtyWindow(true, row)
                                            }}>{row.name} <br/><span className="sku">{translate("SKU")}:  {row.sku}</span>
                                                <ul className="configurable_options">
                                                    {row.type === "configurable" && row.selected_values.map((att, index2) => (
                                                        <li key={index2}>
                                                            <strong>{att.label}</strong> : {att.value_label}
                                                        </li>
                                                    ))}
                                                </ul>

                                            </Col>

                                            <Col xxl={3} xl={6} md={12} className="price_td">
                                                {
                                                    (row.customized ? (
                                                        <div>
                                                                <span
                                                                    className="old-price">{WebPosUtils.formatCurrency(row.original_price)}</span>
                                                            <br/>
                                                            {WebPosUtils.formatCurrency(row.custom_price)}
                                                        </div>
                                                    ) : (WebPosUtils.formatCurrency(row.price)))}
                                                {this.props.checkout && WebPosUtils.getPermission("update_price") && QuoteHelper.canUpdatePrice(row) && (
                                                    <Button variant="outline-primary" className="edit_price" size="xs" onClick={() => {
                                                                if (this.props.updatePrice) this.props.updatePrice(row)
                                                            }}><EditIcon/></Button>
                                                )}

                                                <br/>
                                                <Row className="item_qty_form">

                                                    <Col sm={4} xs={4}  className="minus">
                                                        {QuoteHelper.canUpdateQty(row) && (
                                                        <Button variant="secondary" size="xs" onClick={() => {
                                                            this.changeQty(row, -1)
                                                        }} disabled={row.qty < 2}><RemoveIcon/></Button>
                                                        )}
                                                    </Col>

                                                    <Col sm={4} xs={4} className="qty">
                                                        <span className="itemQty"> x{row.qty}</span>
                                                    </Col>

                                                    <Col sm={4} xs={4} className="plus">
                                                        {QuoteHelper.canUpdateQty(row) && (
                                                        <Button variant="secondary" size="xs" onClick={() => {
                                                            this.changeQty(row, 1)
                                                        }}><AddIcon/></Button>
                                                        )}
                                                    </Col>

                                                </Row>
                                                {!WebPosUtils.getSettings().hide_stock && (
                                                <span
                                                    className="stock stockInfo">{translate('app.quote.stock')}:{row.stock}</span>
                                                )}

                                            </Col>
                                            <Col className="price_td"
                                                 xxl={2} xl={6} sm={2} md={6}><strong>{WebPosUtils.formatCurrency(row.row_total)}</strong>
                                                {WebPosUtils.getPermission("free_product") && !row.customized && QuoteHelper.canUpdatePrice(row) && (
                                                    <div><Button variant="primary" className="free_gift" title={translate("app.catalog.free_product_btn")} size="xs" onClick={() => {
                                                        this.freeProductClick(row)
                                                    }}><CardGiftcardIcon /></Button>
                                                    </div>
                                                )}
                                               </Col>
                                            {!QuoteHelper.isGift(row) && (
                                            <Col className="actions_td text-end" xxl={2}  xl={6} sm={2} md={6} onClick={() => {
                                                this.removeFromCart(index)
                                            }}><Button variant="danger" size="sm"><DeleteIcon/></Button></Col>
                                            )}
                                            {QuoteHelper.isGift(row) && (
                                                <Col className="actions_td text-end" xxl={2}  xl={6} sm={2} md={6} title={translate("app.catalog.gift")}><CardGiftcardIcon /></Col>
                                            )}
                                        </Row>
                                    ))}


                                    <Modal show={this.state.showEditCartQty} onHide={() => {
                                        this.toggleUpdateCartQtyWindow(false, null)
                                    }}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>{translate('app.catalog.update_cart_qty')}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                                <Row>
                                                    <Col>
                                                        <Form.Control type="number" data-id={this.state.refund_item_qty} data-source={this.state.refund_item_qty} onChange={this.changeQtyValue} value={this.state.refund_item_qty} />
                                                    </Col>
                                                    <Col>
                                                            <Button size="sm" variant="primary" type="button" onClick={this.changeCartQty}>
                                                                {translate('app.catalog.cart_qty_save')}
                                                            </Button>
                                                    </Col>
                                                </Row>


                                        </Modal.Body>
                                        <Modal.Footer>


                                        </Modal.Footer>
                                    </Modal>
                                </CardContent>
                            </Card>

                        </div>
                        <div className="cart_footer">
                            <TableContainer component={Paper}>
                                <Table className="cart" size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="left"
                                                       className="subtotal_label">{translate('app.quote.subtotal')}</TableCell>
                                            <TableCell
                                                align="right"> {WebPosUtils.formatCurrency(this.props.quote.subtotal)}</TableCell>
                                        </TableRow>
                                        {this.props.quote.discount_amount > 0 && (
                                            <TableRow>
                                                <TableCell align="left"
                                                           className="subtotal_label">{translate('app.quote.discount')} ({this.props.quote.discount_cart})</TableCell>
                                                <TableCell
                                                    align="right"> -{WebPosUtils.formatCurrency(this.props.quote.discount_amount)}</TableCell>
                                            </TableRow>
                                        )}
                                        {this.props.quote.shipping_amount > 0 && (
                                            <TableRow>
                                                <TableCell align="left"
                                                           className="subtotal_label">{translate('app.quote.shipping_amount')}</TableCell>
                                                <TableCell
                                                    align="right"> {WebPosUtils.formatCurrency(this.props.quote.shipping_amount)}</TableCell>
                                            </TableRow>
                                        )}
                                        <TableRow>
                                            <TableCell align="left"
                                                       className="subtotal_label">{translate('app.quote.tax')}</TableCell>
                                            <TableCell
                                                align="right"> {WebPosUtils.formatCurrency(this.props.quote.tax)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="left"
                                                       className="subtotal_label">{translate('app.quote.grand_total')}</TableCell>
                                            <TableCell
                                                align="right"
                                                className="quote_grand_total"> {WebPosUtils.formatCurrency(this.props.quote.grand_total)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                    <Modal show={this.state.showHoldName} onHide={() => {
                                        this.toggleHoldNameWindow(false)
                                    }}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>{translate('app.catalog.hold_name_header')}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <Row>
                                                <Col>
                                                    <Form.Control type="text"  onChange={this.changeHoldName} value={this.state.hold_name} />
                                                </Col>
                                                <Col>
                                                    <Button size="sm" variant="primary" type="button" onClick={this.addToHold}>
                                                        {translate('app.catalog.cart_qty_save')}
                                                    </Button>
                                                </Col>
                                            </Row>


                                        </Modal.Body>
                                        <Modal.Footer>


                                        </Modal.Footer>
                                    </Modal>


                                </Table>
                            </TableContainer> {!this.props.checkout && (
                            <div className="cart_actions actions">
                                <Row>
                                    <Col className="text-start">
                                        <Button variant="danger" size="md" onClick={() => {
                                            this.clear()
                                        }}><DeleteIcon/> {translate('app.quote.clear')}
                                        </Button>
                                        <Button variant="secondary" size="md" onClick={() => {
                                            this.toggleHoldNameWindow(true)
                                        }}><HourglassEmptyIcon/> {translate('app.quote.hold')}
                                        </Button>


                                    <Button className="putQuote-btn" variant="success" size="md" onClick={() => {
                                        this.purchase()
                                    }}><ReceiptIcon/> {translate('app.quote.purchase')}
                                    </Button>
                                    </Col>
                                </Row>

                            </div>)}
                            {this.props.children}
                        </div>
                    </div>
                }
                <Modal show={this.state.showfreeqty} onHide={() => {
                    this.toggleFreeQtyWindow(false)
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>{translate('app.catalog.freeproduct_name_header')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row>
                            <Col>
                                <Row className="item_qty_form">
                                    <Col sm={4} xs={4}  className="minus">
                                        <Button variant="secondary" size="xs" onClick={() => {
                                            this.changeFreeProductQty(-1)
                                        }} disabled={this.state.freeproduct_qty < 2}><RemoveIcon/></Button>
                                    </Col>
                                    <Col sm={4} xs={4} className="qty">
                                        <span className="itemQty"> x{this.state.freeproduct_qty}</span>
                                    </Col>
                                    <Col sm={4} xs={4} className="plus">
                                        <Button variant="secondary" size="xs" onClick={() => {
                                            this.changeFreeProductQty(1)
                                        }} disabled={this.state.freeproduct_row===null || this.state.freeproduct_qty === this.state.freeproduct_row.qty}><AddIcon/></Button><br/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Button size="sm" variant="primary" type="button" onClick={() => {
                                    this.freeProductClick(null)
                                }}>
                                    {translate('app.catalog.free_product_btn')}
                                </Button>
                            </Col>
                        </Row>


                    </Modal.Body>
                    <Modal.Footer>


                    </Modal.Footer>
                </Modal>
            </div>


        );
    }

}

const mapStateToProps = (state) => {

    return {
        quote: state.cart
    }
};

export default connect(mapStateToProps)(withTranslate(Minicart));