import restdataprovider from './restdataprovider'; //handle ruby dataProvider
import holdDataProvider from './holdDataProvider';// handle java dataProvider


const dataProviders = [
    { dataProvider: restdataprovider, resources: ['catalog','orders','order','funds','reservations','trackings','track','customers','coupons','hold'] },
    { dataProvider: holdDataProvider, resources: ['hold_legacy'] },
];

export default (type, resource, params) => {

    const dataProviderMapping = dataProviders.find(dp => dp.resources.includes(resource));

    switch(type){
        case "GET_LIST":
            return dataProviderMapping.dataProvider.getList(resource, params);
        case "GET_MANY":
            return dataProviderMapping.dataProvider.getMany(resource, params);
        case "GET_MANY_REFERENCE":
            return dataProviderMapping.dataProvider.getManyReference(resource, params);
        case "GET_ONE":
            return dataProviderMapping.dataProvider.getOne(resource, params);
        case "DELETE":
            return dataProviderMapping.dataProvider.delete(resource, params);
        case "DELETE_MANY":
            return dataProviderMapping.dataProvider.deleteMany(resource, params);
        case "UPDATE_MANY":
            return dataProviderMapping.dataProvider.updateMany(resource, params);
        case "UPDATE":
            return dataProviderMapping.dataProvider.update(resource, params);
        case "CREATE":
            return dataProviderMapping.dataProvider.create(resource, params);
        default:
            return null;
    }

};