import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import storeConfig from './store';


const apiUrl = 'https://my.api.com/';
const httpClient = fetchUtils.fetchJson;


export default {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        /*const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
        };*/

        return new Promise(function(resolve, reject) {
                var state = storeConfig.store.store.getState();

                //var data=JSON.parse(localStorage.getItem("persist:hold"));
                //var carts = JSON.parse(data.carts).slice((page - 1) * perPage, page * perPage);
                if(state.hold.carts!=null) {
                    var carts = state.hold.carts.slice((page - 1) * perPage, page * perPage);
                    resolve({data:carts, total:carts.length});
                }else{
                    resolve({data:[], total:0});
                }

        })
    },
    getOne: (resource, params) =>
    {
        return new Promise(function(resolve, reject) {
            var state = storeConfig.store.store.getState();
            var carts = state.hold.carts


            var hold = carts.find(x=>x.id === params.id);

           if (hold) resolve({data:hold});
           else resolve({data:{}});
        });
    },

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json })),

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        })),

    delete: (resource, params) => {
        console.log(params);
        return new Promise(function (resolve, reject) {

            storeConfig.store.store.dispatch({
                type: "REMOVE_FROM_HOLD_LEGACY",
                payload: params.id
            });
        });
    },
    deleteMany: (resource, params) => {

        return new Promise(function (resolve, reject) {

            storeConfig.store.store.dispatch({
                type: "REMOVE_MASS_FROM_HOLD_LEGACY",
                payload: params.ids
            });

            resolve({data: params.ids});
        });
    },
};