import React from 'react';
import {List, Datagrid, TextField, Show, refreshView} from 'react-admin';
import {connect} from 'react-redux';
import Quote from '../sales/Quote';
import {Button, Row, Col} from 'react-bootstrap';
import {withTranslate, withDataProvider} from 'react-admin';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import {bindActionCreators} from 'redux';

class Hold_legacy extends React.Component {

    constructor(props) {
        super(props);

        this.state = {record: null};
        this.selectHold = this.selectHold.bind(this);
        this.delete = this.delete.bind(this);
        this.continue = this.continue.bind(this);

    }

    selectHold(id, basePatch, record) {

        this.setState({record: record});
    }

    delete() {

        this.props.dispatch({
            type: "REMOVE_FROM_HOLD_LEGACY",
            payload: this.state.record.id
        });
        this.setState({record: null});
        this.props.refreshView();

    }
    continue() {


        this.props.dispatch({
            type: "ADD_FROM_HOLD_LEGACY",
            payload: this.state.record
        });
        this.setState({record: null});
        this.props.history.push('/');
    }
    render() {
        const {translate} = this.props;
        return (
            <div className="container-fluid">
                <Row>
                    <Col md={12} lg={6}>
                        <List basePath='hold_legacy' resource='hold_legacy' exporter={false} hasCreate={false} hasEdit={false}
                              hasList={false} hasShow={false} title="app.resource.hold">
                            <Datagrid rowClick={this.selectHold}>
                                <TextField source="id"  headerClassName="d-sm-table-cell d-none" cellClassName="d-sm-table-cell d-none" />
                                <TextField source="name" label="app.quote.name"/>
                                <TextField source="subtotal" label="app.quote.subtotal"/>
                                <TextField source="grand_total" label="app.quote.grand_total"/>
                                <TextField source="created_at" label="app.quote.created_at"/>
                            </Datagrid>
                        </List>
                    </Col>
                    <Col md={12} lg={6}>
                        {this.state.record &&
                        <Show resource='hold_legacy' id={this.state.record.id} basePath='hold_legacy'>
                            <Quote>
                                <div className="actions">
                                    <Button size="lg" variant="danger" onClick={() => {
                                        this.delete()
                                    }}
                                    ><RemoveShoppingCartIcon /> {translate('app.quote.delete')}
                                    </Button>
                                    <Button size="lg" variant="success"  onClick={() => {
                                        this.continue()
                                    }}><ShoppingCartIcon/>
                                        {translate('app.quote.continue')}
                                    </Button>

                                </div>
                            </Quote>


                        </Show>
                        }

                    </Col>
                </Row>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {

    return {
        dispatch,
        ...bindActionCreators({refreshView}, dispatch)
    }
};
const mapStateToProps = (state) => {

    return {
        carts: state.carts
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withDataProvider(withTranslate(Hold_legacy)));