import React from 'react';
import {withTranslate, Loading, refreshView, showNotification, Confirm} from 'react-admin';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Checkbox,
    FormControlLabel
} from '@material-ui/core';
import {Row, Col, InputGroup, Card, Alert, Form, Button, Modal} from 'react-bootstrap';
import {Remove as RemoveIcon, Add as AddIcon} from '@material-ui/icons';
import '../../assets/css/styles.css';
import '../../assets/css/cart.css';
import '../../assets/css/order.css';
import '../../helper/Utils';
import WebPosUtils from "../../helper/Utils";
import QuoteHelper from "../cart/helper/QuoteHelper";
import RefundModel from "./model/RefundModel";
import Payments from ".//Payments";
import ProductListChange from "./ProductListChange";


class Refund extends React.Component {
    constructor(props) {

        super(props);
        var refund = {};
        refund = new RefundModel();

        refund.items = this.props.record.items;
        if (refund.items) {
            refund.items.map((row, index) => {
                refund.items[index].qty_to_return = 0;
                refund.items[index].return_to_stock = true;
                return row;
            });
        }
        this.state = {refund: refund, total_fix: "", confirm_action: false, showEditCartQty:false, refund_item_qty:0, row_updating:null};
        this.updateFix = this.updateFix.bind(this);
        this.selectPaymentMethod = this.selectPaymentMethod.bind(this);
        this.removePaymentMethod = this.removePaymentMethod.bind(this);
        this.updatePaymentReference = this.updatePaymentReference.bind(this);
        this.updatePaymentDelivery = this.updatePaymentDelivery.bind(this);
        this.returnToStock = this.returnToStock.bind(this);
        this.doChange = this.doChange.bind(this);
        this.setChangeProducts = this.setChangeProducts.bind(this);
        this.dochangeConfirm = this.dochangeConfirm.bind(this);
        this.dochangeCancel = this.dochangeCancel.bind(this);
        this.changeQty=this.changeQty.bind(this);
        this.toggleUpdateCartQtyWindow=this.toggleUpdateCartQtyWindow.bind(this);
        this.changeCartQty= this.changeCartQty.bind(this);
        this.changeQtyValue=this.changeQtyValue.bind(this);
    }

    minus(index) {
        let refund = this.state.refund;
        var qty = Number(refund.items[index].qty_to_return) - 1;
        this.changeQty(index, qty);
    }
    plus(index) {
        let refund = this.state.refund;
        var qty = Number(refund.items[index].qty_to_return) + 1;
        this.changeQty(index, qty);
    }
    returnToStock(index) {
        let refund = this.state.refund;
        refund.items[index].return_to_stock = !refund.items[index].return_to_stock;
        this.setState({refund: refund});
    }

    changeQty(index, _qty) {
        _qty=Number(_qty);
        let refund = this.state.refund;
        const qty = this.props.record.items[index].qty - this.props.record.items[index].qty_refunded;

        if (_qty > 0) {
            if (qty < _qty) _qty=qty;
        } else if (_qty < 0) {
            _qty=0;
        }
        refund.items[index].qty_to_return = Number(_qty);
        refund.refreshItemTotals();
        /*

        refund.subtotal += this.props.record.items[index].price_without_tax*_qty;
        refund.tax += this.props.record.items[index].tax*_qty;
        refund.grand_total += (this.props.record.items[index].price - this.props.record.items[index].discount)*_qty;
        */
        if (refund.grand_total > (this.props.record.grand_total - this.props.record.total_due)) {
            refund.grand_total = this.props.record.grand_total - this.props.record.total_due;
        }
        refund.grand_total_final = refund.grand_total + refund.total_fix;

        refund.clearPayments();
        this.setState({refund: refund});
    }


    changeCartQty(event){
        this.changeQty(this.state.row_updating, this.state.refund_item_qty);
        this.toggleUpdateCartQtyWindow(false, null);
    }
    changeQtyValue(event){
        var current = event.target.value;
        this.setState({refund_item_qty:current});
    }
    updateFix(e) {
        var refund = this.state.refund;
        var fix = e.target.value;

        if (fix === this.state.refund.total_fix) {
            this.setState({total_fix: e.target.value});
            return;
        }

        if (isNaN(fix) || fix === "-0") {
            fix = 0;
            /*this.setState({refund: refund, total_fix:fix});
            return;*/
        }

        fix = Number(fix);
        refund.total_fix = fix;
        refund.total_fix = fix;
        refund.overpaid = 0;
        if ((refund.grand_total + fix) > (this.props.record.grand_total - this.props.record.total_due)) {
            refund.overpaid = 1;
            // fix = this.props.record.grand_total - this.props.record.total_due - refund.grand_total;
        } else if ((refund.grand_total + fix) < 0) {
            fix = -refund.grand_total;
        }

        refund.total_fix = fix;
        refund.grand_total_final = refund.grand_total + refund.total_fix;
        refund.payments = [];
        this.setState({refund: refund, total_fix: e.target.value});
    }

    doRefund() {

        this.props.doRefund(this.state.refund);
    }

    selectPaymentMethod(payment) {
        var total_refunded = this.state.refund.getQtyRefunded();
        if (total_refunded === 0) return;
        const {translate} = this.props;
        payment.delivered = this.state.refund.calculeExchange4Refund();

        payment.name = translate(payment.label);

        payment.reference = '';


        var refund = this.state.refund;
        QuoteHelper.addPaymentMethod(refund, payment);
        this.setState({refund: refund});


    }

    removePaymentMethod(payment) {
        var refund = this.state.refund;
        refund = QuoteHelper.removePaymentMethod(refund, payment);
        this.setState({refund: refund});
    }

    updatePaymentReference(e) {

        var refund = this.state.refund;
        refund = QuoteHelper.updatePaymentReference(refund, {code: e.target.name, reference: e.target.value});
        this.setState({refund: refund});
    }

    updatePaymentDelivery(e) {

        var refund = this.state.refund;
        refund = QuoteHelper.updatePaymentDelivered(refund, {code: e.target.name, value: e.target.value});
        this.setState({refund: refund});
    }

    changeCategory(value) {
        var refund = this.state.refund;
        refund.category = value;
        this.setState({refund: refund});
    }

    doChange() {

        var refund = this.state.refund;

        if (refund.calculeExchange4Refund() !== 0) {
            this.setState({confirm_action: true, confirm_msg: 'app.quote.confirm_refund'});
        } else {
            var no_stock = false;
            refund.items_to_change.map((item) => {
                if (item.qty > item.stock) {
                    no_stock = true;

                }
                return no_stock;
            });
            if (no_stock) this.setState({confirm_action: true, confirm_msg: 'app.quote.confirm_refund_nostock'});
            else this.dochangeConfirm();
        }
    }

    dochangeConfirm() {

        this.dochangeCancel();

        const {translate} = this.props;
        var refund = this.state.refund;
        var coupon_qty = this.state.refund.getTotalChange();

        if (coupon_qty > 0) {
            var payment = {
                code: 'webposcoupon',
                delivered: 0,
                name: '',
                reference: '',
                label: 'app.quote.payment_coupon'
            };
            payment.delivered = coupon_qty;
            payment.name = translate("app.quote.payment_coupon");

            refund.addPaymentMethod(payment);
        }
        this.setState({refund: refund});
        this.doRefund();


    }

    dochangeCancel() {
        this.setState({confirm_action: false});
    }

    setChangeProducts(products) {
        var refund = this.state.refund;
        refund.items_to_change = products;
        refund.clearPayments();
        this.setState({refund: refund});
    }

    toggleUpdateCartQtyWindow(status, index=null){
        var qty=0;
        if(index!=null && this.state.refund.items[index]!==null) qty=this.state.refund.items[index].qty_to_return;
        this.setState({showEditCartQty: status, refund_item_qty:qty, row_updating:index});
    }

    render() {

        const {translate} = this.props;
        if (this.props.record === null) return "";
        if (this.state.confirm_action) {
            return (<div><Confirm isOpen={true}
                                  title="app.quote.clear_cart"
                                  content={translate(this.state.confirm_msg)}
                                  onConfirm={this.dochangeConfirm}
                                  onClose={this.dochangeCancel}
            /><Loading loadingPrimary="" loadingSecondary="app.core.loading1"/></div>)
        }
        if (this.props.record.total_qty_ordered === this.props.record.total_qty_refunded) {
            return (
                <Alert variant="danger">{translate('app.quote.refund_not_allowed')}</Alert>
            );
        } else return (
            <div className="cart">
                <Modal show={this.state.showEditCartQty} onHide={() => {
                    this.toggleUpdateCartQtyWindow(false, null)
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>{translate('app.catalog.update_cart_qty')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row>
                            <Col>
                                <Form.Control type="number" data-id={this.state.refund_item_qty}  onChange={this.changeQtyValue} value={this.state.refund_item_qty} />
                            </Col>
                            <Col>
                                <Button size="sm" variant="primary" type="button" onClick={this.changeCartQty}>
                                    {translate('app.catalog.cart_qty_save')}
                                </Button>
                            </Col>
                        </Row>


                    </Modal.Body>
                    <Modal.Footer>


                    </Modal.Footer>
                </Modal>

                <Card className="order_header">
                    <Card.Header>{translate('app.quote.order_header')} #{this.props.record.increment_id}</Card.Header>
                    {WebPosUtils.getPermission("changes") && this.props.record.total_due === 0 && (
                        <Card.Body>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.refund.category === "refund"}
                                        onClick={() => {
                                            this.changeCategory("refund")
                                        }}
                                        name="category"
                                        color="primary"
                                    />
                                }
                                label={translate("app.quote.refund")}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.refund.category === "change"}
                                        onClick={() => {
                                            this.changeCategory("change")
                                        }}
                                        name="category"
                                        color="primary"
                                    />
                                }
                                label={translate("app.quote.change_items")}
                            />

                        </Card.Body>
                    )}
                </Card>

                <Card className="">
                    <Card.Header>{translate('app.quote.refund_items')}</Card.Header>
                    <Card.Body>

                        <div>
                            {this.props.record && this.props.record.items && this.props.record.items.map((row, index) => (
                                <Row key={index}>

                                    <Col md={4}>{row.name}
                                        <ul className="configurable_options">
                                            {row.type === "configurable" && row.selected_values.map((att, index2) => (
                                                <li key={index2}>
                                                    <strong>{att.label}</strong> : {att.value_label}
                                                </li>
                                            ))}
                                        </ul>
                                    </Col>

                                    <Col
                                        className="text-end price_td">{WebPosUtils.formatCurrency(row.price - row.discount/row.qty)}</Col>
                                    <Col md="auto" className="qty_td">
                                        {this.props.creating ?

                                            <InputGroup className="mb-3">

                                                <Button variant="primary" size="md" onClick={() => {
                                                    this.minus(index)
                                                }}><RemoveIcon/>
                                                </Button>

                                                <Form.Control className="qty text-center" type="text"
                                                              value={this.state.refund.items[index].qty_to_return}
                                                              readOnly   onClick={() => {
                                                    this.toggleUpdateCartQtyWindow(true, index)
                                                }}/>

                                                <InputGroup.Text
                                                    id="basic-addon2">/{this.props.record.items[index].qty - this.props.record.items[index].qty_refunded}</InputGroup.Text>
                                                <Button variant="primary" size="md" onClick={() => {
                                                    this.plus(index)
                                                }}><AddIcon/></Button>


                                            </InputGroup>


                                            :
                                            <span>
                                                    {this.props.record.items[index].qty}
                                                    </span>
                                        }

                                        {this.props.creating && (
                                            <div className="text-center">

                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={this.state.refund.items[index].return_to_stock}
                                                            onClick={() => {
                                                                this.returnToStock(index)
                                                            }}
                                                            name="return_to_stock"
                                                            color="primary"
                                                        />
                                                    }
                                                    label={translate("app.quote.return_to_stock")}
                                                />
                                            </div>


                                        )}
                                    </Col>
                                    <Col className="text-end"
                                         xs={12}
                                         lg="auto">{WebPosUtils.formatCurrency(this.state.refund.items[index].qty_to_return * (row.price - row.discount))}</Col>

                                </Row>
                            ))}

                        </div>
                    </Card.Body>
                </Card>

                <div>
                    {this.props.record && this.state.refund.category === "refund" &&

                    <div className="row">
                        <div className="col-6 offset-6">
                            <TableContainer component={Paper}>

                                <Table className="cart" size="small">
                                    <TableBody>
                                        {this.props.creating && this.props.record.total_due > 0 && (
                                            <TableRow>
                                                <TableCell align="right"
                                                           className="subtotal_label">{translate('app.quote.amount_due')} </TableCell>
                                                <TableCell
                                                    align="right">
                                                    <div
                                                        className="alert-danger">{WebPosUtils.formatCurrency(this.props.record.total_due)}</div>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        <TableRow>
                                            <TableCell align="right"
                                                       className="subtotal_label">{translate('app.quote.subtotal')}</TableCell>
                                            <TableCell
                                                align="right"> {WebPosUtils.formatCurrency(this.state.refund.subtotal)}</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell align="right"
                                                       className="subtotal_label">{translate('app.quote.tax')}</TableCell>
                                            <TableCell
                                                align="right"> {WebPosUtils.formatCurrency(this.state.refund.tax)}</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell align="right"
                                                       className="subtotal_label">{translate('app.quote.grand_total')}</TableCell>
                                            <TableCell
                                                align="right"> {WebPosUtils.formatCurrency(this.state.refund.grand_total)}


                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right"
                                                       className="subtotal_label">{translate('app.quote.total_refund_fix')}</TableCell>
                                            <TableCell
                                                align="right">
                                                <Form.Control className="qty text-center" type="text"
                                                              value={this.state.total_fix}
                                                              onChange={this.updateFix}/> {WebPosUtils.getCurrency()}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right"
                                                       className="subtotal_label">{translate('app.quote.total_refund')}</TableCell>
                                            <TableCell
                                                align="right"> {WebPosUtils.formatCurrency(this.state.refund.grand_total_final)}


                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                    }

                    {
                        this.props.children
                    }
                    {this.state.refund.category === "change" &&
                    <div className="alert alert-info size-less1">{translate('app.quote.refund_message')}</div>
                    }
                    {this.state.refund.category === "refund" &&
                    <Payments loading={this.state.loading}
                              available_payment_methods={QuoteHelper.getPaymentMethods(true, false, this.props.currentorder.webpos_booking === 1)}
                              selected_payments={this.state.refund.payments}
                              selectPaymentMethod={this.selectPaymentMethod}
                              updatePaymentReference={this.updatePaymentReference}
                              updatePaymentDelivery={this.updatePaymentDelivery}
                              removePaymentMethod={this.removePaymentMethod}
                    ></Payments>
                    }
                    {this.state.refund.category === "change" && WebPosUtils.getPermission("changes") && this.state.refund.getQtyRefunded() > 0 && (

                        <Card className="order_header">
                            <Card.Header>{translate('app.quote.refund_items_change')}</Card.Header>
                            <Card.Body>
                                <ProductListChange doChange={this.doChange} creatingRefund={this.props.creatingRefund}
                                                   selectPaymentMethod={this.selectPaymentMethod}
                                                   setChangeProducts={this.setChangeProducts}
                                                   updatePaymentReference={this.updatePaymentReference}
                                                   updatePaymentDelivery={this.updatePaymentDelivery}
                                                   removePaymentMethod={this.removePaymentMethod}
                                                   selected_payments={this.state.refund.payments}
                                                   refund={this.state.refund}
                                                   order_booking={this.props.currentorder.webpos_booking}/>
                            </Card.Body>
                        </Card>


                    )}
                    {this.props.creating && (
                        <div className="row">
                            <div className="col-12 text-end">
                                <div className="padding10">
                                    {this.props.creatingRefund ? (
                                        <Loading loadingPrimary="" loadingSecondary="app.core.loading1"/>) : (

                                        <div>
                                            {this.state.refund.category === "refund" && (
                                                <div>
                                                    {(this.state.refund.grand_total + this.state.refund.total_fix) > (this.props.record.grand_total - this.props.record.total_due) && (
                                                        <div
                                                            className="alert alert-warning">{translate("app.quote.refund_excedeed")}</div>

                                                    )}
                                                    {this.state.refund.payments.length > 0 && this.state.refund.items.length > 0 ? (
                                                        <div>
                                                            {this.state.refund.getPaymentTotals() === 0 ? (
                                                                <div
                                                                    className="alert alert-danger">{translate("app.quote.refund_empty")}</div>
                                                            ) : (
                                                                <div>
                                                                    {this.props.record.discount_amount < 0 && (
                                                                        <div
                                                                            className="alert alert-warning  text-center">{translate("app.quote.refund_discount_warning")}</div>
                                                                    )}
                                                                    <Button variant="primary" size="md" onClick={() => {
                                                                        this.doRefund()
                                                                    }}>{translate('app.quote.refund')}
                                                                    </Button>
                                                                </div>
                                                            )}

                                                        </div>
                                                    ) : (
                                                        <div
                                                            className="alert alert-warning">{translate("app.quote.refund_requirements")}</div>
                                                    )}
                                                </div>
                                            )}
                                        </div>

                                    )}


                                </div>
                            </div>
                        </div>

                    )}
                </div>

            </div>

        );
    }

}

const mapDispatchToProps = (dispatch) => {

    return {
        dispatch,
        ...bindActionCreators({showNotification, refreshView}, dispatch)
    }
};
export default connect(null, mapDispatchToProps)(withTranslate(Refund));