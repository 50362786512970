import React from 'react';
import {withTranslate} from "react-admin";
import {Table, TableBody, TableCell, TableContainer, TableRow, Paper} from '@material-ui/core';
import {Print as PrintIcon} from '@material-ui/icons';
import {Button, Row, Col, Card} from 'react-bootstrap';
import PaymentList from "../sales/PaymentList";
import WebPosUtils from "../../helper/Utils";




class RefundsList extends React.Component {

    render() {

        const refund = this.props.refund;
        return(
            <Card className="order_header">
                <Card.Header>
                    <Row>
                        <Col xs="auto">
                            {WebPosUtils.formatDateFromMysql(refund.created_at)} - #{refund.increment_id}
                        </Col>
                        <Col className="text-end">
                            <Button size="sm" variant="primary" onClick={() => {
                                this.props.printRefundAction(refund)
                            }}><PrintIcon />
                            </Button>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    {refund.payments && refund.payments.length > 0 && (

                        <PaymentList record={refund} order={refund} />
                    )
                    }
                    <div>
                        <TableContainer component={Paper}>

                            <Table aria-label="simple table cart">
                                <TableBody>
                                    {refund.items && refund.items.map((row, index) => (
                                        <TableRow key={index}>

                                            <TableCell align="left" className="name_td">{row.name}
                                            </TableCell>

                                            <TableCell align="right"
                                                       className="price_td">{WebPosUtils.formatCurrency(row.price)}</TableCell>
                                            <TableCell align="right" className="qty_td">x{row.qty}

                                            </TableCell>
                                            <TableCell align="right"
                                                       className="qty_td">{WebPosUtils.formatCurrency(row.row_total)}</TableCell>


                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>



                </Card.Body>
            </Card>
        )
    }
}

export default withTranslate(RefundsList);