class QuoteAddressModel{
    id;
    customer_id;
    region;
    region_id;
    city;
    country;
    street;
    telephone;
    postcode;
    firstname;
    lastname;
    constructor(params){

        this.id=params.id;
        this.customer_id=params.customer_id;
        this.region=params.region;
        this.region_id=params.region_id;
        this.city=params.city;
        this.country=params.country;
        this.street=params.street;
        this.telephone=params.telephone;
        this.postcode=params.postcode;
        this.firstname=params.firstname;
        this.lastname=params.lastname;

    }

}

export default QuoteAddressModel;